import { Bit, ILabeled } from "../common";

export enum EDevice {
  WINDOWS = 1,
  MAC = 2,
  LINUX = 4,
  APPLE_PHONE = 8,
  ANDROID_PHONE = 16,
  APPLE_TABLET = 32,
  ANDROID_TABLET = 64,
  LINUX_TABLET = 128,
  OTHER_PHONE = 256,
  OTHER = 512,
}

export enum EBrowser {
  CHROME = 1,
  FIREFOX = 2,
  SAFARI = 4,
  EDGE = 8,
  OPERA = 16,
  DUCKDUCKGO = 32,
  OTHER = 64,
}

export const devices: Record<EDevice, ILabeled<EDevice>> = {
  [EDevice.WINDOWS]: {
    uid: EDevice.WINDOWS,
    label: "Windows",
  },
  [EDevice.MAC]: {
    uid: EDevice.MAC,
    label: "Mac",
  },
  [EDevice.LINUX]: {
    uid: EDevice.LINUX,
    label: "Linux",
  },
  [EDevice.APPLE_PHONE]: {
    uid: EDevice.APPLE_PHONE,
    label: "Apple Phone",
  },
  [EDevice.ANDROID_PHONE]: {
    uid: EDevice.ANDROID_PHONE,
    label: "Android Phone",
  },
  [EDevice.APPLE_TABLET]: {
    uid: EDevice.APPLE_TABLET,
    label: "Apple Tablet",
  },
  [EDevice.ANDROID_TABLET]: {
    uid: EDevice.ANDROID_TABLET,
    label: "Android Tablet",
  },
  [EDevice.LINUX_TABLET]: {
    uid: EDevice.LINUX_TABLET,
    label: "Linux Tablet",
  },
  [EDevice.OTHER_PHONE]: {
    uid: EDevice.OTHER_PHONE,
    label: "Other Phone",
  },
  [EDevice.OTHER]: {
    uid: EDevice.OTHER,
    label: "Other",
  },
};

export const browsers: Record<EBrowser, ILabeled<EBrowser>> = {
  [EBrowser.CHROME]: {
    uid: EBrowser.CHROME,
    label: "Chrome",
  },
  [EBrowser.FIREFOX]: {
    uid: EBrowser.FIREFOX,
    label: "Firefox",
  },
  [EBrowser.SAFARI]: {
    uid: EBrowser.SAFARI,
    label: "Safari",
  },
  [EBrowser.EDGE]: {
    uid: EBrowser.EDGE,
    label: "Microsoft Edge",
  },
  [EBrowser.OPERA]: {
    uid: EBrowser.OPERA,
    label: "Opera",
  },
  [EBrowser.DUCKDUCKGO]: {
    uid: EBrowser.DUCKDUCKGO,
    label: "Duck Duck Go",
  },
  [EBrowser.OTHER]: {
    uid: EBrowser.OTHER,
    label: "Other",
  },
};

export interface ITechContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  date: "" | string;
  time: string;
  pageUrl?: string;
  whatHappened?: string;
  version: string;
  device?: EDevice | "";
  browser?: EBrowser | "";
  otherDetails: string;
  confirmAge: boolean;
  agreeToPrivacy: boolean;
}

export interface ITechContactFormSubmission {
  firstName: string;
  lastName: string;
  email: string;
  date: Date | "";
  pageUrl?: string;
  whatHappened?: string;
  version: string;
  device?: Bit;
  browser?: Bit;
  otherDetails: string;
  confirmAge: boolean;
  agreeToPrivacy: boolean;
}
