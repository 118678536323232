import { ConstrainedPageStyle, PageTitle, P, SectionBackground } from "../styles/page";
import styled from "styled-components";
const TimelineDiv = styled.div`
  padding: 1rem;
  overflow: hidden;
  position: relative;

  & > div {
    display: flex;

    .school-involvement-heading {
      margin-top: -15rem;
    }

    & > div.timeline {
      flex: 1;
      padding: 1rem 0;
      overflow: hidden;
      position: relative;
      // display: flex;

      &:after {
        // timeline
        content: "";
        position: absolute;
        width: 4px;
        background: ${({ theme }) => theme.colors.grey5};
        top: 0;
        height: 100%;
        left: 50%;
        margin-left: -2px;
      }

      & > div {
        width: 50%;
        position: relative;
      }

      & > div.left {
        h2 {
          font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
        }
        left: 0;
        padding: 1rem 7rem 1rem 1rem;

        .icon {
          right: 18px;
        }
      }

      & > div.right {
        h2 {
          font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
        }
        left: 50%;
        padding: 1rem 1rem 1rem 7rem;

        .icon {
          left: 18px;
        }

        &:after {
          left: -8px;
        }

        &:before {
          left: 8px;
        }
      }
    }
  }

  & .time {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
    width: 5rem;
    color: white;
    text-align: center;
  }

  .icon {
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;
    top: calc(50% - 20px);
    border-style: solid;
    border-width: 4px;
    border-radius: 20px;
    text-align: center;
    z-index: 1;
    padding: 3px;
  }

  .stage1 {
    .time {
      background-color: #98a8fd;
    }
    h2 {
      color: #98a8fd;
    }
    .icon {
      border-color: #98a8fd;
    }
  }

  .stage2 {
    .time {
      background-color: #4c5ebc;
    }
    h2 {
      color: #4c5ebc;
    }
    .icon {
      border-color: #4c5ebc;
    }
  }

  .stage3 {
    .time {
      background-color: #303b71;
    }
    h2 {
      color: #303b71;
    }
    .icon {
      border-color: #303b71;
    }
  }

  .stage4 {
    .time {
      background-color: #98d82a;
    }
    h2 {
      color: #98d82a;
    }
    .icon {
      border-color: #98d82a;
    }
  }

  .stage5 {
    .time {
      background-color: #2cc4d9;
    }
    h2 {
      color: #2cc4d9;
    }
    .icon {
      border-color: #2cc4d9;
    }
  }

  .stage6 {
    .time {
      background-color: #b52bd8;
    }
    h2 {
      color: #b52bd8;
    }
    .icon {
      border-color: #b52bd8;
    }
  }

  .stage7 {
    .time {
      background-color: #98a8fd;
    }
    h2 {
      color: #98a8fd;
    }
    .icon {
      border-color: #98a8fd;
    }
  }

  .arrow-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 7px;
    right: calc(((100% - 7rem + 2px) / 2));
    font-weight: bold;
    stroke-width: 3px;
  }

  .arrow-down-large {
    width: 50%;
    margin-left: 20%;
    margin-top: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    & > div {
      .school-involvement-heading {
        display: none;
      }
      & > div.timeline {
        &:after {
          // timeline
          left: 20px;
        }

        & > div.left,
        & > div.right {
          width: 100%;
          padding-left: 8rem;
          padding-right: 1rem;

          .icon {
            left: 36px;
          }
        }

        & > div.right {
          left: 0%;
        }
      }
    }
    .arrow-icon {
      right: auto;
      left: calc(5.5rem + 5px);
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    & > div {
      & > div.timeline {
        & > div.left,
        & > div.right {
          padding-left: 36px;
          padding-top: 4rem;

          .icon {
            top: 0;
          }
        }
      }
    }
  }
`;

const TimelinePage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>SAMHE Timeline</PageTitle>
      </SectionBackground>
      <TimelineDiv>
        <div className="stage1">
          <div className="time">
            Apr
            <br /> 2022
          </div>
          <div className="timeline">
            <div className="left">
              <h2>Forming a team and getting funding</h2>
              <img src="images/timeline/team-communication-icon.svg" alt="team communication icon" className="icon" />
              <P>
                A group of interested researchers get together and come up with the idea for SAMHE. They apply for
                funding and are given money to run the project by the Department for Education and the Engineering and
                Physical Sciences Research Council (EPSRC).
              </P>
            </div>
            <div className="right school-involvement-heading">
              <h1>Schools' involvement</h1>
              <img src="images/timeline/arrow-down-large.svg" alt="arrow icon" className="arrow-down-large" />
            </div>
          </div>
        </div>

        <div className="stage2">
          <div className="time">
            May
            <br />-<br />
            July
            <br /> 2022
          </div>
          <div className="timeline">
            <div className="left">
              <h2>Planning</h2>
              <img src="images/timeline/design-thinking-icon.svg" alt="design thinking icon" className="icon" />
              <P>
                The team comes up with lots of ideas about how the project could support schools and how we can best use
                the air quality data from the monitors. Then we narrow them down to the ones we think are most
                important.
              </P>
            </div>
          </div>
        </div>

        <div className="stage3">
          <div className="time">
            July
            <br />-<br />
            Oct
            <br /> 2022
          </div>
          <div className="timeline">
            <div className="right">
              <h2>Co-design with schools</h2>
              <img src="images/timeline/online-survey-icon.svg" alt="online survey icon" className="icon" />
              <P>
                Pupils and teachers from 22 schools meet with us to help answer key questions about what the interactive
                Web App should look like and how it should work, so that it is fun and useful for schools.
              </P>
            </div>
            <div className="left">
              <h2>Creating the Web App</h2>
              <img src="images/timeline/web-development-icon.svg" alt="web development icon" className="icon" />
              <P>Our web developers build the Web App, using the ideas from co-design schools and the team.</P>
            </div>
          </div>
        </div>

        <div className="stage4">
          <div className="time">
            Nov
            <br />
            2022
            <br />-<br />
            Apr
            <br /> 2023
          </div>
          <div className="timeline">
            <div className="right">
              <img src="images/timeline/comments-icon.svg" alt="comments icon" className="icon" />
              <h2>Beta testing with schools</h2>
              <P>
                100+ schools which have agreed to be 'pioneer schools' receive SAMHE air quality monitors and help us
                test the Web App.
              </P>
            </div>
            <div className="left">
              <h2>Improving the Web App</h2>
              <img src="images/timeline/web-software-icon.svg" alt="web software icon" className="icon" />
              <P>Our web developers add to and adapt the Web App using the feedback from pioneer schools.</P>
            </div>
          </div>
        </div>

        <div className="stage5">
          <div className="time">
            Apr
            <br /> 2023
          </div>
          <div className="timeline">
            <div className="right">
              <h2>Launch of SAMHE!</h2>
              <img
                src="images/timeline/product-launch-release-icon.svg"
                alt="product launch release icon"
                className="icon"
              />
              <P>
                We send out SAMHE air quality monitors to 1000+ schools all over the UK. Thanks to the help from
                co-design and pioneer schools, we now have a brilliant Web App that schools can use to see and interact
                with the data from their monitor.
              </P>
            </div>
          </div>
        </div>

        <div className="stage6">
          <div className="time">
            Apr
            <br />
            2023
            <br />-<br />
            Aug
            <br /> 2024
          </div>
          <div className="timeline">
            <div className="left">
              <h2>Scientists learn more about air quality in schools</h2>
              <img
                src="images/timeline/growing-market-analysis-icon.svg"
                alt="growing market analysis icon"
                className="icon"
              />
              <P>
                Our scientists study the data coming in from all the school air quality monitors. We share our results
                with the UK government, schools and other organisations who can use them to make schools healthier
                places for learning.
              </P>
            </div>
            <div className="right">
              <h2>SAMHE Web App keeps getting better</h2>
              <img src="images/timeline/update-icon.svg" alt="update icon" className="icon" />
              <P>
                Schools using SAMHE let us know how it is working for them and give us ideas for new activities for the
                Web App and other ways to make it better. Our web developers continue to add to and improve it.
              </P>
            </div>
          </div>
        </div>

        <div className="stage7">
          <div className="time">Aug 2024 and beyond</div>
          <div className="timeline">
            <div className="left">
              <h2>The SAMHE Initiative</h2>
              <img src="images/timeline/dna.svg" alt="DNA icon" className="icon" />
              <P>
                Our initial funding for the SAMHE project ran until 31<sup>st</sup> July 2024, but we still have lots we'd like to
                achieve so we enter a new phase and become the 'SAMHE Initiative'. The SAMHE Initiative continues
                working with schools and supporting further analysis of the SAMHE data, to benefit UK schools and their
                communities.
              </P>
            </div>
            <div className="right">
              <h2>Schools continue to benefit from SAMHE</h2>
              <img src="images/timeline/link-solid.svg" alt="link icon" className="icon" />
              <P>
                Support for the Web App, and all the resources on this website has been secured long into the future
                (until at least 2030) so SAMHE schools can continue to use their monitor to investigate their indoor
                environment. Some SAMHE schools use their monitor to evaluate the impact of efforts to improve air
                quality. Schools which don't have a SAMHE monitor can use the free Teacher Resource Pack to engage with
                SAMHE activities and learn about indoor air quality.
              </P>
            </div>
          </div>
        </div>
        <img src="images/timeline/arrow-down.svg" alt="arrow icon" className="arrow-icon" />
      </TimelineDiv>
    </ConstrainedPageStyle>
  );
};

export default TimelinePage;
