import React from "react";
import { AppSectionHeading, KeyIconStyle, KeyItemLabelButton, KeySectionStyle, KeyStyle } from "../../styles/app";
import { FilterStateContext } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { DataViewFields } from "../../lib/DataViews/IDataView";

export const DataViewKeyItem = ({ icon, label, id }: { icon: string; label: string; id: any }) => {
  const { filters, updateFilterValue } = React.useContext(FilterStateContext);

  return (
    <KeySectionStyle>
      <KeyItemLabelButton
        on={filters.find((f) => f.uid === id)?.value}
        onClick={() => updateFilterValue(id, !filters.find((f) => f.uid === id)?.value)}
      >
        <KeyIconStyle src={icon} alt={label} style={{ marginRight: "0.5rem" }} />
        <span>{label}</span>
      </KeyItemLabelButton>
    </KeySectionStyle>
  );
};

export const DataViewKey = () => (
  <>
    <KeyStyle>
      {DataViewFields.map((f) => (
        <DataViewKeyItem key={f.filter.uid} icon={f.icon} label={f.filter.label} id={f.filter.uid} />
      ))}
    </KeyStyle>
  </>
);

export const DataViewDashboardKey = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AppSectionHeading style={{ color: "#0091a5", marginRight: "20px" }}>
        DataViews <br></br>key
      </AppSectionHeading>
      <div>
        <DataViewKey />
      </div>
    </div>
  );
};
