import React from "react";
import { LedColors, monitorLeds } from "../../lib/Monitor/monitorLeds";
import { SectionHeading, SubSectionHeading, P, Section, HorizontalScroll, FloatImageMax, Ul } from "../../styles/page";
import { TableStyle } from "../../styles/table";
import { AExternal, DownloadLink } from "../Links";

export const MonitorLedsCo2 = ({ glossaryClass = true }) => {
  return (
    <>
      <Section>
        <SectionHeading id="co2" className={glossaryClass ? "hideFromGlossary" : ""}>
          CO<sub>2</sub> (carbon dioxide)
        </SectionHeading>
        <SubSectionHeading className={glossaryClass ? "hideFromGlossary" : ""}>Understanding the monitor lights</SubSectionHeading>
        <P>
          The table below provides a quick overview of what the LEDs tell you about CO<sub>2</sub> levels and what those
          levels mean for your wellbeing, and gives some recommended actions.
        </P>
        <P>
          If you'd like to know what a specific LED combination means please consult our{" "}
          <DownloadLink href="/resources/SAMHE_Monitors_LED_guide_normal_operation.pdf">
            full guide to SAMHE monitor LEDs during normal operation
          </DownloadLink>
          .
        </P>
        <HorizontalScroll>
          <TableStyle>
            <tr>
              <th>Monitor CO₂ Reading</th>
              <th>LED colour</th>
              <th>Number of LEDs</th>
              <th>Description</th>
              <th>What this means for you</th>
              <th>Actions</th>
            </tr>
            {monitorLeds.map((m) => (
              <tr key={m.noOfLeds}>
                <td>
                  {m.lowerLimit}
                  {m.upperLimit === "+" ? "+" : `-${m.upperLimit}`} ppm
                </td>
                <td>
                  {LedColors[m.color].label}
                  <div
                    style={{
                      background: LedColors[m.color].color,
                      width: "2rem",
                      height: "2rem",
                      borderRadius: "2rem",
                    }}
                  />
                </td>
                <td>{m.noOfLeds}</td>
                <td>{m.description}</td>
                <td>{m.whatItMeans}</td>
                <td>{m.whatYouCanDo}</td>
              </tr>
            ))}
          </TableStyle>
        </HorizontalScroll>
      </Section>
      <Section>
        <SectionHeading id="how-are-the-thresholds-for-each-range-of-co2-concentration-determined">
          How are the thresholds for each range of CO<sub>2</sub> concentration determined?
        </SectionHeading>
        <P>
          The thresholds have been set based on advice from the{" "}
          <AExternal href="https://www.gov.uk/government/organisations/scientific-advisory-group-for-emergencies">
            UK Government's 'Scientific Advisory Group for Emergencies' (SAGE)
          </AExternal>
        </P>
        <P>
          <b>The two most relevant publications from SAGE are as follows:</b>
          <br></br>
          <br></br>
          <Ul>
            <li>
              <AExternal href="https://www.gov.uk/government/publications/emg-role-of-ventilation-in-controlling-sars-cov-2-transmission-30-september-2020">
                SAGE EMG: Role of ventilation in controlling SARS-CoV-2 transmission
              </AExternal>
            </li>
            <li>
              <AExternal href="https://www.gov.uk/government/publications/emg-and-spi-b-application-of-co2-monitoring-as-an-approach-to-managing-ventilation-to-mitigate-sars-cov-2-transmission-27-may-2021">
                SAGE EMG and SPI-B: Application of CO₂ monitoring as an approach to managing ventilation to mitigate
                SARS-CoV-2 transmission.
              </AExternal>
            </li>
          </Ul>
        </P>
      </Section>
    </>
  );
};

export const MonitorLedsContent = (
  <>
    <Section>
      <SectionHeading id="what-you-can-see-on-the-monitor" className="hideFromGlossary">
        What you can see on the monitor
      </SectionHeading>
      <FloatImageMax src="/images/monitor-leds/Monitor-panorama.jpg" alt="SAMHE Monitor panorama" />
      <SubSectionHeading id="numbers">Numbers</SubSectionHeading>
      <P>
        The SAMHE air quality monitors measure carbon dioxide (CO<sub>2</sub>), total volatile organic compounds (TVOCs)
        particulate matter (PM2.5), temperature and relative humidity. The levels of each of these are shown as numbers
        on the monitor display.
      </P>
      <P>
        <b>SAMHE Monitors record:</b>
      </P>
      <FloatImageMax
        style={{ borderRadius: "0", marginTop: "0" }}
        src="/images/monitor-leds/moniitor_readings_page_GIFv2notyping.gif"
        alt="Monitor readings"
      />
      <br></br>
      <SubSectionHeading id="lights">Lights</SubSectionHeading>
      <P>
        As well as being displayed as a number, the LEDs (coloured lights) displayed on your SAMHE monitor also
        correspond to the level of CO<sub>2</sub> detected.
      </P>
      <FloatImageMax
        style={{ borderRadius: "0" }}
        src="/images/monitor-leds/monitor_readings_page_GIF.gif"
        alt="Monitor lights"
      />
    </Section>
    <MonitorLedsCo2 glossaryClass={false} />
  </>
);

export const MonitorLEDs = () => {
  return MonitorLedsContent;
};
