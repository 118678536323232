import React from "react";
import styled from "styled-components";
import { PLong, Section } from "../../styles/page";
import { TableStyle } from "../../styles/table";
import { CTAButtonStyle } from "../../styles/button";

const codes = [
  {
    q: "Tutorial",
    code: "c062",
  },
  {
    q: "Quiz: Science of ventilation",
    code: "c525",
  },
  {
    q: "Understanding your SAMHE monitor: temperature",
    code: "c876",
  },
  {
    q: "Understanding your SAMHE monitor: humidity",
    code: "c702",
  },
  {
    q: "Understanding your SAMHE monitor: PM₂.₅",
    code: "c512",
  },
  {
    q: "Understanding your SAMHE monitor: TVOCs",
    code: "c477",
  },
  {
    q: "Understanding your SAMHE monitor: CO₂",
    code: "c467",
  },
  {
    q: "Temperature changes throughout the day",
    code: "c374",
  },
  {
    q: "CO₂ levels of an empty classroom",
    code: "c050",
  },

  {
    q: "Write to your MP",
    code: "c732",
  },
  {
    q: "Something has changed in my classroom",
    code: "c434",
  },
  {
    q: "Move your monitor",
    code: "c909",
  },
  {
    q: "Describe your room",
    code: "c814",
  },
  {
    q: "CO₂ levels before and after exercise",
    code: "c447",
  },
  {
    q: "Data Detectives",
    code: "c530",
  },
  {
    q: "End of term SAMHE Quiz",
    code: "c253",
  },
  {
    q: "Sources of air pollution indoors",
    code: "c053",
  },
  {
    q: "Describe your building",
    code: "c488",
  },
  {
    q: "SAMHE careers activity",
    code: "c381",
  },
  {
    q: "Design a SAMHE poster",
    code: "c105",
  },
];

interface IClickToRevealButtonProps {
  code: string;
}

export const ClickToRevealButton: React.FC<IClickToRevealButtonProps> = ({ code }) => {
  const [showCode, setShowCode] = React.useState(false);

  // Function to handle button click
  const handleButtonClick = () => {
    // Hide the button by setting setShowCode to true
    setShowCode(true);
  };

  return <>{showCode ? code : <CTAButtonStyle onClick={handleButtonClick}>Click to reveal</CTAButtonStyle>}</>;
};

const CompleteCodeTableStyle = styled(TableStyle)`
  width: 100%;
`;

export const CompleteCodeContent = () => (
  <Section>
    <PLong>
      We advise that pupils complete our activities in a specific order to ensure that they have a sufficient
      understanding key concepts which may be new to them, before exploring their data in more detail through other
      activities. If you'd like to bypass this your pupils can input the relevant completion code(s). If users are
      completing activities as a class or in small groups codes can also be used to share credit between everyone
      involved, so that each user gains points towards Web App badges.
    </PLong>
    <CompleteCodeTableStyle>
      <tr>
        <th>Web App Activity Name</th>
        <th>Completion code</th>
      </tr>
      {codes.map((c) => (
        <tr key={c.code}>
          <td>{c.q}</td>
          <td>
            <ClickToRevealButton code={c.code} />
          </td>
        </tr>
      ))}
    </CompleteCodeTableStyle>
  </Section>
);
