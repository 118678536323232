import { Uid } from "@react_db_client/constants.client-types";
import { ImageUrl } from "../Primatives/Paths";
import { UserGroup } from "../User/UserGroup";

export type TResourceId = Uid;

export enum EResourceType {
  DOWNLOAD = 1,
  CERTIFICATE = 2,
  POPUP_INFO = 3,
}

export interface IAppResource {
  id: TResourceId;
  label: string;
  resourceType: EResourceType;
  description: string;
  resourceUrl: string;
  logo?: ImageUrl;
  thumbnailUrl?: ImageUrl;
  restrictedAccessGroups?: UserGroup[];
}
