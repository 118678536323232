import { IQuestion } from "../Forms/IQuestion";
import { version, lastUpdated } from "../../components/Documents/ParticipationInfo";
import { Bit } from "../common";
import { HowDidYouHearAboutUs } from "./IHowDidYouHear";

export const SchoolFundingTypes = {
  1: { uid: 1, label: "Yes" },
  2: { uid: 2, label: "No" },
} as const;

export type ESchoolFundingTypes = keyof typeof SchoolFundingTypes;

export const SchoolTypes = {
  1: { uid: 1, label: "KS1 (age 5-7)" },
  2: { uid: 2, label: "KS2 (age 7-11)" },
  4: { uid: 4, label: "KS3 (age 11-14)" },
  8: { uid: 8, label: "KS4 (age 14-16)" },
  16: { uid: 16, label: "KS5 (age 16-18)" },
} as const;

export type ESchoolTypes = keyof typeof SchoolTypes;

export const SchoolSizes = {
  1: { uid: 1, label: "Less than 100" },
  2: { uid: 2, label: "100 to 500" },
  4: { uid: 4, label: "500 to 1000" },
  8: { uid: 8, label: "1000+" },
} as const;

export type ESchoolSizes = keyof typeof SchoolSizes;

export const SchoolWasBuiltIn = {
  1: { uid: 1, label: "Pre-1919" },
  2: { uid: 2, label: "1919-1944" },
  4: { uid: 4, label: "1945-1966" },
  8: { uid: 8, label: "1967-1976" },
  16: { uid: 16, label: "Post-1976" },
  32: { uid: 32, label: "Mixture of ages" },
  64: { uid: 64, label: "Don't know" },
} as const;

export type ESchoolWasBuiltIn = keyof typeof SchoolWasBuiltIn;

export const SubmitterRoles = {
  1: { uid: 1, label: "Senior leadership team" },
  2: { uid: 2, label: "Teacher (science)" },
  4: { uid: 4, label: "Teacher (other)" },
  8: { uid: 8, label: "Teaching assistant or learning support assistant" },
  16: { uid: 16, label: "Lab or technical staff" },
  32: { uid: 32, label: "Site staff or caretaker" },
  64: { uid: 64, label: "School support staff" },
} as const;

export type ESubmitterRoles = keyof typeof SubmitterRoles;

export const InterestReasons = {
  1: { uid: 1, label: "I am concerned about air quality and the health impacts it can have" },
  2: { uid: 2, label: "I am concerned about pollution from a busy road near my school" },
  4: { uid: 4, label: "I am concerned about the connection between poor ventilation and COVID-19 transmission" },
  8: { uid: 8, label: "I would like to see how air quality varies around my school" },
  16: { uid: 16, label: "I am concerned about ventilation in my school and would like a way to test this" },
  32: { uid: 32, label: "I would like to use the monitor as an educational tool to discuss air quality" },
  64: {
    uid: 64,
    label: "I run a science club and would like to carry out experiments around the school to test air quality",
  },
  128: { uid: 128, label: "A student asked me to request a monitor" },
};

export type EInterestReasons = keyof typeof InterestReasons;

export interface IMonitorRequestFormData {
  // id: number;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  schoolEmail: string;
  confirmSchoolEmail: string;
  schoolTelephone: string;
  submitterRole: { uid: ESubmitterRoles; label: string };
  requestReasons: boolean[];
  requestReasonOther?: string;
  confirmSchoolDetails: boolean;
  useAlternateDeliveryAddress: boolean;
  schoolFundingType: { uid: ESchoolFundingTypes; label: string };
  schoolType: boolean[];
  schoolScienceClub?: boolean;
  schoolScienceClubDetails?: string;
  schoolSize: { uid: ESchoolSizes; label: string };
  schoolBuildingAge: { uid: ESchoolWasBuiltIn; label: string };
  howDidYouFindUs: [];
  howDidYouFindUsOther: string;
  consentToParticipant: boolean;
  consentToVoluntary: boolean;
  consentToTakePart: boolean;
  consentToMonitor: boolean;
  consentToContact: boolean;
  consentToNewsletter: boolean;
  altSchoolAddress1: string;
  altSchoolAddress2: string;
  altSchoolAddress3: string;
  altSchoolAddress4: string;
  altSchoolAddressPostCode: string;
}

export const monitorRequestFormDefaultValues: IMonitorRequestFormData = {
  firstName: "",
  lastName: "",
  email: "",
  confirmEmail: "",
  schoolEmail: "",
  confirmSchoolEmail: "",
  schoolTelephone: "",
  submitterRole: null,
  requestReasons: [],
  requestReasonOther: "",
  confirmSchoolDetails: false,
  useAlternateDeliveryAddress: false,
  schoolFundingType: null,
  schoolType: [],
  schoolScienceClub: null,
  schoolScienceClubDetails: "",
  schoolSize: null,
  schoolBuildingAge: null,
  howDidYouFindUs: null,
  howDidYouFindUsOther: "",
  consentToParticipant: false,
  consentToVoluntary: false,
  consentToTakePart: false,
  consentToMonitor: false,
  consentToContact: false,
  consentToNewsletter: false,
  altSchoolAddress1: "",
  altSchoolAddress2: "",
  altSchoolAddress3: "",
  altSchoolAddress4: "",
  altSchoolAddressPostCode: "",
};

export interface IMonitorRequestSubmission {
  // id: number;
  firstName: string;
  lastName: string;
  email: string;
  schoolEmail: string;
  schoolTelephone: string;
  submitterRole: Bit;
  requestReasons: number[];
  requestReasonOther?: string;
  confirmSchoolDetails: boolean;
  useAlternateDeliveryAddress: boolean;
  establishmentId: string;
  schoolName: string;
  schoolFundingType: Bit;
  schoolType: number[];
  schoolScienceClub?: boolean;
  schoolScienceClubDetails?: string;
  schoolSize: Bit;
  schoolBuildingAge: Bit;
  schoolAddress1: string;
  schoolAddress2: string;
  schoolAddress3: string;
  schoolAddress4: string;
  schoolAddressPostCode: string;
  howDidYouFindUs: number[];
  howDidYouFindUsOther: string;
  consentToParticipant: boolean;
  consentToVoluntary: boolean;
  consentToTakePart: boolean;
  consentToMonitor: boolean;
  consentToContact: boolean;
  consentToNewsletter: boolean;
}

export const monitorRequestQuestions: { [p in keyof IMonitorRequestFormData]: IQuestion } = {
  consentToParticipant: {
    name: "consentToParticipant",
    label: `I confirm that I have read and understand the participant information sheet version ${version} dated
${lastUpdated} for the above study and have had the opportunity to ask questions which have been answered fully.`,
    type: "checkbox",
    required: true,
  },
  consentToVoluntary: {
    name: "consentToVoluntary",
    label: `I understand that my participation is voluntary, and I am free to withdraw at any time, without giving any
reason and without my legal rights being affected.`,
    type: "checkbox",
    required: true,
  },
  consentToTakePart: {
    name: "consentToTakePart",
    label: "I consent to take part in the above study.",
    type: "checkbox",
    required: true,
  },
  consentToMonitor: {
    name: "consentToMonitor",
    label: `I understand that the monitor my school will receive from the SAMHE project is a gift.
By accepting this gift, my school is accepting responsibility for the monitor, its safe usage within the school, and any liability that arises as a result.`,
    type: "checkbox",
    required: true,
  },
  consentToContact: {
    name: "consentToContact",
    label: "I give consent to being contacted about opportunities to take part in other research studies.",
    type: "checkbox",
  },
  consentToNewsletter: {
    name: "consentToNewsletter",
    label: "I give consent for my email to be used to send me a SAMHE newsletter and email updates.",
    type: "checkbox",
  },
  firstName: {
    name: "firstName",
    label: "First Name",
    type: "string",
    required: true,
  },
  lastName: {
    name: "lastName",
    label: "Last Name",
    type: "string",
    required: true,
  },
  email: {
    name: "email",
    label: "Email",
    type: "email",
    required: true,
    placeholder: "Work email preferred"
  },
  confirmEmail: {
    name: "confirmEmail",
    label: "Confirm Email",
    type: "email",
    required: true,
    validateOnBlur: true,
  },
  submitterRole: {
    name: "submitterRole",
    label: "Which description best fits your role at the school?",
    type: "select",
    options: Object.values(SubmitterRoles),
  },
  schoolEmail: {
    name: "schoolEmail",
    label: "Generic office email address",
    type: "email",
    required: true,
  },
  confirmSchoolEmail: {
    name: "confirmSchoolEmail",
    label: "Confirm generic office email address",
    type: "email",
    required: true,
    validateOnBlur: true,
  },
  confirmSchoolDetails: {
    name: "confirmSchoolDetails",
    label: "Confirm selection",
    type: "checkbox",
    required: true,
  },
  useAlternateDeliveryAddress: {
    name: "useAlternateDeliveryAddress",
    label: "I'd like to supply a different delivery address",
    type: "checkbox",
  },
  schoolTelephone: {
    name: "schoolTelephone",
    label: "School Contact Number",
    type: "string",
    required: true,
  },
  schoolFundingType: {
    name: "schoolFundingType",
    label: "Is your school fee paying?",
    type: "multicheckbox",
    options: Object.values(SchoolFundingTypes),
    required: true,
  },
  schoolType: {
    name: "schoolType",
    label:
      "Which of the following age groups are you planning to use SAMHE with? (Please note that pupils must be aged 5+ to participate in SAMHE)",
    type: "multicheckbox",
    options: Object.values(SchoolTypes),
    multiple: true,
  },
  schoolScienceClub: {
    name: "schoolScienceClub",
    label: "Please check the box if your school has a science or eco-club.",
    type: "checkbox",
  },
  schoolScienceClubDetails: {
    name: "schoolScienceClubDetails",
    label: "If so, please could you provide details.",
    type: "area",
  },
  schoolSize: {
    name: "schoolSize",
    label: `Approximately how many pupils attend this school? (No need to count! This is to get a rough size of your
school, an estimate is fine).`,
    type: "multicheckbox",
    options: Object.values(SchoolSizes),
  },
  schoolBuildingAge: {
    name: "schoolBuildingAge",
    label: "Do you know when your school was built?",
    type: "multicheckbox",
    options: Object.values(SchoolWasBuiltIn),
  },
  requestReasons: {
    name: "requestReasons",
    label: "Why are you requesting an air quality monitor? (select all that apply).",
    type: "multicheckbox",
    options: Object.values(InterestReasons),
    multiple: true,
  },
  requestReasonOther: {
    name: "requestReasonOther",
    label: "Other",
    type: "area",
    placeholder: "If you're requesting an air quality monitor for another reason, please describe it here"
  },
  howDidYouFindUs: {
    name: "howDidYouFindUs",
    label: "Please select one or more of the options below. If you’ve got time, please add more detail in the box below.",
    type: "multicheckbox",
    options: Object.values(HowDidYouHearAboutUs),
    multiple: true,
  },
  howDidYouFindUsOther: {
    name: "howDidYouFindUsOther",
    label: "",
    placeholder: "More detail...",
    type: "area",
  },

  altSchoolAddress1: {
    name: "altSchoolAddress1",
    label: "School Address Line 1",
    type: "string",
  },
  altSchoolAddress2: {
    name: "altSchoolAddress2",
    label: "School Address Line 2",
    type: "string",
  },
  altSchoolAddress3: {
    name: "altSchoolAddress3",
    label: "School Address Line 3",
    type: "string",
  },
  altSchoolAddress4: {
    name: "altSchoolAddress4",
    label: "School Address Line 4",
    type: "string",
  },
  altSchoolAddressPostCode: {
    name: "altSchoolAddressPostCode",
    label: "School Post Code",
    type: "string",
  },
};
