import styled from "styled-components";

export const P = styled.p`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
  //text-align: justify;
  max-width: 36.25rem;
  font-size: ${({ theme }) => theme.typography.fontSize3};
`;

export const PLong = styled.p`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
  font-size: ${({ theme }) => theme.typography.fontSize3};
`;

export const Ul = styled.ul`
  margin-left: ${({ theme }) => theme.shape.defaultPaddingLarger};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  }
  li {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-left: 2rem;
  }
`;

export const Ol = styled.ol`
  margin-left: ${({ theme }) => theme.shape.defaultPaddingLarger};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  }
`;

export const GreenText = styled.span`
  color: green;
  text-decoration: underline;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const ListStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    border-left: 2px solid ${({ theme }) => theme.colors.main};
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
  }
`;

export const BelowHeaderWrap = styled.div`
  flex-grow: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
  }
`;

export const MainContentWrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
`;

const PageStyle = styled.div`
  min-height: 80%;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.fontSize3};
`;

export const PageContentWrapStyle = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
`;

export const ConstrainedPageStyle = styled(PageStyle)`
  background: ${({ theme }) => theme.background};
  box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 21px 1px;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.shape.defaultPaddingWide};
  padding-right: ${({ theme }) => theme.shape.defaultPaddingWide};
  padding-top: ${({ theme }) => theme.shape.defaultPaddingWide};
  max-width: 98%; //showing the boundaries of the box with the shadow instead of taking up the entire width of the screen

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    max-width: 1100px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    padding-left: ${({ theme }) => theme.shape.defaultPaddingSmall};
    padding-right: ${({ theme }) => theme.shape.defaultPaddingSmall};
  }
`;

export default PageStyle;

export const PageTitle = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  margin-top: ${({ theme }) => theme.shape.defaultPaddingMid};
  padding-bottom: 0.2rem;
`;

export const SectionStyle = styled.section`
  margin: ${({ theme }) => theme.shape.defaultPadding} ${({ theme }) => theme.shape.defaultPaddingMid};
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingLarge};
  clear: both;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.xs}) {
    margin-left: ${({ theme }) => theme.shape.defaultPaddingSmall};
    margin-right: ${({ theme }) => theme.shape.defaultPaddingSmall};
  }
`;

export const Section = (props) => <SectionStyle role="region" {...props} />;

export const SectionHeadingQuickLink = styled.h2`
  color: white;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  width: 100%;
  margin: 0 0 0.5rem 0;
`;

export const SectionHeading = styled.h2`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 0.3rem;
    background-color: #2cc4d9;
  }
  margin: 0 0 0.5rem 0;
  margin-left: 1.5rem;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.xs}) {
    &:before {
      left: -0.5rem;
    }
  }
`;

export const SubSectionHeading = styled.h3`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const FormHeading = styled.h2`
  font-weight: bold;
  align-self: start;
  margin-top: ${({ theme }) => theme.shape.defaultPaddingMid};
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  font-size: ${({ theme }) => theme.typography.fontSize4};
  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 0.2rem solid #2cc4d9;
  }
`;

export const SectionHeadingTop = styled.h2`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-top: 0.3rem solid #2cc4d9;
  padding-top: 0.8rem;
  margin: 0 0 0.5rem 0;
  display: unset;
  // padding-bottom: 0.2rem;
`;

export const SectionSubHeading = styled.h3`
  padding-bottom: 0.2rem;
`;

export const PageTextBlock = styled.div`
  margin: 1rem;
  padding-bottom: 0.2rem;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  font-size: ${({ theme }) => theme.typography.fontSize3};
`;

export interface IFlexiFlipWrapProps {
  reverse?: boolean;
}

export const FlexFlipWrap = styled.div<IFlexiFlipWrapProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: ${({ reverse }) => (reverse ? "row" : "row")};
    & > :first-child {
      ${({ reverse }) =>
        reverse
          ? `
        padding-right: 1rem;
        `
          : `
        padding-right: 1rem;
    `};
    }

    & > :last-child {
      ${({ reverse }) =>
        reverse
          ? `
          padding-left: 1rem;
          `
          : `
          padding-left: 1rem;
      `};
    }
  }
`;

export const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const CenteredFlexDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem;
`;

export const CenteredTextContainer = styled.div`
  width: 100%;
  minwidth: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  max-width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 50%;
  }
`;

export const HomeCenteredTextContainer = styled.div`
  width: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  @media only screen and (min-width: 850px) and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: 75%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.larger}) {
    width: 60%;
  }
`;

export interface IFloatImageProps {
  direction?: "left" | "right";
  desktopStyleOverride?: string;
  mobileStyleOverride?: string;
}

export const FloatImage = styled.img<IFloatImageProps>`
  float: ${({ direction }) => direction || "left"};
  width: 100%;
  minwidth: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 1rem 0;
  ${({ mobileStyleOverride }) => mobileStyleOverride};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 50%;
    ${({ direction }) => (!direction || direction === "left" ? "margin-right: 1rem" : "margin-left: 1rem")};
    ${({ desktopStyleOverride }) => desktopStyleOverride};
  }
`;
export const SnapRightImg = styled.img`
  max-width: 100%;
  border-radius: 4rem 0;
  width: 100%:
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    // max-width: 50%;
  }
`;

export const SnapRightImgHome = styled.img`
  max-width: 100%;
  border-radius: 3rem 0;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    height: 80%;
  }
`;

export const FloatImageWrapper = styled.div<IFloatImageProps>`
  float: ${({ direction }) => direction || "left"};
  width: 100%;
  minwidth: 60%;
  margin-bottom: 0.5rem;
  ${({ mobileStyleOverride }) => mobileStyleOverride};
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 50%;
    ${({ direction }) => (!direction || direction === "left" ? "margin-right: 1rem" : "margin-left: 1rem")};
    ${({ desktopStyleOverride }) => desktopStyleOverride};
  }
  img {
    border-radius: 0.3rem 0;
    width: 100%;
  }
`;

export const FloatImageWrapperWithCaption = styled(FloatImageWrapper)<IFloatImageProps>`
  p {
    font-size: ${({ theme }) => theme.typography.fontSize3};
    margin-bottom: 0.5rem;
  }
`;

export const FloatImageWrapperWithCredit = styled(FloatImageWrapperWithCaption)<IFloatImageProps>`
  p.credit {
    a {
      color: black;
      font-weight: 400;
    }
  }
`;

export const InsetDiv = styled.div`
  max-height: 30rem;
  overflow: auto;
  margin-bottom: 1rem;
  outline: 1px solid grey;
  padding: 1rem;
`;

export const Su = styled.span`
  vertical-align: sub;
  font-size: ${({ theme }) => theme.typography.fontSize2};
`;
export const Sp = styled.span`
  vertical-align: super;
  font-size: ${({ theme }) => theme.typography.fontSize2};
`;

export const TableOfContentsWrapper = styled(Section)`
  display: flex;
  .scroll-top-button-wrapper {
    display: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    display: block;
    .scroll-top-button-wrapper {
      display: block;
    }
  }
`;

export const TableOfContents = styled.div`
  display: block;
  width: 12rem;
  padding: 1.5rem 1rem 0 0;
  font-size: small;
  position: sticky;
  top: 0;

  h4 {
    text-decoration: underline;
    font-weight: bold;
  }

  & > ul {
    margin-top: 0.5rem;
    list-style-type: none;
    margin-left: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }

  & > .title {
    font-size: small;
    font-weight: bold;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    padding: 1rem;
  }
`;

export const BorderedTable = styled.table`
  width: 100%;
  &,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.5rem;
  }

  td {
    vertical-align: top;
  }
`;

export const HorizontalScroll = styled.div`
  width: 100%; /* Set the width to fill the available space */
  overflow-x: auto; /* Enable horizontal scrolling */
`;

export const CenteredTextDiv = styled.div`
  width: 100%;
  minwidth: 60%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 50%;
  }
`;

export const FloatImageMax = styled.img`
  width: 100%;
  height: auto;
  minwidth: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 1.5rem 0;
`;

export const SectionBackground = styled.section`
  margin: ${({ theme }) => theme.shape.defaultPadding} ${({ theme }) => theme.shape.defaultPaddingMid};
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  clear: both;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    margin-bottom: 0;
  }
`;

export const Hr = styled.hr`
  width: 4rem;
  height: 4px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: none;
`;
