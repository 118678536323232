/* Contains all configuration variables.
 * These should be set via environment variables
 *
 */

// Ensure server url is correctly formatted

/* Adds a / if the url is not empty and not a full url (i.e has http)
 *
 */
const formatServerUrl = (url) => (url.match(/http:|https:/g) || url === "" ? url : `/${url}`);

export const SERVER_URL = formatServerUrl(process.env.REACT_APP_SERVER_URL || process.env.STORYBOOK_SERVER_URL || "");
export const AUTH_URL = process.env.REACT_APP_AUTH_URL || process.env.STORYBOOK_AUTH_URL || "SET_AUTH_URL";
export const AUTH_URL_REGISTER =
  process.env.REACT_APP_AUTH_URL_REGISTER || process.env.STORYBOOK_AUTH_URL_REGISTER || "SET_AUTH_URL_REGISTER";
export const AUTH_URL_LOGOUT =
  process.env.REACT_APP_AUTH_URL_LOGOUT || process.env.STORYBOOK_AUTH_URL_LOGOUT || "SET_AUTH_URL_LOGOUT";
export const AUTH_URL_ACCOUNT_DELETE =
  process.env.REACT_APP_AUTH_URL_ACCOUNT_DELETE ||
  process.env.STORYBOOK_AUTH_URL_ACCOUNT_DELETE ||
  "SET_AUTH_URL_ACCOUNT_DELETE";
export const AUTH_TOKEN_URL =
  process.env.REACT_APP_AUTH_TOKEN_URL || process.env.STORYBOOK_AUTH_TOKEN_URL || "SET_AUTH_TOKEN_URL";
export const AUTH_SCOPE = process.env.REACT_APP_AUTH_SCOPE || process.env.STORYBOOK_AUTH_SCOPE || "samhe.api openid";
export const AUTH_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
/* If USE_DUMMY_API is true then we reroute some api endpoints to a mock server */
export const USE_DUMMY_API =
  process.env.REACT_APP_USE_DUMMY_SERVER === "ALL" || process.env.STORYBOOK_USE_DUMMY_SERVER === "ALL"
    ? "ALL"
    : process.env.REACT_APP_USE_DUMMY_SERVER === "true" || process.env.STORYBOOK_USE_DUMMY_SERVER === "true";
export const FILE_SERVER_URL = formatServerUrl(
  process.env.STORYBOOK_FILE_SERVER_URL || process.env.REACT_APP_FILE_SERVER_URL || ""
);

export const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY || "NOTFORPRODUCTION";
export const TITLE = process.env.REACT_APP_TITLE || document.title;
export const VERSION = process.env.REACT_APP_VERSION || "UNKNOWN VERSION";
export const BETA_VERSION = process.env.REACT_APP_BETA_VERSION === "true" || false;
export const DEV_MODE = process.env.NODE_ENV === "development";
export const HELP_CONTACT = process.env.REACT_APP_HELP_CONTACT || "MISSINGEMAIL";
export const TWITTER_ID = process.env.REACT_APP_TWITTER_ID || "samheproject";
export const TECH_CONTACT_EMAIL = process.env.REACT_APP_TECH_CONTACT_EMAIL || "techsupport@samhe.org.uk";
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";
export const MSW_LOGGER = process.env.REACT_APP_MSW_LOGGER;
export const MAX_DATA_POINTS =
  process.env.REACT_APP_MAX_DATA_POINTS || process.env.STORYBOOK_MAX_DATA_POINTS || 60 * 24;
export const MAX_DATE_RANGE = parseInt(String(MAX_DATA_POINTS)) * 1000 * 60; // Converts minutes to milliseconds
export const MIN_DATE_RANGE = 1000 * 60 - 1;
export const MONITOR_REFRESH_RATE =
  parseFloat(process.env.REACT_APP_MONITOR_REFRESH_RATE) ||
  parseFloat(process.env.STORYBOOK_MONITOR_REFRESH_RATE) ||
  45 * 1000;

export const ROOM_SELECTION_COOKIE_TIMEOUT_HOURS =
  Number(process.env.REACT_APP_ROOM_SELECTION_COOKIE_TIMEOUT_HOURS) || 12;

/* === */

const hours = (h) => 1000 * 60 * 60 * h;
const minutes = (m) => 1000 * 60 * m;

export const sessionConfig = {
  shortTermTimeout: minutes(1),
  mediumTermTimeout: hours(1),
  longTermTimeout: hours(999999), // Logout handled by session token instead
};
