/* eslint-disable max-len*/
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Header } from "../components/Header";
import HomePage from "../pages/home";
import CodesignPage from "../pages/codesign";
import ResourcesPage from "../pages/resources";
import NewsPage from "../pages/news";
import SupportPage from "../pages/support";
import TechContactPage from "../pages/techSupportContact";
import GetInvolvedPage from "../pages/getinvolved";
import PioneerSchoolsPage from "../pages/pioneerSchools";
import PioneerSchoolsInvitePage from "../pages/pioneerSchoolsInvite";
import NewsletterSignupPage from "../pages/newsletterSignup";
import SchoolRegistrationPage, { SchoolRegistrationClosePage } from "../pages/schoolRegistration";
import SchoolValidationPage from "../pages/schoolValidation";
import SchoolRegistrationSuccessPage from "../pages/schoolRegistrationSuccess";
import UserProfilePage from "../pages/userProfile";
import AboutAirPollutionPage from "../pages/aboutAirPollution";
import AboutPage from "../pages/about";
import AdminParticipationInfoPage from "../pages/participationInfoAdmin";
import ContactSuccessPage from "../pages/contactSuccess";
import TechSupportContactSuccessPage from "../pages/techSupportContactSuccess";
import PrivacyPage from "../pages/privacy";
import RegistrationPrivacyPage from "../pages/privacyRegistration";
import ContactPrivacyPage from "../pages/privacyContact";
import TechContactPrivacyPage from "../pages/privacyTechContact";
import UserAdminPrivacyPage from "../pages/privacyUserAdmin";
import QrPage from "../pages/qr";
import MonitorActivationPage from "../pages/monitorActivation";
import CookiesPage from "../pages/cookies";
import FaqPage from "../pages/faq";
import MonitorConnectionGuidePage from "../pages/monitorConnectionGuide";
import {
  P,
  BelowHeaderWrap,
  ConstrainedPageStyle,
  MainContentWrap,
  PageTitle,
  Section,
  PageContentWrapStyle,
} from "../styles/page";
import AuthWrapper from "../auth/AuthWrapper";
import { sessionConfig } from "../config";
import { Footer } from "../components/Footer";
import { AppFooter } from "../components/Footer/appFooter";
import { CookieSnackbar } from "../GenericComponents/CookieSnackbar";
import VocabSheetPage from "../pages/vocabSheet";
import { PingTest } from "../GenericComponents/PingTest";
import { UserGroup } from "../lib/User/UserGroup";
import { useIdleManager } from "../GenericComponents/SessionManager/SessionManager";
import AppHeader from "../components/Header/appHeader";
import TvocsAndPmsPage from "../pages/tvocsAndPms";
import MonitorPlacementGuidePage from "../pages/monitorPlacementGuide";
import MonitorLEDPage from "../pages/monitorReadings";
import VentilationGuidancePage from "../pages/ventilation";
import AuthenticationRequired from "../auth/AuthProvider";
import SchoolAdminPage from "../pages/schoolAdmin";
import TeamPage from "../pages/team";
import TimelinePage from "../pages/timeline";
import SafetyDataProtectionPage from "../pages/safetyDataProtection";
import MediaPage from "../pages/media";
import LaunchOfferPage from "../pages/launchOffer";
import GettingStartedPage from "../pages/gettingStarted";
import AirCleaningDevicesPage from "../pages/airCleaningDevices";
import KeywordsListPage from "../pages/keywordsList";
import AirPollutionAndHealthPage from "../pages/airPollutionAndHealth";
import MonitorLinkingPage from "../pages/monitorLinkingPage";
import { AppWrapperStyle } from "../styles/app";
import { AppDashboard } from "../components/AppDashboard";
import { AppInnerRoutes } from "../pages/webApp/samheAppHome";
import ConnectionCompetitionPage from "../pages/connectionCompetition";
import OutputsPage from "../pages/outputs";
import SchoolActivationPage from "../pages/schoolActivation";
import { RequiresActivatedMonitor } from "../auth/RequiresActivatedMonitor";
import { RequiresActivatedSchool } from "../auth/RequiresActivatedSchool";
import { DebugBar } from "../components/DebugBar/DebugBar";
import CurriculumLinksPage from "../pages/curriculumLinks";
import { RoomSelectionPrompt } from "../components/MonitorLocationSelection/MonitorRoomSelectionPrompt";
import HowSAMHEWorkedPage from "../pages/howSamheWorked";

export const PageContentWrap = ({ children }) => (
  <PageContentWrapStyle data-testid="pageContentInner">{children}</PageContentWrapStyle>
);

export function SchoolRoutes() {
  return (
    <AuthenticationRequired>
      <AppHeader />
      <AuthWrapper
        allowedUserGroups={[
          UserGroup.SUPERADMIN,
          UserGroup.DEPLOYMENTADMIN,
          UserGroup.SCHOOLADMIN,
          UserGroup.STUDENT,
          UserGroup.TEACHER,
        ]}
      >
        <RequiresActivatedSchool>
          <PageContentWrap>
            <Routes>
              <Route
                path="/admin"
                element={
                  <AuthWrapper allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN]}>
                    {/* TODO: Should allow showing this when the currently selected monitor is not activated */}
                    <SchoolAdminPage />
                  </AuthWrapper>
                }
              />
              <Route path=":school_id">
                <Route path="user/*" element={<UserProfilePage />} />
                <Route path="*" element={<div>Page not found!</div>} />
                {/* <Route path="*" element={<div>PLACEHOLDER - school/:school_id</div>} /> */}
              </Route>
            </Routes>
          </PageContentWrap>
        </RequiresActivatedSchool>
        <PingTest />
      </AuthWrapper>
    </AuthenticationRequired>
  );
}
export function UserRoutes() {
  return (
    <AuthenticationRequired>
      <AppHeader />
      <PageContentWrap>
        <Routes>
          <Route path="/" element={<UserProfilePage />} />
          <Route path="/profile/*" element={<UserProfilePage />} />
        </Routes>
      </PageContentWrap>
      <PingTest />
    </AuthenticationRequired>
  );
}

export function WebAppRoutes() {
  const contentRef = React.useRef(null);
  useScrollOnChange(contentRef);
  return (
    <AuthenticationRequired>
      <AppWrapperStyle data-testid="samheAppWrapper" className="samheAppWrapper">
        <AppHeader />
        <BelowHeaderWrap>
          <MainContentWrap className="mainContent_wrap" ref={contentRef}>
            <AuthWrapper
              allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.STUDENT, UserGroup.TEACHER]}
            >
              <RequiresActivatedSchool>
                <RequiresActivatedMonitor>
                  <RoomSelectionPrompt />
                  <Routes>
                    <Route path="/" element={<AppDashboard />} />
                    <Route path="*" element={<AppInnerRoutes />} />
                  </Routes>
                </RequiresActivatedMonitor>

                <AppFooter />
              </RequiresActivatedSchool>
            </AuthWrapper>
          </MainContentWrap>
        </BelowHeaderWrap>
        <DebugBar />
        <PingTest />
      </AppWrapperStyle>
    </AuthenticationRequired>
  );
}

export function QrRoutes() {
  return (
    <AuthenticationRequired>
      <AppHeader />
      <AuthWrapper allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.DEPLOYMENTADMIN, UserGroup.SCHOOLADMIN]}>
        <PageContentWrap>
          <Routes>
            <Route path="/:qrcode" element={<QrPage />} />
            <Route path="/*" element={<QrPage />} />
          </Routes>
        </PageContentWrap>
      </AuthWrapper>
      <PingTest />
    </AuthenticationRequired>
  );
}

export function SetupRoutes() {
  return (
    <AuthenticationRequired>
      <AppHeader />
      <AuthWrapper allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN]}>
        <PageContentWrap>
          <Routes>
            <Route
              path="/monitor/:qrcode"
              element={
                <RequiresActivatedSchool>
                  <MonitorActivationPage />
                </RequiresActivatedSchool>
              }
            />
            <Route
              path="/monitor/*"
              element={
                <RequiresActivatedSchool>
                  <MonitorActivationPage />
                </RequiresActivatedSchool>
              }
            />
            <Route path="/school/*" element={<SchoolActivationPage />} />
            <Route path="/*" element={<>Page not found!</>} />
          </Routes>
        </PageContentWrap>
      </AuthWrapper>
      <PingTest />
    </AuthenticationRequired>
  );
}

export function DeploymentRoutes() {
  return (
    <AuthenticationRequired>
      <MainContentWrap className="mainContent_wrap">
        <AppHeader />
        <AuthWrapper allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.DEPLOYMENTADMIN]}>
          <PageContentWrap>
            <Routes>
              <Route path="/school-validation" element={<SchoolValidationPage />} />
              <Route path="/link-monitor" element={<MonitorLinkingPage serialNumber="" />} />
              <Route path="/*" element={<>Page not found!</>} />
            </Routes>
          </PageContentWrap>
        </AuthWrapper>
        <Footer />
      </MainContentWrap>
      <PingTest />
    </AuthenticationRequired>
  );
}

function getHeaderHeight() {
  const header = document.querySelector<HTMLElement>(".header_wrap");
  return header ? header.offsetHeight * 2 + 10 : 0; // underneath the header and add 10px padding
}

function useScrollOnChange(containerRef) {
  const { pathname, hash } = useLocation();
  React.useEffect(() => {
    if (hash) {
      const linkTarget = document.getElementById(hash.replace("#", ""));
      if (!linkTarget) return;
      const headerHeight = getHeaderHeight(); //  header is thicker on desktop
      const containerTop = containerRef?.current?.getBoundingClientRect().top || 0;
      const targetTop = linkTarget?.getBoundingClientRect().top || 0;
      const scrollTopPos = containerRef?.current?.scrollTop || 0;
      const y = linkTarget ? scrollTopPos + containerTop + targetTop - headerHeight : 0;
      containerRef?.current?.scrollTo(0, y);
    } else {
      containerRef?.current?.scrollTo(0, 0);
    }
  }, [pathname, containerRef, hash]);

  return null;
}

export const useGetTitle = () => {
  const { pathname } = useLocation();
  const title = pathname && pathname.split("/").slice(1).join(" ");
  React.useEffect(() => {
    document.title = title ? `SAMHE ${title}` : "SAMHE";
  }, [title]);
};

export const PageComingSoon = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>Page coming soon!</PageTitle>
      <Section>
        <P>This page is being developed by our team of experts. Come back soon to see something interesting!</P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export const PublicFacingRoutes = () => {
  const contentRef = React.useRef(null);
  useScrollOnChange(contentRef);
  return (
    <>
      <Header />
      <BelowHeaderWrap data-testid="samhePublicContent">
        <MainContentWrap className="mainContent_wrap" ref={contentRef} id="mainContent_wrap">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="register/school" element={<SchoolRegistrationClosePage />} />
            <Route path="register-plus" element={<SchoolRegistrationPage />} />
            <Route path="register/school/success" element={<SchoolRegistrationSuccessPage />} />
            <Route path="newsletter-signup" element={<NewsletterSignupPage />} />
            <Route path="activation/:qrcode" element={<SchoolActivationPage />} />
            <Route path="get-involved" element={<GetInvolvedPage />} />
            <Route path="how-samhe-works" element={<HowSAMHEWorkedPage />} />
            <Route path="pioneer-schools/*" element={<PioneerSchoolsPage />} />
            <Route path="pioneer-schools/invite" element={<PioneerSchoolsInvitePage />} />
            <Route path="contact" element={<FaqPage />} />
            <Route path="contact/success" element={<ContactSuccessPage />} />
            <Route path="news" element={<NewsPage />} />
            <Route path="launch-offer" element={<LaunchOfferPage />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="getting-started" element={<GettingStartedPage />} />
            <Route path="support/tech-contact" element={<TechContactPage />} />
            <Route path="support/tech-contact/success" element={<TechSupportContactSuccessPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="about/team" element={<TeamPage />} />
            <Route path="resources" element={<ResourcesPage />} />
            <Route path="resources/monitor-leds" element={<MonitorLEDPage />} />
            <Route path="resources/the-samhe-monitor" element={<PageComingSoon />} />
            <Route path="resources/air-pollution" element={<AboutAirPollutionPage />} />
            <Route path="resources/ventilation" element={<VentilationGuidancePage />} />
            <Route path="resources/key-definitions" element={<VocabSheetPage />} />
            <Route path="resources/tvocs-and-pm25s" element={<TvocsAndPmsPage />} />
            <Route path="resources/monitor-placement-guide" element={<MonitorPlacementGuidePage />} />
            {/* <Route path="resources/parent-zone" element={<ParentZonePage />} /> */}
            <Route path="resources/media" element={<MediaPage />} />
            <Route path="resources/air-cleaning-devices" element={<AirCleaningDevicesPage />} />
            <Route path="resources/air-pollution-and-health" element={<AirPollutionAndHealthPage />} />
            <Route path="resources/outputs" element={<OutputsPage />} />
            <Route path="resources/curriculum-links" element={<CurriculumLinksPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="privacy/registration" element={<RegistrationPrivacyPage />} />
            <Route path="privacy/contact" element={<ContactPrivacyPage />} />
            <Route path="privacy/tech-contact" element={<TechContactPrivacyPage />} />
            <Route path="privacy/user-admin" element={<UserAdminPrivacyPage />} />
            <Route path="safety-and-data-protection" element={<SafetyDataProtectionPage />} />
            <Route path="documents/admin-participation-info" element={<AdminParticipationInfoPage />} />
            <Route path="cookies" element={<CookiesPage />} />
            <Route path="co-design" element={<CodesignPage />} />
            <Route path="monitor-connection-guide" element={<MonitorConnectionGuidePage />} />
            <Route path="timeline" element={<TimelinePage />} />
            <Route path="resources/glossary" element={<KeywordsListPage />} />
            <Route path="connection-competition" element={<ConnectionCompetitionPage />} />
            <Route path="*" element={<div>Page not found!</div>} />
          </Routes>
          <Footer />
        </MainContentWrap>
      </BelowHeaderWrap>
    </>
  );
};

export default function AppRoutes() {
  useGetTitle();
  useIdleManager({ ...sessionConfig });
  return (
    <>
      <CookieSnackbar />
      <Routes>
        <Route path="app/*" element={<WebAppRoutes />} />
        <Route path="user/*" element={<UserRoutes />} />
        <Route path="school/*" element={<SchoolRoutes />} />
        <Route path="deployment/*" element={<DeploymentRoutes />} />
        <Route path="qr/*" element={<QrRoutes />} />
        <Route path="setup/*" element={<SetupRoutes />} />
        <Route path="*" element={<PublicFacingRoutes />} />
      </Routes>
    </>
  );
}
/* eslint-enable max-len*/
