import React from "react";
import { DataEventGuidance } from "../components/Documents/DataEventGuidance";
import { MonitorLedsCo2, MonitorLedsContent } from "../components/Documents/MonitorLEDs";
import { MonitorPlacementGuideContent } from "../components/Documents/MonitorPlacementGuide";
import { TvocsAndPmsInfo } from "../components/Documents/TvocsAndPms";
import { VentilationGuidance } from "../components/Documents/VentilationGuidance";
import { VOCGuidanceDataDetectives } from "../components/Documents/VocGuidanceDataDetectives";
import { AExternal } from "../components/Links";
import { IKeyword } from "../lib/Keyword/IKeyword";
import { GuidanceOnWindowTypes } from "../components/Documents/GuidanceOnWindowTypes";
import { AboutAirPollutioncontent } from "../components/Documents/AboutAirPollution";
import { PmGuidanceDataDetectives } from "../components/Documents/PmGuidanceDataDetectives";
import { TemperatureHumidityInfo } from "../components/Documents/TemperatureHumidity";
import { AirCleaningDevicesContent } from "../pages/airCleaningDevices";
import { MonitorLEDCombinationContent } from "../pages/monitorReadings";

// TODO: Replace all descriptions with markdown.
// TODO: https://github.com/CoTrace-Samhe/FrontEnd.Wrap/issues/201

export const keywordMap: IKeyword[] = [
  {
    uid: "air-cleaner",
    label: "Air Cleaner",
    rtext: ["air cleaner"],
    description: `
An air cleaner is a device that can remove some of the pollutants present in the air. A lot of the devices use a
!HEPA filter! but other technologies also exist. They can sometimes be called air purifiers, air filters or air
cleaning devices. [More information about air cleaners is available on our website](/resources/air-cleaning-devices).
`,
  },
  {
    uid: "air-temperature",
    label: "Air Temperature",
    rtext: ["air temperature"],
    description: `
Air temperature is the !temperature! of the air. It is relatively easy to measure using a thermometer
that is not in direct sunlight. This is the most common way you will see temperature recorded;
it is what is reported in weather forecasts.
`,
  },
  {
    uid: "air-quality-index",
    label: "Air Quality Index",
    rtext: ["air quality index", "AQI", "daily air quality index", "DAQI"],
    description: (
      <>
        <p>
          The{" "}
          <AExternal href="https://uk-air.defra.gov.uk/air-pollution/daqi">Daily Air Quality Index (DAQI)</AExternal> is
          published by DEFRA and it tells you about levels of air pollution and provides recommended actions and health
          advice.
        </p>
        <p>DEFRA is the government Department for Environment, Food and Rural Affairs.</p>
      </>
    ),
  },
  {
    uid: "airborne-infection",
    label: "Airborne Infection",
    rtext: ["airborne infection"],
    description: `
An airborne infection is when a virus has been spread from one person to another through very small particles in the air.
`,
  },
  {
    uid: "approximate",
    label: "Approximate",
    rtext: ["Approximate"],
    description: (
      <>
        <p>We use the word approximate in two different ways which have similar but different meanings.</p>
        <p>
          One of the ways we use the word approximate is as an adjective (a describing word). When we're using
          approximate in this way we mean something that is not 100% accurate but it's close. For example, we might ask
          you to measure the approximate height of your classroom - we mean take a good enough measure to help us
          understand how tall your classroom is but you don't have to specify the number of millimetres.
        </p>
        <p>
          The other way we use the word approximate is as a verb (a doing word). When we use 'approximate' in this way
          we might be asking you to do something, for example, make a good guess of how big your room is, or explaining
          how your data can be used.
        </p>
      </>
    ),
  },
  {
    uid: "area",
    label: "Area",
    rtext: ["area"],
    description: `
Area is used to describe the size of a flat (two dimensional) shape. You can think of area as the number of
square units needed to completely cover a shape.

For any shape where all the angles are right angles (90°) you can work out its area by multiplying its height
by its width.

In the example shown here, the area of this rectangle is 50cm², 10cm (width) x 5cm (height).

This makes sense because if we imagine lining up 10 squares which all measure 1cm wide each they would fit
along the bottom.

Equally, 5 squares measuring 1cm high each would fit along the side.

And - if we used square centimetres to fill up the entire shape we'd have 50 of them!

![Area diagram](/images/glossary/SAMHE_area_diagram.jpg)

Area is always expressed in square units which we write down by adding a small 2 above the unit of
measurement. For example, centimetres squared is written as !cm²! and metres squared is written as !m²!.
`,
  },
  {
    uid: "atom",
    label: "Atom",
    rtext: ["atom", "atoms"],
    description: `
You can think of atoms as the basic building blocks which make up everything around us. Atoms themselves are
made up of other, even smaller, things called electrons, protons, and neutrons. When atoms are combined with
other atoms, they make !molecules!

![Carbon dioxide atoms and oxygen atoms](/images/glossary/atom1.jpg)

Some molecules, like !oxygen!, are made of single type of atom. Other molecules, such as !carbon dioxide!, are
made of multiple different types of atoms. The number and type of atoms determine what a substance is and what
it does.
`,
  },
  {
    uid: "airpollution",
    label: "Air Pollution",
    rtext: ["air pollution"],
    description: `
Air pollution happens when !gases!, and tiny materials known as !particles!, are introduced into the air. Air
pollution can mean that the air we breathe contains things that make it less healthy for us to breathe. These
things (which we call pollutants) include solid and liquid particles, as well as certain gases.

Air pollution is something we often cannot see with our eyes (we need specialist equipment), but just because
we can't see it doesn't mean it's not there. This is why our SAMHE monitors are so helpful. Our monitors help
us measure air pollution by collecting data on three air quality measures: !particulate matter!, !carbon dioxide!,
and !total volatile organic compounds!.

Air pollutants mostly come from things involving humans such as using different modes of transport (including
cars and lorries), factories and even from the furniture in our buildings and paint on our walls. Air
pollutants can also come from natural sources including !desert dust! and !mould spores!.
`,
  },
  {
    uid: "airquality",
    label: "Air Quality",
    rtext: ["air quality"],
    description: `
Air quality describes how polluted the air we breathe is. The less pollution there is in the air, the better
the air quality is. Your SAMHE monitor cannot measure all the pollutants that might be present in the air, but
it does provide a good indication of when any of the three SAMHE air quality measures are high within the air
that you might be breathing. Because the monitor can measure a combination of different things in the air at
the same time, it is really useful for learning about air quality.

Even though you can't see the things our monitors measure, you will probably be aware of poor air quality -
have you ever done a really long lesson with lots of people talking in the same room and felt that it's really
stuffy? If you didn't have the windows open, high levels of !re-breathed air!, likely meaning high levels
of !carbon dioxide! (which you can now measure), could be one of the culprits!

There have been a number of scientific studies suggesting links between !exposure! to high levels of CO₂ and
reduced ability to concentrate, as well as various health symptoms being linked to poor indoor air quality.
How and why higher CO₂ levels and poor indoor air quality have these effects is still being studied.
`,
  },
  {
    uid: "average",
    label: "Average",
    rtext: ["average", "mean", "median", "mode", "averages"],
    description: (
      <>
        <p>
          Finding the average is a way to represent a set of numbers. There are several types of average, the most
          common are mean, median, and mode.
        </p>
        <p>
          Mean: When people use the term average, they are often referring to the mean (or strictly speaking the
          arithmetic mean). The mean is calculated by adding up a series of numbers and then dividing by how many
          numbers there are in that series.
        </p>
        <p>
          Median: If all of the numbers in a series are placed in order from smallest to largest, the median is the
          middle number. The median separates the higher half from the lower half of the sample.
        </p>
        <p>Mode: The mode is the number that appears the most frequently in a series of numbers.</p>
        <p>Below is an example of finding the mean, median, and mode.</p>
        <p>
          Consider the series of numbers:
          <br />
          4, 1, 9, 10, 3, 4, 6, 2, 4, 8, 7, 3, 4<br />
          There are thirteen numbers in this series.
        </p>
        <p>
          Mean: To calculate the mean, we need to add all the numbers and divide by thirteen:
          <br />
          Mean = (4 + 1 + 9 + 10 + 3 + 4 + 6 + 2 + 4 + 8 + 7 + 3 + 4)/13 = 5
        </p>
        <p>
          Median: To calculate the median, we first need to place the numbers in order:
          <br />
          1, 2, 3, 3, 4, 4, 4, 4, 6, 7, 8, 9, 10
          <br />
          And then find the middle number:
          <br />
          1, 2, 3, 3, 4, 4, <b>4</b>, 4, 6, 7, 8, 9, 10. The middle number, and so the median, is 4
        </p>
        <p>Mode: The mode is 4 because this is the number that appears the most frequently.</p>
        <p>Remember, when the word 'average' is used a mean has been calculated.</p>
      </>
    ),
  },
  {
    uid: "carbon",
    label: "Carbon",
    rtext: ["carbon"],
    description: `
Carbon is a !chemical element! which can be found naturally in all plants and animals. Carbon is also an important
part of coal and oil. Carbon has many different forms including diamonds and graphite (which is used in
pencils), each with different properties.
`,
  },
  {
    uid: "carbondioxide",
    label: "Carbon Dioxide",
    rtext: ["Carbon Dioxide", "CO2", "CO₂", "CO\u2082"],
    description: `
!Carbon dioxide!(CO₂) is a !gas! found in the air around us. It is a !chemical compound! made up of one !atom! of !carbon!
with two !atoms! of !oxygen!. Its !chemical formula! is !CO₂!.

![A diagram representing the structure of a carbon dioxide molecule](/images/glossary/atomco2.jpg)

The most common source of CO₂ in classrooms is people. This is because we all breathe out CO₂. This makes
carbon dioxide a good way to indicate indoor air quality because higher levels of CO₂ implies that a room
contains lots of !re-breathed air! (that is air that's already been breathed out by someone else). Equally when
a room is well ventilated, we can expect that the CO₂ level will be lower because more air is coming in
from outside, replacing the re-breathed air.

Unit of measurement: ppm.

Ppm stands for parts per million and is a measure of the !concentration! of CO₂ in the air. You could also write
parts per million as a fraction which would look like 1/1,000,000.


This means you can read the measurement on your monitor as the number of molecules of CO₂ for every million
!molecules! of air in the room. For example, if your measurement reads 600 ppm, there would be 600 molecules of
carbon dioxide for every million molecules in the air.


The lowest carbon dioxide readings you would normally expect to see are around 400 ppm; classroom readings any
lower than this would be very unusual. This is because the background level of CO₂ within outdoor air is
normally between 400 ppm and 500 ppm (parts per million), with cities often having higher levels of outdoor
CO₂ than !rural areas!. You might have heard about CO₂ in the context of climate change, and that's because
background CO₂ concentrations have changed quite significantly since the industrial revolution as a result of
human activity. Changes in the CO₂ levels within outdoor air are continuing to take place all over the world.
For more information about background CO₂ levels, and how they have changed over time, you might want to look
at NASA's climate pages.

      `,
  },
  {
    uid: "carbon-monoxide",
    label: "Carbon monoxide",
    rtext: ["carbon monoxide", "co"],
    description: `
Carbon monoxide is a !chemical compound! made up of !carbon! and !oxygen! which has the !chemical formula! CO.
Carbon monoxide is a poisonous, colourless, tasteless, odourless, !gas!. Carbon monoxide is produced during !combustion!
of fuels due to there being too little oxygen present. Most combustion processes (natural or man-made) produce some carbon monoxide.`,
  },
  {
    uid: "centimetre",
    label: "Centimetre",
    rtext: ["centimetre", "centimetres"],
    description: (
      <>
        <p>
          A centimetre is a measure of length. A centimetre is one hundredth of a metre. As a fraction this looks like
          1/100 and as a decimal it looks like 0.01 of a metre. Centimetres are displayed as cm. Classroom rulers are
          often 30cm long.
        </p>
      </>
    ),
  },
  {
    uid: "census",
    label: "Census",
    rtext: ["census", "censuses"],
    description: (
      <>
        <p>
          A census is a complete count of all the people and households in a country. In England and Wales the census is
          carried out by the Office for National Statistics every 10 years.
        </p>
        <p>
          The census asks questions about you, your household and your home. In doing so, it helps to build a detailed
          snapshot of our society. Information from the census helps the government and local authorities to plan and
          fund local services, such as education, doctors' surgeries and roads.
        </p>
      </>
    ),
  },
  {
    uid: "chemical",
    label: "Chemical",
    rtext: ["chemical", "chemicals"],
    description: `
A chemical is any substance that has a constant chemical composition. That means that one type of chemical substance is
always made up of the same 'stuff'.  Some chemicals are naturally occuring, such as water, whereas other chemicals are
manufactured, such as chlorine (used for bleaching fabrics or in swimming pools). 'Chemicals' can refer to either
!Chemical Compounds! or !Chemical Elements!.
`,
  },
  {
    uid: "chemicalcompound",
    label: "Chemical Compound",
    rtext: ["chemical compound", "chemical compounds"],
    description: `Chemical compounds are composed of one or more !chemical elements!.`,
  },
  {
    uid: "chemicalelement",
    label: "Chemical Element",
    rtext: ["chemical element", "chemical elements"],
    description: `
Chemical elements are made up of one type of !atom! only.

The periodic table contains the names of all the elements that have been discovered. !Carbon! and !oxygen! are
examples of elements.
          `,
  },
  {
    uid: "chemicalformula",
    label: "Chemical Formula",
    rtext: ["chemical formula"],
    description: `
A chemical formula is a way of describing which !chemical elements! are in a !chemical compound! and what proportions they
are in. Every chemical element has a special symbol made up of one or two letters which scientists use in
chemical formulas instead of writing out the name in full.

Symbols for every element are found in the periodic table.
        `,
  },
  {
    uid: "clean-air-zone",
    label: "Clean Air Zone",
    rtext: ["clean air zone", "CAZ", "clean air zones"],
    description: (
      <>
        <p>
          A Clean Air Zone defines an area where targeted action is taken to improve air quality and resources are
          prioritised and coordinated in order to shape the urban environment in a way that delivers improved health
          benefits and supports economic growth.
        </p>
      </>
    ),
  },
  {
    uid: "cognitiveperformance",
    label: "Cognitive Performance",
    rtext: ["cognitive performance"],
    description: `
Cognitive performance is a way of describing how well you're able to carry out different skills which make use
of your brain. Examples of these skills include learning, thinking, and reasoning.
`,
  },
  {
    uid: "combustion",
    label: "Combustion",
    rtext: ["combustion"],
    description: (
      <p>
        Combustion, or burning, is a chemical reaction that produces heat and light (usually a flame). A common form of
        combustion is a fire which combines oxygen, heat and a fuel (for example wood, paper or coal).
      </p>
    ),
  },
  {
    uid: "concentration",
    label: "Concentration",
    rtext: ["concentration"],
    description: `
When we're talking about measurements of concentration like ppm (parts per million) or µg/m3 (micrograms per cubic metre) we mean how much of something is present in the air.

For example, if you first hand a glass of fruit squash which contains a large amount of squash concentrate has a larger fraction of lot squash concentrate, relative to water.
The opposite would be if you later had a very dilute glass of fruit squash. In this later case, for a glass of the same !volume!, you would have much less squash than you did before.

![diagrams explaining concentration](/images/glossary/Concentration_SAMHE_Diagram.png)
`,
  },
  {
    uid: "cubicmetre",
    label: "Cubic Metre",
    rtext: ["cubic metre"],
    description: `
Cubic metres are used to measure !volume!. One cubic metre is the volume of a cube (which is a solid shape with
six square faces) if it measured one metre in each side. Cubic metres are represented by the symbol !m³!.
      `,
  },
  {
    uid: "cm2",
    label: "cm²",
    rtext: ["cm²", "cm2"],
    description: `
A !centimetre! squared, or square centimetre is the !area! equal to a square which measures 1 centimetre on each side.
It is a unit of !area!. Square centimetres are used to measure areas.
`,
  },
  {
    uid: "cm3",
    label: "cm³",
    rtext: ["cm³"],
    description: `
A !centimetre! cubed, or cubic centimetre, is a cube (which is a solid shape with six square faces) which has height,
width and depth of 1 centimetre. Cubic centimetres are used to measure !volume!.
`,
  },
  {
    uid: "dataevent",
    label: "Data Event",
    rtext: ["data event", "data events"],
    // description: "PLACEHODLER",
    description: DataEventGuidance,
  },
  {
    uid: "dehumidifier",
    label: "Dehumidifier",
    rtext: ["dehumidifier"],
    description: `A dehumidifier is a device designed to remove excess moisture from the air.`,
  },
  {
    uid: "desertdust",
    label: "Desert Dust",
    rtext: ["desert dust"],
    description: (
      <>
        <p>
          Exactly how it sounds. When the wind blows strongly enough dust and sand from places like Africa's Sahara
          Desert can be whipped up high into the sky. This dust can be carried by the wind over very large distances
          including to the United Kingdom and even the Amazon Rainforest.
        </p>
        <p>
          This might sound unusual, but the Met Office states that Saharan dust events typically occur in the UK several
          times a year. If you're interested in learning more about desert dust and how it travels to the UK, you may
          like to explore the{" "}
          <AExternal href="https://www.metoffice.gov.uk/weather/learn-about/weather/types-of-weather/wind/saharan-dust">
            Met Office webpage defining desert dust
          </AExternal>
          .
        </p>
        <p>
          Desert dust is also transported in larger quantities than you might think, see this{" "}
          <AExternal href="https://www.nasa.gov/content/goddard/nasa-satellite-reveals-how-much-saharan-dust-feeds-amazon-s-plants">
            NASA news story for more information
          </AExternal>
          .
        </p>
      </>
    ),
  },
  {
    uid: "diameter",
    label: "Diameter",
    rtext: ["diameter"],
    description: (
      <>
        <p>
          A diameter is the distance of a line passing through the centre of a circle if it were to start one edge of
          the circle and end on another. We use diameter to refer to the size of particles based on the assumption that
          they are roughly round.
        </p>
        <p>
          <img src="/images/glossary/diametre.jpg" alt="circle with a line from the left to the right" />
        </p>
      </>
    ),
  },
  {
    uid: "diesel",
    label: "Diesel",
    rtext: ["diesel", "diesel fuel"],
    description: `Diesel is a mixture of !hydrocarbons! obtained by the distillation of crude oil (naturally occurring liquid found deep underground). Like !petrol!, diesel is often used to fuel cars.`,
  },
  {
    uid: "draught",
    label: "Draught",
    rtext: ["draught", "draughts"],
    description: `A current, or flow, of cool air within a room often felt most near open windows and doors. Draughts can be an important factor in our !thermal comfort!.`,
  },
  {
    uid: "electric-cars",
    label: "Electric cars",
    rtext: ["electric car", "electric cars", "electric vehicle", "electric vehicles", "EV", "EVs", "BEV", "BEVs"],
    description: `
Electric cars are powered by rechargeable batteries rather than fossil fuels such as !diesel! or !petrol!.
The batteries can be charged by plugging into a dedicated charging point and taking electricity from the national grid
(which is a network connecting all power stations in the UK and responsible for supplying electricity).
Electric cars store electricity in rechargeable batteries that power an electric motor, which turns the wheels.
`,
  },
  {
    uid: "exhaust-emissions",
    label: "Exhaust emissions",
    rtext: ["exhaust emission", "exhaust emissions"],
    description: `
Exhaust emissions are substances that come out of vehicle exhausts as a result of the !combustion! of fuels, often
through exhaust pipes, into the atmosphere. Exhaust emissions consist of atmospheric air, carbon dioxide (CO₂),
!water vapour!, and numerous !chemical! compounds either as !gases! or in !solid! form as tiny !particles!.
`,
  },
  {
    uid: "evaporate",
    label: "Evaporate",
    rtext: ["evaporate", "evaporates"],
    description: `When a substance evaporates it changes from a !liquid! to a !gas!.`,
  },
  {
    uid: "exposure",
    label: "Exposure",
    rtext: ["exposure"],
    description: (
      <>
        <p>
          When we use the word exposure, we're usually talking about how much pollution we experience in the air we
          breathe.
        </p>
        <p>
          When we have been exposed to something, the effects it has on us are usually determined by how much of it we
          were exposed to, the way in which we were exposed to it, and how long we were exposed to it for. Other
          important factors include the age of the person exposed and whether they have any health conditions.
        </p>
      </>
    ),
  },
  {
    uid: "fresh-air",
    label: "Fresh air",
    rtext: ["fresh air", "clean air"],
    description: `
Fresh air refers to air coming in from outside. Some people mistake 'fresh' with 'clean'. Neither of these are
scientific terms but clean air is generally considered to be that which has low levels of !Air Pollution!.
Whether the air outside is 'cleaner' than the air inside depends on lots of different things, including what
the sources of !Pollution! are in the area.`,
  },
  {
    uid: "gas",
    label: "Gas",
    rtext: ["gas", "gases"],
    description: (
      <p>
        A gas is often invisible and the parts that make up the gas can move around freely, filling the shape and size
        of its container. The air that we breathe is made up of different gases.
      </p>
    ),
  },
  {
    uid: "guidance-on-window-types",
    label: "Guidance on window types",
    rtext: ["guidance on window types"],
    description: (
      <>
        <GuidanceOnWindowTypes />
      </>
    ),
  },
  {
    uid: "hepa-filter",
    label: "HEPA Filter",
    rtext: ["hepa filter"],
    description: `
A HEPA filter is a type of air cleaning device which draws air through a very fine filter to remove !particulate matter! (PM) from the air.
`,
  },
  {
    uid: "humidity",
    label: "Humidity",
    rtext: ["humidity", "relative humidity"],
    description: `
Humidity is the amount of !water vapour! in the air. There are lots of ways of measuring humidity, but SAMHE is
interested in relative humidity. Relative humidity lets us know what the humidity is compared to the highest
amount of humidity there could be at any given !temperature! and !pressure!. Relative humidity is the most common
way of measuring humidity.

Unit of measurement: %

Relative humidity is shown as a percentage using the % symbol.

This is because relative humidity is a measure of how much water vapour there is in the air compared to how
much there could be at that temperature. If relative humidity was at 100% the air would be holding as much
water vapour as it possibly could; if it was holding any more than that water droplets would form, and it
might start raining indoors!
          `,
  },
  {
    uid: "hydrocarbons",
    label: "Hydrocarbons",
    rtext: ["hydrocarbon", "hydrocarbons"],
    description: `
Hydrocarbons are !chemical compounds! that contain the !chemical elements! carbon and hydrogen only.
They are compounds that are obtained from fossil fuel crude oil (naturally occurring liquid found
deep underground that can be refined to make fuels such as gasoline, petrol and diesel).
`,
  },
  {
    uid: "hybrid-cars",
    label: "Hybrid cars",
    rtext: ["hybrid cars", "hybrid car"],
    description: `A hybrid car is a car that uses 2 or more types of fuel source, usually !petrol! or !diesel! and electricity.`,
  },
  {
    uid: "hypothesis",
    label: "Hypothesis",
    rtext: ["hypothesis", "Hypotheses"],
    description: (
      <p>
        A hypothesis is a possible explanation for an observed effect which has not yet been proven. A good hypothesis
        is based on strong scientific evidence. To hypothesise is to suggest a hypothesis. Scientists hypothesise a lot
        and run experiments to confirm whether their ideas were correct.
      </p>
    ),
  },
  {
    uid: "indicator",
    label: "Indicator",
    rtext: ["indicator"],
    description: `
An indicator is a sign that shows or describes something else. Indicators can be useful to know what is
happening or if something is changing. For example, the temperature in a room is only an indicator of how warm
people will feel inside because !thermal comfort! is subjective.
`,
  },
  {
    uid: "liquid",
    label: "Liquid",
    rtext: ["liquid", "liquids"],
    description: (
      <p>
        A liquid can flow which means that the parts that make up the liquid can slide past each other to make the
        liquid take the shape of their container, but cannot move as freely as in a gas.
      </p>
    ),
  },
  {
    uid: "mass",
    label: "Mass",
    rtext: ["mass"],
    description: `Mass is a measure of the amount of !matter! in an object or a particle. This usually measured in kilograms, kg.`,
  },
  {
    uid: "matter",
    label: "Matter",
    rtext: ["matter"],
    description: <p>Matter is anything which takes up space and can be weighed.</p>,
  },
  {
    uid: "maximum",
    label: "Maximum",
    rtext: ["maximum", "max", "maximums"],
    description: (
      <>
        <p>
          The biggest value in a dataset. To find the maximum value on a graph you look for the highest value recorded
          on the y (vertical) axis.
        </p>
        <img src="/images/glossary/Maximum_SAMHE_Diagram.png" alt="graph showing maximum value" />
      </>
    ),
  },
  {
    uid: "mechanicalventillation",
    label: "Mechanical Ventilation",
    rtext: ["mechanical ventilation"],
    description: `
Mechanical ventilation is when fresh air is supplied to a room using mechanical devices such as pumps or fans.
This is in contrast to !natural ventilation!, which is where outdoor air flows naturally through windows or other
openings. You can normally identify mechanical ventilation where there are vents in the walls or ceiling where
air is forced into the room (note that a passive air vent in an exterior wall is an example of natural
ventilation). There may also be visible ventilation ducting, which is another sign of mechanical ventilation.
`,
  },
  {
    uid: "metric",
    label: "Metric",
    rtext: ["metric"],
    description: (
      <>
        <p>
          Metrics are a way of measuring a value in context. When we use the word metric in the SAMHE Web App, we're
          usually talking about numbers that describe the indoor environment which help us to learn more about our
          classroom air quality or how comfortable the room might feel.
        </p>
      </>
    ),
  },
  {
    uid: "metre",
    label: "Metre",
    rtext: ["metre", "metres"],
    description: `
A metre is a measure of length used in the metric system. Metres are displayed as m. A metre is equal to 100
!centimetres!. You might have a metre stick in your classroom. Metres are often used to measure items of
furniture, people or buildings.
`,
  },
  {
    uid: "micrometre",
    label: "Micrometre",
    rtext: ["micrometre", "micrometres"],
    description: `Micrometres are also called microns. See !micron!.`,
  },
  {
    uid: "microgram",
    label: "Microgram",
    rtext: ["microgram", "micrograms"],
    description: (
      <>
        <p>A microgram is a measure of weight. One microgram is one millionth of a gram.</p>
        <p>
          To give you an idea of just how little that is, a typical plastic pen cap or paper clip each weigh around 1
          gram. If you imagine cutting them into a million equal parts each part would weigh one microgram.
        </p>
      </>
    ),
  },
  {
    uid: "micron",
    label: "Micron",
    rtext: ["micron", "microns"],
    description: (
      <p>
        A micron is a measure of length. One micrometre is one millionth of a metre. As a fraction this looks like
        1/1,000,000 as a decimal this looks like 0.000001. Microns are displayed as μm.
      </p>
    ),
  },
  {
    uid: "microscopic",
    label: "Microscopic",
    rtext: ["microscopic"],
    description: (
      <p>
        Something so small that it can only be seen by a microscope (which is a special piece of scientific equipment
        used to help us view very tiny things). Microscopes use special lenses to do this. Have you ever used a
        magnifying glass? It's a bit like that but a lot stronger.
      </p>
    ),
  },
  {
    uid: "minimum",
    label: "Minimum",
    rtext: ["minimum", "min"],
    description: (
      <>
        <p>
          The smallest value in a dataset. To find the minimum value on a graph you look for the lowest y value
          recorded.
        </p>
        <img src="/images/glossary/Minimum_SAMHE_Diagram.png" alt="graph showing minimum value" />
      </>
    ),
  },
  {
    uid: "molecule",
    label: "Molecule",
    rtext: ["molecule", "molecules"],
    description: `
A molecule is a group of two or more !atoms! bound together. Molecules can include groupings of the same atom whereas !chemical compounds! are groups of at least two different types of atom.

![CO2 and oxygen molecules(with explanation)](/images/glossary/co2-and-oxygen-molecules-with-explanation-samhe-diagram.png)
`,
  },
  {
    uid: "m2",
    label: "m²",
    rtext: ["m²"],
    description: `A !metre! squared, or square metre, is the !area! equal to a square which measures 1 metre on each side. Square metres are used to measure areas.`,
  },
  {
    uid: "m3",
    label: "m³",
    rtext: ["m³"],
    description: `
A !metre! cubed, or cubic metre, is a cube (which is a solid shape with six square faces) which has height, width and depth of 1 metre.
Cubic metres are used to measure volume. Cubic metres are represented by the symbol m³.
`,
  },
  {
    uid: "mouldspores",
    label: "Mould Spores",
    rtext: ["mould spores"],
    description: (
      <>
        <p>
          Moulds are a form of fungus which are very small. They usually grow best in warm, moist conditions with access
          to organic matter such as food. Moulds reproduce using spores which is how they spread. You've probably seen
          mould before even if you're not sure what it is. Here is a picture:
        </p>
        <img src="/images/glossary/mouldylemon.jpg" alt="It's a mouldy lemon" />
      </>
    ),
  },
  {
    uid: "nanometre",
    label: "Nanometre",
    rtext: ["nanometre", "nanometres"],
    description: (
      <p>
        A nanometer is a measure of length. One nanometre is one billionth of a metre. One billion is one with 9 zeros
        after it or 1,000,000,000 . As a fraction this looks like 1/1,000,000,000. It's not practical to write that many
        zeros all the time so scientists usually write the symbol for nanometres instead (nm) or 10<sup>-9</sup>.
      </p>
    ),
  },
  {
    uid: "naturalventilation",
    label: "Natural Ventilation",
    rtext: ["natural ventilation"],
    description: `
Natural ventilation is when air flows into a space from the outside without the use of mechanical
assistance - see !mechanical ventilation!. Natural ventilation usually takes place through windows, or possibly
doors to outside. In natural ventilation, the wind, or temperature differences between inside and outside drive
the flow of outdoor air in, and stale air out.
`,
  },
  {
    uid: "nitrogen-dioxide",
    label: "Nitrogen Dioxide (NO₂)",
    rtext: ["nitrogen dioxide", "NO₂", "NO2"],
    description: `
Nitrogen dioxide is a !chemical compound! which has the !chemical formula! NO₂.
Nitrogen dioxide is one of a group of highly reactive gases known as !oxides of nitrogen! or nitrogen oxides (NOₓ).
Other nitrogen oxides include nitrous acid and nitric acid. NO₂ primarily gets in the air from the burning of fuel.
`,
  },
  {
    uid: "nitric-oxide",
    label: "Nitric Oxide (NO)",
    rtext: ["nitric oxide", "NO"],
    description: `
Nitric oxide is a !chemical compound! which has the !chemical formula! NO.
Nitric oxide is a colourless !toxic! gas that is formed by the oxidation of nitrogen.
`,
  },
  {
    uid: "oxides-of-nitrogen",
    label: "Oxides of nitrogen (NOₓ)",
    rtext: ["NOₓ", "nox", "oxides of nitrogen", "nitrogen oxides"],
    description: `The term NOₓ refers to all nitrogen oxides, commonly !nitrogen dioxide! (NO₂) and !nitric oxide! (NO).`,
  },
  {
    uid: "operative-temperature",
    label: "Operative Temperature",
    rtext: ["operative temperature"],
    description: `
Operative temperature is one of the important factors for our !thermal comfort!. It depends on the !air temperature! and the mean radiant temperature.

Mean radiant temperature accounts for the transfer of heat energy between a person and their surroundings.
Think about a cold, but sunny day, although the air temperature is cold, you would feel a warming affect from being in the sun.
`,
  },
  {
    uid: "outlier",
    label: "Outlier", // TODO: superscript
    rtext: ["outlier"],
    description: (
      <>
        <p>
          An outlier is a data point which appears very different from the other values within a data set. Outliers are
          extremes and do not fit with the general pattern of the data.
        </p>
        <p>
          It is worth investigating outliers to see if you can identify a reason why the value is so different.
          Sometimes outliers can be the result of errors in measurement or recording, in which case you could
          justifiably exclude them from your analysis. When you have outliers they can affect other calculations you
          might want to perform using your data - the range will be much larger and outliers can also have a big effect
          on the mean.
        </p>
        <p>Line graphs and box plots are good ways of visualising outliers. </p>
      </>
    ),
  },
  {
    uid: "oxygen",
    label: "Oxygen",
    rtext: ["oxygen"],
    description: `
Oxygen is a naturally occurring !chemical element!, its chemical symbol is O. Oxygen atoms bond in pairs to form
the molecule O₂ (this is oxygen gas, also called diatomic oxygen or dioxygen). This is the most
common form of oxygen. Oxygen forms part of many !chemical compounds! including water (H₂0) and carbon
dioxide (CO₂).
`,
  },
  {
    uid: "particle",
    label: "Particle",
    rtext: ["particle", "particles"],
    description: `
A particle is a very small bit of !matter!. Particles are so small that they don't take up much room at all and
they don't weigh very much either.
`,
  },
  {
    uid: "particlematter",
    label: "Particulate Matter",
    rtext: ["particulate matter", "PM₂.₅", "PM2.5", "PM"],
    description: `
Particulate matter includes everything in the air that is not a !gas!. Particulate matter includes things which
cannot be seen by the human eye - !microscopic! material. Take a look at the image below to learn about just how
small particulate matter can be.

![A grain of sand is shown next to a human hair. The grain of sand has a slightly larger diameter than the hair. Blue spheres around the hair represent dust particles.](/images/glossary/hairparticle.jpg)

The particles of most interest to air quality scientists and health researchers are those that are small
enough to be breathed in.

Particulate matter is classified by size. There are two main size bands which are PM₁₀ which
includes any particles less than 10 microns in !diameter! and PM₂.₅ which includes any particles less
than 2.5 microns in !diameter! and bigger than 400 !nanometres!. The SAMHE monitors measure PM₂.₅.

Particulate matter can come from naturally occurring sources such as !pollen! and !desert dust!, as well as from
human activities including cooking and driving. From these examples you can see that particulate matter comes
from sources both outdoors and indoors. Particulate matter from outdoors can also travel indoors, so it is not
a simple thing to analyse.

Unit of measurement: µg/m³

µg/m³ is a measure of concentration which means the number of !micrograms! per cubic metre.

Let's break this down a bit. The µg part stands for micrograms and m³ means metres cubed. You can read the /
symbol as "for every". If your monitor reading was 1 µg/m³ that would mean there is one microgram of pollutant
for every cubic metre of air.
`,
  },
  {
    uid: "petrol",
    label: "Petrol",
    rtext: ["petrol"],
    description: `
Petrol is a mixture of !hydrocarbons! obtained by the distillation of crude oil
(naturally occurring liquid found deep underground). Like !diesel!, petrol is often used to fuel cars.
`,
  },
  {
    uid: "percentage",
    label: "Percentage",
    rtext: ["percentage"],
    description: (
      <>
        <p>
          A number expressed as a fraction out of 100 where 100 is used to represent the whole. Each percentage point
          (1%) represents 1/100. Percentages use the % sign, and you can read percentages as "out of 100". For example,
          7% is 7 out of 100, 86% is 86 out of 100.
        </p>
      </>
    ),
  },
  {
    uid: "pollen",
    label: "Pollen",
    rtext: ["pollen"],
    description: (
      <>
        <p>
          Powder produced by the male part of a flower as part of a plant's reproductive process. It's a tiny particle
          which can make us sneeze!
        </p>
      </>
    ),
  },
  {
    uid: "pollution",
    label: "Pollution",
    rtext: ["pollution"],
    description: (
      <>
        <p>
          Pollution is the introduction of harmful materials called pollutants into the environment. Pollutants can be
          natural, such as volcanic ash and they can also be created by human activity, such as rubbish, fumes from
          traffic, or waste from factories. Pollutants damage the quality of air, water, and land.
        </p>
      </>
    ),
  },
  {
    uid: "population-density",
    label: "Population density",
    rtext: ["population density"],
    description: (
      <>
        <p>
          Population density is a measurement of the number of people in an area. Population density is calculated by
          dividing the number of people in an area by the size of the area. Population density is usually shown as the
          number of people per square kilometre (km<sup>2</sup>).
        </p>
        <p>The more people per square kilometre, the more crowded a given area is.</p>
      </>
    ),
  },
  {
    uid: "pressure",
    label: "Pressure",
    rtext: ["pressure"],
    description: `
Pressure is the amount of force exerted per unit area. Inside !liquids! and !gases!, like air, pressure is
everywhere - so you can measure the pressure at every single point in the air; pressure can change for many reasons
(including with air temperature, its speed, how much air is sitting above it, etc.) and, because it is related to
forces, differences in pressure can cause liquids and gases to move. It is all these reasons, and more,
that pressure is important; for example, if you listen to the weather forecasts, meteorologist even use
ideas like 'regions of low and high pressure' in the atmosphere to help explain our weather.
`,
  },
  {
    uid: "range",
    label: "Range",
    rtext: ["range"],
    description: `
The range of data is the difference between the lowest number (minimum) and the highest number (maximum) in
the dataset. To work out the range you subtract the !minimum! value from the !maximum! value in your dataset.

Imagine that you recorded the !temperature! five times a day in your classroom (you can do this with your SAMHE
monitor). You record the following temperatures:

19°C, 20°C, 20°C, 18°C, 19°C

The maximum in this dataset is 20°C and the minimum is 18°C. By taking 18°C away from 20°C we find the range,
which is 2°C.


When you look at a line graph you can see the range of the data very clearly - datasets with a small range
will have lots of points with a similar y value, the line might look quite flat compared to a dataset with a
bigger range.

![graph showing data range](/images/glossary/Data_Variation_SAMHE_Diagram.png)
`,
  },
  {
    uid: "rebreathedair",
    label: "Re-breathed air",
    rtext: ["Re-breathed air"],
    description: `
Air that someone else has already breathed out. We often breathe in re-breathed air and this is not harmful in
itself. It's what is in the re-breathed air that can be of concern. For example, re-breathed air can contain
exhaled virus particles. This is why !CO₂! monitors were introduced in schools to help assess COVID risk.
`,
  },
  {
    uid: "respiratory-aerosols",
    label: "Respiratory Aerosols",
    rtext: ["respiratory aerosols", "Respiratory aerosols"],
    description: `Respiratory aerosols are small water droplets that are released as people breathe out.
These droplets are so small that they can be carried on the air, which makes them an aerosol.
These droplets are important as they can carry viruses or bacteria over much greater distances
than larger water droplets.
`,
  },
  {
    uid: "rural",
    label: "Rural Areas",
    rtext: ["rural", "rural area", "rural areas"],
    description: (
      <>
        <p>
          In rural areas people and buildings are much more spread out than in towns and cities. Rural areas are often
          linked with farming.
        </p>
      </>
    ),
  },
  {
    uid: "solid",
    label: "Solid",
    rtext: ["solid"],
    description: (
      <>
        <p>
          A solid has a fixed shape and volume. A solid will not move to fill a container. Examples of solids in your
          classroom include objects like desks and chairs.
        </p>
      </>
    ),
  },
  {
    uid: "specific-heat-capacity",
    label: "Specific Heat Capacity",
    rtext: ["specific heat capacity"],
    description: `Specific heat capacity is defined as the amount of heat energy required to change a substance's !temperature! per unit !mass!, its units are J/(kg K) or J/(kg °C).`,
  },
  {
    uid: "temperature",
    label: "Temperature",
    rtext: ["temperature"],
    description: `Temperature is a measure of how warm or cold the air is. The SAMHE monitors measure !air temperature!.
Temperature is usually measured using a thermometer.

The SAMHE team are interested in measuring air temperature because it helps us to understand how comfortable the room feels to students and staff.
Looking at temperature in combination with the other things we measure can help you to make decisions about when to ventilate your classroom and
how to balance !ventilation! requirements with making sure your classroom is comfortably warm to work in.

Unit of measurement: °C

The temperature on your SAMHE monitor is measured in degrees Celsius, with the symbol °C. The Celsius scale is defined by 0°C for
the melting point of ice, and 100°C for the boiling point of water. In many contexts scientists use an absolute measure of the temperature,
called Kelvin (with the symbol K). A one degree change in Celsius is also a one degree change in Kelvin but 0K is the coldest matter can ever be,
that's absolute zero temperature. Absolute zero is the equivalent to -273.15°C.
`,
  },
  {
    uid: "thermal-comfort",
    label: "Thermal Comfort",
    rtext: ["thermal comfort"],
    description: `
Thermal comfort describes how we experience our thermal environment in terms of how comfortable we feel with it. Because of this, it is very 'subjective' -
which means we can all feel different in the same environment. Thermal comfort is often described in terms of: personal factors (e.g., our activity level and clothing),
how we expect a space to feel and where we have been before, and the environmental conditions such as !operative temperature!, !humidity!, and the speed of the air, or !draughts!,
within the space. SAMHE might use a survey in the future to explore other factors, but we are mainly focused on using the SAMHE monitors to measure what we can of the environmental conditions -
in our case that's !air temperature!, which affects !operative temperature!, and !relative humidity!.
`,
  },
  {
    uid: "tvocs",
    label: "TVOC",
    rtext: ["tvoc", "tvocs", "total volatile organic compounds", "voc", "vocs", "Volatile Organic Compounds"],
    description: `
A volatile organic compound, or VOC, is a substance that !evaporates! easily at room !temperature!. Lots of commonly used
substances evaporate at room temperature, which means we often come across VOCs in the air we breathe. Many things you can smell are  VOCs!

Thinking about VOCs as things you can smell can help you to identify things that produce VOCs. For example, cleaning products,
air fresheners, deodorant, and paint all produce VOCs. Because there are so many different types of VOCs, SAMHE monitors
detect the total volatile organic compounds, or TVOCs. TVOCs readings are a measure of almost all the different VOCs that are likely to be in the air.

Unit of measurement: Ind30

We report the SAMHE monitors; measurements of TVOC via a relative index, 'Ind30'. There are thousands of different VOCs,
and the monitors have different sensitivities to each particular !chemical compound!; this makes it impossible to
accurately report an absolute concentration of total VOCs, so instead we use an index. Having this relative indication of
when VOCs might be high, or low, might be helpful, or interesting, to you; so, we report a relative Index of the levels being detected by
the 'Sensirion SGP30 TVOC sensor' inside your SAMHE monitor - hence the units of 'Ind30'.
`,
  },
  {
    uid: "toxic",
    label: "Toxic",
    rtext: ["toxic"],
    description: (
      <>
        <p>
          If something is toxic then it has the potential to negatively affect living things, including people. How much
          a toxic substance negatively affects those things is dependent on the amount of the substance they have been
          exposed to, so not all exposures to toxic substances will be harmful to human health.
        </p>
      </>
    ),
  },
  {
    uid: "unit",
    label: "Unit",
    rtext: ["unit", "units"],
    description: `
A unit is anything you can say that there is one of. You can have one book, one window, one !centimetre! and one
!metre!. These are all definite quantities of something and describing them in this way helps other people
understand what we mean.

Standard units of measurement are those which are always the same because there is an agreed way of measuring
them. If two people each measure a centimetre they'd be talking about the same thing. If on the other hand two
people each measured a book they might end up with very different measurements - which is why no-one uses
books to measure things (at least if they want an accurate result!).
`,
  },
  {
    uid: "ventilation",
    label: "Ventilation",
    rtext: ["ventilation", "ventilated"],
    description: `
Ventilation is the name for the process of refreshing indoor air by allowing air to flow into a building or room while letting, potentially stale, air out.
Most classrooms in the UK use !natural ventilation! but some have !mechanical ventilation! or a mixture of the two. A key way of increasing natural ventilation is to open windows,
but it is important to look at your SAMHE monitor to see if !particulate matter! or other readings rise with opened windows, as outdoor air can contain different !pollution!.
`,
  },
  {
    uid: "volume",
    label: "Volume",
    rtext: ["volume"],
    description: `Volume is how much space an object or a substance takes up.

Volume can be a measure of how loud something is, but usually that's not what we're talking about in the SAMHE Web App.
    `,
  },
  {
    uid: "watervapour",
    label: "Water Vapour",
    rtext: ["Water Vapour"],
    description: (
      <>
        <p>
          Water vapour is water that has evaporated into a gas and is generally invisible to the human eye unless
          condensed like steam from a kettle or your breath on a cold day (in these circumstances what you can see is
          liquid water droplets forming as water vapour condenses).
        </p>
      </>
    ),
  },
  {
    uid: "xaxis",
    label: "X Axis",
    rtext: ["x axis", "x axes"],
    description: "The x axis is the horizontal axis on a graph. It runs from left to right.",
  },
  {
    uid: "yaxis",
    label: "Y Axis",
    rtext: ["y axis", "y axes"],
    description: "The y axis is the vertical axis on a graph. It runs from top to bottom.",
  },
  {
    uid: "legend",
    label: "Legend",
    rtext: ["legend"],
    description: "A legend is a key to a graph that labels data using different colours, styles, or symbols.",
  },
  {
    uid: "series",
    label: "Series",
    rtext: ["series"],
    description: `A graph can contain multiple data sources each of which is known as a series.
For example a graph could plot both temperature and CO₂. This would be a graph with 2 series.
Each series is often represented by a different colour or line style. A !legend! is then used to match the
line colour and style to the data source.
`,
  },
  // Additional popup info
  {
    uid: "about_air_pollution",
    label: "About air pollution",
    rtext: ["About air pollution", "About air pollution page"],
    description: AboutAirPollutioncontent,
  },
  {
    uid: "pm_and_tvoc_guidance",
    label: "PM and TVOC guidance",
    rtext: [
      "VOC Guidance",
      "TVOC Guidance document",
      "TVOC reference text",
      "PM guidance",
      "PM2.5 reference text",
      "PM₂.₅ reference text",
    ],
    description: <TvocsAndPmsInfo />,
  },
  {
    uid: "vocSources",
    label: "TVOC Sources",
    rtext: ["list of TVOCs sources", "list of TVOC sources", "list of VOCs sources", "list of VOC sources"],
    description: VOCGuidanceDataDetectives,
  },
  {
    uid: "pm_sources",
    label: "PM sources",
    rtext: ["list of PM sources"],
    description: PmGuidanceDataDetectives,
  },
  {
    uid: "guidance_on_monitor_readings",
    label: "Guidance on monitor readings",
    rtext: ["guidance on monitor readings", "monitor readings page"],
    description: MonitorLEDCombinationContent,
  },
  {
    uid: "guidanceOnInterpretingMonitorReadings",
    label: "Guidance on interpreting monitor readings",
    rtext: ["guidance on interpreting monitor readings", "Monitor readings"],
    description: MonitorLedsContent,
  },
  {
    uid: "co2_reference_text",
    label: "CO₂ reference text",
    rtext: ["CO₂ reference text", "CO₂ guidance"],
    description: <MonitorLedsCo2 />,
  },
  {
    uid: "guidanceOnMonitorPlacement",
    label: "Guidance on monitor placement",
    rtext: ["guidance on monitor placement", "Where to put your monitor"],
    description: MonitorPlacementGuideContent,
  },
  {
    uid: "ventilationGuidance",
    label: "Guidance on Ventilation",
    rtext: ["ventilation guidance"],
    description: <VentilationGuidance />,
  },
  {
    uid: "temperature_and_relative_humidity",
    label: "Reference text for temperature and relative humidity",
    rtext: [
      "Reference text for relative humidity",
      "reference text on relative humidity",
      "temperature reference text",
    ],
    description: <TemperatureHumidityInfo />,
  },
  {
    uid: "guidance_on_air_cleaning_devices",
    label: "Guidance on air cleaning devices",
    rtext: ["guidance on air cleaning devices"],
    description: AirCleaningDevicesContent,
  },
  {
    uid: "csvFile",
    label: "CSV Files",
    rtext: ["csv file", "CSV Files", ".CSV Files"],
    description: (
      <>
        <p>
          The data from your SAMHE monitor is downloaded as a CSV or Comma Separated Value file. It is a common way of
          displaying data, with rows of data represented by new lines, and columns represented by commas. CSV files can
          be used by software such as Microsoft Excel, Google Sheets and OpenOffice, opening it in these packages will
          allow you to see the data in rows and columns .
        </p>
        <p>
          Clicking on the 'Download data as a CSV file' will save the file to your computer. You can open this up using
          Excel or OpenOffice, or if you don't have either of these on your computer, and you have a Google account, you
          can use Google Sheets.
        </p>
        <p>
          The first row in the spreadsheet is the column heading, for example Date, Time, and the metric being measured.
          Underneath this is all the data.
        </p>
        <p>
          <AExternal href="https://www.bbc.co.uk/bitesize/guides/zdydmp3/revision/1">BBC Bitesize</AExternal> have a
          useful guide on how spreadsheets work and how to use them to analyse data, including how to make your own
          graphs.
        </p>
      </>
    ),
  },
];
