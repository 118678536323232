import styled from "styled-components";
import { BETA_VERSION, DEV_MODE } from "../../config";
import { useAppSelector } from "../../Redux/hooks";

export const DebugBarStyle = styled.div`
  border-radius: 2rem;
  opacity: 0.3;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: ${({ theme }) => theme.shape.defaultPadding};
  z-index: 1000;
  background: white;
  pointer-events: none;

  &:hover, &:focus {
    color: white;
    opacity: 1;
    text-decoration: none;
    max-width: 20rem;
    max-height: 2rem;
    padding 0 1rem;
  }
  `;

export const DebugBar = () => {
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const currentRoomMonitor = useAppSelector((state) => state.userState.state.monitor?.data?.room);
  const dissallowMoveYourMonitor = useAppSelector(
    (state) => state.userState.state.school?.data?.disallowMoveYourMonitor
  );
  const hasMultipleMonitors = useAppSelector((state) => state.userState.state.school?.data?.monitors?.length > 1);

  if (!BETA_VERSION && !DEV_MODE) return <></>;

  return (
    <DebugBarStyle>
      <p>Room: {currentRoom?.label}</p>
      <p>Monitor: {monitorId}</p>
      <p>Current Room Monitor: {currentRoomMonitor?.label}</p>
      <p>Static School: {dissallowMoveYourMonitor ? "true" : "false"}</p>
      <p>Has Multiple Monitors: {hasMultipleMonitors ? "true" : "false"}</p>
    </DebugBarStyle>
  );
};
