import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/Forms/ContactForm";
import { ConstrainedPageStyle, PageTitle, Section, PLong, SectionHeadingTop } from "../styles/page";
import { BoxoutContactStyle } from "../styles/hero";

export const ContactPageContent: React.FC = () => {
  return (
    <Section>
      <PageTitle id="contact">Get In Touch!</PageTitle>
      <PLong>
        If you want to stay in touch by signing up for our newsletter, please complete the form below. (If you have a
        specific query about data, collaboration or media, please visit our <Link to="/get-involved">Get Involved</Link> page to find out who to
        contact)
      </PLong>
      <PLong>
        <b>By signing up for the newsletter, you'll be alerted as soon as our Teacher Resource Pack is available!</b>{" "}
        This pack will be free to download and will contain versions of many of the activities and resources currently
        only available to registered SAMHE schools via the Web App. It is being designed for use by schools which don't
        have a SAMHE monitor.
      </PLong>
      <PLong>
        If you are under 13 then please ask your parent, carer or your teacher to submit this form on your behalf. If
        you are over 13 then you can send us a message yourself.
      </PLong>
      <BoxoutContactStyle>
        <PLong style={{ margin: "1rem 0.5rem" }}>
          If you are experiencing technical issues with your monitor, please visit our{" "}
          <Link to="/support">Help Centre</Link> or use our{" "}
          <Link to="/support/tech-contact">Technical Queries Form</Link>.
        </PLong>
      </BoxoutContactStyle>
      <div id={"enquiries"} style={{ textAlign: "center", marginTop: "4rem" }}>
        <SectionHeadingTop>Contact Form</SectionHeadingTop>
      </div>
      <br></br>
      <ContactForm />
    </Section>
  );
};

const ContactPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <ContactPageContent />
    </ConstrainedPageStyle>
  );
};

export default ContactPage;
