import styled, { css } from "styled-components";
import { ButtonNoFormat } from "./button";
import { CTALinkButtonStyle } from "./links";

export const headerHeight = (mobile) => (mobile ? "95px" : "3rem");

export const mobileScreenCutOff = "500px";
export const tabletScreenCutOff = "1320px";

export const HeaderStyle = styled.div`
  background-color: ${(props) => props.theme.background};
  display: flex;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: ${(props) => props.theme.colors.text};
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.colors.main};
  flex-direction: row;
  height: ${headerHeight(true)};
  padding: 5px 0.7rem;
  box-shadow: 1px 1px 21px 1px rgba(0, 0, 0, 0.19);

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    height: ${headerHeight(false)};
    align-items: center;
  }

  .userDropDownBtn_wrap {
    position: relative;
    width: 4rem;
    order: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .userDropDownIcon {
      height: ${headerHeight(true)};
      width: ${headerHeight(true)};
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    @media only screen and (min-width: ${tabletScreenCutOff}) {
      position: relative;
      .userDropDownIcon {
        height: ${headerHeight(false)};
        width: ${headerHeight(false)};
      }
    }
  }
`;

export const LogoWrap = styled.div`
  height: 100%;
`;

export const Logo = styled.div`
  height: 100%;
  padding: 3px;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  text-align: left;
  border-radius: 4rem 0;
  padding-left: 0.5rem;
  display: flex;
  border: 0.3rem solid transparent;
  &:hover {
    border: 0.3rem solid ${(props) => props.theme.colors.main};
  }
  a {
    outline: none !important;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    @media only screen and (min-width: ${tabletScreenCutOff}) {
      justify-content: flex-start;
    }
  }
  order: 2;
  @media only screen and (min-width: ${tabletScreenCutOff}) {
    order: 1;
  }
`;

export const WebAppLogo = styled(Logo)`
  border: 0.2rem solid transparent;
  border-radius: 1rem 0;
  &:hover {
    border: 0.2rem solid ${(props) => props.theme.colors.main};
  }
`;

export const WebAppFooterLogo = styled(Logo)`
  border: none;
  height: 100%;
  &:hover {
    border: none;
  }
  /** Safari */
  /* Fix stretched image issue */
  @media not all and (min-resolution: 0.001dpcm) {
    div
      height: 30px; width: 100px;
    }
  }
`;

export interface ILinksListMenuProps {
  isOpen: boolean;
}

export const LinksListMenu = styled.div<ILinksListMenuProps>`
  position: absolute;
  left: 0;
  top: ${headerHeight(true)};
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.background};
  padding: 1rem;
  padding-top: 2rem;
  z-index: 99999999;
  flex-direction: column;
  align-items: flex-end;
  order: 1;

  ${({ isOpen }) =>
    isOpen
      ? `
      display: flex;
      `
      : `
      display: none;
  `}

  @media only screen and (max-height: 760px) {
    overflow-y: auto;
  }

  @media only screen and (min-width: ${mobileScreenCutOff}) {
    padding: 3rem;
    padding-top: 4rem;
  }

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    position: relative;
    display: flex;
    margin-right: 0.5rem;
    flex-direction: row;
    justify-content: flex-end;
    z-index: auto;
    padding: 0;
    order: 2;
    top: 0;
    overflow-y: visible;
  }
`;

export const LinksList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    position: relative;
    margin-right: 0.5rem;
    flex-direction: row;
    justify-content: flex-end;
    z-index: auto;
    padding: 0;
  }
`;

export const LinksListItem = styled.li`
  padding: 0;
  display: flex;
  margin: 0;
  width: 100%;
  text-align: center;

  margin-bottom: 2rem;
  height: 2rem;
  align-content: center;
  justify-items: center;
  align-items: center;
  background: ${(props) => props.theme.colors.main};

  border: 1px solid ${(props) => props.theme.colors.main};

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    border: none;
    margin-bottom: 0;
    width: auto;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
export const HeaderTextCss = css`
  text-align: left;
  color: ${({ theme }) => theme.colors.ctaHover};
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.fontSize5};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;

export const HeaderLabel = styled.label`
  ${HeaderTextCss}
  border-radius: 1rem 0 0 1rem;
  padding: 0.2rem 0.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid white;

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    padding: 0 0.5rem;
  }
`;

export const HeaderLabelValue = styled.div`
  ${HeaderTextCss}
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;

  border-radius: 0 1rem 1rem 0;
  height: 2rem;
  border: 1px solid white;
  color: white;
`;

export const HeaderDropdown = styled.select`
  ${HeaderTextCss}
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  width: fit-content;
  padding: 0 0.5rem;

  border-radius: 0 1rem 1rem 0;
  height: 2rem;

  border: 1px solid white;
  color: ${({ theme }) => theme.colors.ctaHover};
  flex-grow: 1;
  background: white;

  &:focus,
  &:hover {
    border: 1px solid white;
  }
`;

export const HeaderItem = styled.li`
  // Mobile and above size screens
  padding: 0;
  display: flex;
  margin: 0;
  text-align: center;
  width: 100%;
  border-radius: 0.5rem 0;
  flex-wrap: wrap;

  margin-bottom: 2rem;
  height: 3rem;
  align-content: center;
  justify-items: center;
  align-items: center;

  label {
    flex-grow: 1;
  }

  @media only screen and (min-width: ${mobileScreenCutOff}) {
    // Tablet and above size screens
    max-width: 50rem;
    flex-wrap: nowrap;
  }

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    // Full size screens
    background: none;
    margin: 0 auto;
    border: none;
    height: 2.3rem;
    margin-bottom: 0;
    width: auto;
    margin-right: 1rem;
    max-width: 50rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const HeaderLink = styled(CTALinkButtonStyle)`
  ${HeaderTextCss}
  color: ${({ theme }) => theme.colors.ctaHover};
  background: ${({ theme }) => theme.background};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
  border: 1px solid ${(props) => props.theme.colors.main};
  border-radius: 0.5rem 0;
  width: 100%;
  height: 100%;

  &.active {
    color: ${({ theme }) => theme.background};
    background: #b52bd8;
    border-radius: 0.5rem 0;
  }
  &:hover {
    border: 0.1rem solid ${({ theme }) => theme.background};
    color: white;
  }
  &:focus {
    border: 0.1rem solid ${({ theme }) => theme.background};
    background: ${({ theme }) => theme.colors.ctaHover};
  }

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    background: none;
    border: 1px solid transparent;
    color: white;
    width: auto;
  }
`;

export const HamburgerMenuStyle = styled(ButtonNoFormat)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.background};

  &:focus,
  &:focus-visible {
    color: ${({ theme }) => theme.background};
  }

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    display: none;
  }
`;

export const AppHeaderStyle = styled(HeaderStyle)`
  background: ${({ theme }) => theme.colors.appBackground};
  box-shadow: none;
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  height: 6rem;
  border: none;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  border-bottom: 2px solid white;

  @media only screen and (max-width: ${tabletScreenCutOff}) {
    width: 100%;
  }
`;

export const AppHeaderMainSectionStyle = styled.header`
  display: block;
`;

export const ReturnToSAMHELink = styled(LinksListItem)`
  background: none;
  outline: none;
  border: none;
  margin-bottom: 0;
  width: auto;
  a {
    background: none;
    color: white;
    border: none;
    height: auto;

    &.active,
    &:hover,
    &:focus {
      background: ${({ theme }) => theme.colors.ctaHover};
      border: 0.1rem solid ${({ theme }) => theme.background};
    }
  }
`;

export const AppLinksListMenu = styled(LinksListMenu)`
  background: none;

  @media only screen and (max-width: ${tabletScreenCutOff}) {
    background: ${({ theme }) => theme.colors.appBackground};
  }

  @media only screen and (max-width: ${tabletScreenCutOff}) {
    background: ${({ theme }) => theme.colors.appBackground};
  }
`;

export const NavBar = styled.div`
  background-color: ${(props) => props.theme.background};
  padding: 0.2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.main};
  padding-right: 0;
  box-shadow: 1px 1px 21px 1px rgba(0, 0, 0, 0.19);

  a,
  button {
    font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  }

  .logo {
    height: 60px;
  }

  button {
    color: black;
  }

  .dropbtn {
    display: block;
  }
  .droplink {
    display: none;
  }

  @media only screen and (min-width: ${tabletScreenCutOff}) {
    .logo {
      height: 6rem;
    }
    align-items: center;
    padding-right: 17px; //compensate the scroll bar
    .dropbtn {
      display: none;
    }
    .droplink {
      display: block;
    }
  }

  .navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    @media only screen and (min-width: ${tabletScreenCutOff}) {
      width: 980px;
    }

    .menu-wrapper {
      z-index: 11;

      .button-wrapper {
        display: flex;
        gap: 1rem;
        justify-content: end;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        a {
          padding: 0.5rem 1rem;
        }
      }
      .menu {
        position: relative;
        a {
          float: left;
          color: black;
          text-align: center;
          padding: 1rem;
          text-decoration: none;
          font-weight: normal;
        }

        .dropdown {
          float: left;
          overflow: hidden;
        }

        .dropdown .dropbtn {
          font-size: 16px;
          border: none;
          outline: none;
          padding: 1rem;
          background-color: inherit;
          margin: 0;
        }

        & > :hover,
        .dropdown:hover,
        :focus .dropbtn {
          background-color: #ddd;
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 200px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
          z-index: 1;
          top: 54px;
        }

        .dropdown-content a {
          float: none;
          padding: 0.5rem 1rem;
          display: block;
          text-align: left;
        }

        .dropdown-content a:hover {
          background-color: #ddd;
        }

        .dropdown:focus,
        .dropdown:focus-within,
        .dropdown:hover {
          .dropdown-content {
            display: block;
          }
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }

        .icon {
          display: none;
        }
      }
    }

    @media only screen and (max-width: ${tabletScreenCutOff}) {
      .menu-wrapper {
        padding-right: 0.5rem;
        .menu {
          padding-top: 2rem;

          a,
          .dropdown .dropbtn {
            display: none;
          }
          button.icon {
            float: right;
            display: block;
            top: 3px;
            right: 3px;
            position: absolute;
          }
          .dropdown {
            display: none;
          }
        }
      }

      .menu-wrapper.active {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: white;

        .menu {
          a:not(.droplink) {
            float: none;
            display: block;
            text-align: left;
          }
          .dropdown {
            float: none;
            display: block;
          }
          .dropdown-content {
            position: relative;
            top: 0;
          }
          .dropdown .dropbtn {
            display: block;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
`;
