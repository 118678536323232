import styled from "styled-components";
import { ConstrainedPageStyle, P, PageTitle, Section, SectionSubHeading, Ul } from "../styles/page";
import { AExternalButtonStyle, CTALinkButtonStyle } from "../styles/links";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";

const GetinvolvedWrapper = styled(Section)`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
`;

const GetinvolvedDiv = styled.div`
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  padding: 1rem;
  position: relative;

  & > ${SectionSubHeading} {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  & > ${CTALinkButtonStyle}, & > ${AExternalButtonStyle} {
    margin-top: 2rem;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    padding: 0.3rem 1rem;
    display: inline-block;
    text-align: center;
  }
`;

const SchoolWithMonitorDiv = styled(GetinvolvedDiv)`
  background-color: #d3dafe;

  & > ${CTALinkButtonStyle} {
    background-color: #4c5ebc;

    &:focus {
      outline: #28367d solid 3px;
      outline-offset: -4px;
      border-color: ${({ theme }) => theme.colors.grey1};
    }
    &:hover {
      background: #28367d;
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

const SchoolWithoutMonitorDiv = styled(GetinvolvedDiv)`
  background-color: #d4a4ee;
`;

const ResearcherDiv = styled(GetinvolvedDiv)`
  background-color: #a5e6ef;

  & > ${AExternalButtonStyle} {
    background-color: #0091a5;

    &:focus {
      outline: #014852 solid 3px;
      outline-offset: -4px;
      border-color: ${({ theme }) => theme.colors.grey1};
    }
    &:hover {
      background: #014852;
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

const FunderMediaDiv = styled(SchoolWithMonitorDiv)``;

const GetinvolvedPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <Section>
        <PageTitle>Get Involved</PageTitle>
        <P>
          Although our initial funding came to an end on 31 July 2024, there are still plenty of ways for schools and
          others to get involved. See below!
        </P>
      </Section>

      <GetinvolvedWrapper>
        <SchoolWithMonitorDiv>
          <SectionSubHeading>Schools with a SAMHE monitor</SectionSubHeading>
          <P>
            Keep using the Web App to explore your data, do Activities to further your understanding of air quality and
            download data for further analysis.
          </P>
          <P>Depending on where you've got up to, you may find the following helpful:</P>
          <Ul>
            <li>
              Not set up your monitor yet? Follow our step-by-step{" "}
              <Link to="/getting-started">guide to getting started</Link>.
            </li>
            <li>
              Just been handed a SAMHE monitor and not sure what to do with it? Find out{" "}
              <Link to="/faq?audience=staff#ive_just_been_handed_a_monitor_what_do_i_do_with_it">how to use the monitor</Link>.
            </li>
            <li>
              Handing over the SAMHE monitor to a colleague? Use this{" "}
              <Link to="/faq?audience=staff#im_moving_schoolchanging_roles_what_do_i_do_with_my_monitor">
                handover checklist
              </Link>{" "}
              to make sure you give them all the necessary information.
            </li>
          </Ul>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/app">Explore the Web App</CTALinkButtonStyle>
        </SchoolWithMonitorDiv>
        <SchoolWithoutMonitorDiv>
          <SectionSubHeading>Schools without a SAMHE monitor</SectionSubHeading>
          <P>
            COMING SOON - a free Teacher Resource Pack to allow you to access versions of the SAMHE activities which
            don't require a monitor. It will include experiments, example data, explanatory information and ventilation
            guidance.
          </P>
          <P>Sign up to receive the newsletter to hear when it is available.</P>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/faq#contact">Sign up for the SAMHE newsletter</CTALinkButtonStyle>
        </SchoolWithoutMonitorDiv>
      </GetinvolvedWrapper>

      <GetinvolvedWrapper>
        <ResearcherDiv>
          <SectionSubHeading>Researchers</SectionSubHeading>
          <P>
            If you are doing related research and want to collaborate or use the SAMHE data, please get in touch with
            the PI, Dr Henry Burridge.
          </P>
          <br></br>
          <br></br>
          <AExternalButtonStyle href="https://profiles.imperial.ac.uk/h.burridge">
            Contact Henry Burridge
          </AExternalButtonStyle>
        </ResearcherDiv>
        <FunderMediaDiv>
          <SectionSubHeading>Funders & Media</SectionSubHeading>
          <P>
            If you love what we have done on SAMHE and want to give us funding to reach more schools, or write a story
            about this work, please get in touch!
          </P>
          <P>
            We also have copy and images on our <Link to="/resources/media">media page</Link>.
          </P>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/faq#contact">Get in touch</CTALinkButtonStyle>
        </FunderMediaDiv>
      </GetinvolvedWrapper>

      <Section>
        <P>
          We will continue to produce a SAMHE newsletter, so if you would like to hear about new ideas for using your
          monitor, inspirational stories from other schools, further research findings, and developments such as funding
          to continue this work, please use the contact form to <Link to="/faq#contact">sign up for our newsletter</Link>.
        </P>
        <P>
          Whether your school has a monitor or not, we'd love to hear how you are using SAMHE so that we can celebrate
          your work and share it with others to inspire them! If you're happy to share your story, please use our{" "}
          <AExternal href="https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform">
            newsletter contributions form
          </AExternal>{" "}
          to do so.
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};
export default GetinvolvedPage;
