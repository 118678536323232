import React from "react";
import { useInterval } from "react-use";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import {
  CardInfoSectionWrapper,
  CardStyle,
  CardTitle,
  CardTitleBar,
} from "../../../../GenericComponents/CardBoard/styles";
import { CardExpandedHr, DataViewButton, ErrorMessage } from "../../../../styles/app";
import { DataMetricsMetaData } from "../../../../lib/DataViews/EDataMetrics";
import { Tags } from "../../../Tags/Tags";
import { ISimpleScatterCardProps, ISimpleScatterCollapsedCardProps, ISimpleScatterExpandedCardProps } from "./lib";
import { useDataLoader } from "../../dataLoader";
import { DataViewCsvDownload } from "../../DataViewComponents";
import { TextFormatter } from "../../../TextFormatter/TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { SimpleScatter } from "../../../DataViz/SimpleScatter/SimpleScatter";
import { defaultDateRangeState, refreshTimes } from "../../../DateRangePicker/utils";
import { SimpleScatterOptions } from "./SimpleScatterOptions";
import { Br, FlexBox } from "../../../../styles/singlePageStyles";
import { EDayRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { ExpandedCardLoader } from "../../CardLoader";
import { ExpandedPanelContainer, ExpandedPanelHeaderStyle, ExpandedPanelInnerStyle } from "../../../../styles/popups";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { GraphCaptionBox } from "../../DataViewComponents/GraphCaptionBox";
import { ButtonStyle } from "../../../../styles/button";
import { GraphGuidanceBox } from "../../DataViewComponents/GraphGuidanceBox";
import { PatternInfoBox } from "../../DataViewComponents/PatternInfoBox";

const simpleScatterDefaultDateRange = (): IDateRangeState =>
  refreshTimes({
    ...defaultDateRangeState(EDayRange.TODAY),
    aggregation: EAggregationInterval.FIVE_MINUTE,
    maxAggregation: EAggregationInterval.HOUR,
    minAggregation: EAggregationInterval.FIVE_MINUTE,
  });

export const SimpleScatterCardInner = ({
  id,
  monitorId,
  label,
  additionalProps: { metrics, liveUpdate = true },
}: ISimpleScatterCollapsedCardProps) => {
  const { label: metricXLabel, unit: unitX, strid: stridX } = DataMetricsMetaData[metrics[0]] || {};
  const { label: metricYLabel, unit: unitY, strid: stridY } = DataMetricsMetaData[metrics[1]] || {};
  if (!metricXLabel) throw Error(`Invalid Metric: ${metrics[0]}`);
  if (!metricYLabel) throw Error(`Invalid Metric: ${metrics[1]}`);
  const [dateRange, setDateRange] = React.useState(defaultDateRangeState());
  const xLabel = `${metricXLabel} (${unitX})`;
  const yLabel = `${metricYLabel} (${unitY})`;

  const {
    loadedData: loadedXData,
    dataLoadError: dataLoadErrorX,
    readyToView: readyToViewX,
  } = useDataLoader({
    monitorId,
    currentMetric: metrics[0],
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });

  const {
    loadedData: loadedYData,
    dataLoadError: dataLoadErrorY,
    readyToView: readyToViewY,
  } = useDataLoader({
    monitorId,
    currentMetric: metrics[1],
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <div className="inner">
      <CardTitleBar>
        <CardTitle>
          <DataViewButton to={`/app/data/${id}`}>{String(label)}</DataViewButton>
        </CardTitle>
      </CardTitleBar>
      <p>
        Scatter plot of: {metricXLabel} vs {metricYLabel}{" "}
      </p>
      {readyToViewX && readyToViewY && !dataLoadErrorX && !dataLoadErrorY && (
        <SimpleScatter
          dataX={loadedXData?.data}
          dataY={loadedYData?.data}
          xLabel={xLabel}
          yLabel={yLabel}
          metricX={stridX}
          metricY={stridY}
          compact
        />
      )}
      {dataLoadErrorX && <ErrorMessage>Error loading data</ErrorMessage>}
      {dataLoadErrorY && <ErrorMessage>Error loading data</ErrorMessage>}
    </div>
  );
};

export const SimpleScatterExpanded = ({
  id,
  label,
  monitorId,
  description,
  longDescription,
  tags,
  additionalProps: { metrics, graphCaption, patternText, graphGuidance, equalAspectRatio },
  onLoadedData,
  dateRange,
  setDateRange,
}: ISimpleScatterExpandedCardProps) => {
  if (!metrics || metrics.length < 2) throw Error("Not enough metrics provided!");
  const [currentXMetric, setCurrentXMetric] = React.useState(metrics[0]);
  const [currentYMetric, setCurrentYMetric] = React.useState(metrics[1]);
  const [startAxisAtZero, setStartAxisAtZero] = React.useState(false);
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const [showLongDesc, setShowLongDesc] = React.useState(true);

  const {
    loadedData: loadedXData,
    loading: loadingX,
    dataLoadError: dataLoadErrorX,
    readyToView: readyToViewX,
  } = useDataLoader({
    monitorId,
    currentMetric: currentXMetric,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
    onLoadedData: onLoadedData,
  });

  const {
    loadedData: loadedYData,
    loading: loadingY,
    dataLoadError: dataLoadErrorY,
    readyToView: readyToViewY,
  } = useDataLoader({
    monitorId,
    currentMetric: currentYMetric,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );
  const { label: metricXLabel, unit: unitX, strid: stridX } = DataMetricsMetaData[currentXMetric] || {};
  const { label: metricYLabel, unit: unitY, strid: stridY } = DataMetricsMetaData[currentYMetric] || {};

  const metricOptions = metrics.map((m) => DataMetricsMetaData[m]).filter((m) => m);
  const xLabel = `${metricXLabel} (${unitX})`;
  const yLabel = `${metricYLabel} (${unitY})`;

  return (
    <div className="simplePlotExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <CardExpandedHr />
      <TextFormatter text={String(description)} keywordMap={keywordMap} />
      {longDescription ? (
        <>
          <CardInfoSectionWrapper open={showLongDesc} maxHeight="70rem" style={{ width: "100%" }} transitionTime={0.2}>
            <CollapsableSectionInner open={showLongDesc}>
              <FlexBox horiz flexwrap style={{ gap: "2rem" }}>
                <div data-testid="long-description" style={{ flex: "1 1 30rem" }}>
                  <TextFormatter text={longDescription} keywordMap={keywordMap} />
                </div>
                {graphCaption && <GraphCaptionBox text={graphCaption} />}
              </FlexBox>
            </CollapsableSectionInner>
          </CardInfoSectionWrapper>
          <ButtonStyle style={{ width: "100%", marginTop: "1rem" }} onClick={() => setShowLongDesc((prev) => !prev)}>
            {showLongDesc ? "Hide Description" : "Show Description"}
          </ButtonStyle>
        </>
      ) : (
        ""
      )}
      <Br />
      {tags ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tags tags={tags} />
        </div>
      ) : (
        ""
      )}
      {loadingX || loadingY ? "Loading Data..." : ""}
      <Br />
      {dataLoadErrorX ? (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadErrorX && dataLoadErrorX.message}</ErrorMessage>
        </FlexBox>
      ) : (
        ""
      )}
      {dataLoadErrorY ? (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadErrorY && dataLoadErrorY.message}</ErrorMessage>
        </FlexBox>
      ) : (
        ""
      )}
      {readyToViewX && readyToViewY ? (
        <div style={{ width: "100%" }}>
          <SimpleScatter
            dataX={loadedXData?.data}
            dataY={loadedYData?.data}
            yLabel={yLabel}
            xLabel={xLabel}
            metricX={stridX}
            metricY={stridY}
            showLegend={dateRange.aggregation !== EAggregationInterval.DAY}
            startAxisAtZero={startAxisAtZero}
            equalAspectRatio={equalAspectRatio}
          />
        </div>
      ) : (
        ""
      )}
      <Br />
      <Br />
      <SimpleScatterOptions
        id={id}
        reloadData={() => setDateRange(refreshTimes(dateRange))}
        loading={loadingX || loadingY}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setLiveUpdate={setLiveUpdate}
        liveUpdate={liveUpdate}
        metricOptions={metricOptions}
        currentXMetric={currentXMetric}
        setCurrentXMetric={setCurrentXMetric}
        currentYMetric={currentYMetric}
        setCurrentYMetric={setCurrentYMetric}
        startAxisAtZero={startAxisAtZero}
        setStartAxisAtZero={setStartAxisAtZero}
      />
      <Br />
      {graphGuidance ? <GraphGuidanceBox text={graphGuidance} /> : ""}
      {patternText ? <PatternInfoBox type={patternText} /> : ""}
    </div>
  );
};

export const SimpleScatterCard = (props: ISimpleScatterCardProps) => (
  <CardStyle>
    <SimpleScatterCardInner {...props} />
  </CardStyle>
);

export const SimpleScatterCardExpanded = (props: ISimpleScatterCardProps) => {
  const { uniqueCardId } = props;

  const [loadedData, setLoadedData] = React.useState<any>(null);
  // Callback function to receive data from the child component
  const handleLoadedData = (data: any) => {
    setLoadedData(data);
  };
  const [dateRange, setDateRange] = React.useState<IDateRangeState>(simpleScatterDefaultDateRange());

  return (
    <>
      <ExpandedPanelContainer data-testid={`${uniqueCardId}_expanded-panel`}>
        <ExpandedPanelHeaderStyle style={{ backgroundColor: "#303b71", justifyContent: "flex-end" }}>
          {loadedData && <DataViewCsvDownload data={loadedData} />}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle>
          <ExpandedCardLoader childProps={props}>
            {(propsFull: ISimpleScatterExpandedCardProps) => (
              <SimpleScatterExpanded
                {...propsFull}
                onLoadedData={handleLoadedData}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}
          </ExpandedCardLoader>
        </ExpandedPanelInnerStyle>
      </ExpandedPanelContainer>
    </>
  );
};
