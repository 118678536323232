import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { entries } from "../components/Blog/entries";
import { NewsEntry } from "../components/Blog/entry";
import { newsletterEntries } from "../components/Blog/newsletterEntries";
import {
  NewsletterHeading,
  // NewslettersList,
  NewsPageWrap,
  NewsStyle,
  NewsTickerStyle,
  TwitterFeedDesktopWrap,
  TwitterFeedMobileWrap,
  TwitterFeedWrap,
} from "../components/Blog/styles";
import { AExternal } from "../components/Links";
import { TWITTER_ID } from "../config";
import { CTALinkButtonStyle } from "../styles/links";
import {
  HeroHomeBackground,
  HeroButtonLink,
  HeroButtonWrap,
  HeroInnerStyle,
  HeroLogo,
  HeroStyle,
} from "../styles/hero";
import PageStyle, {
  CenteredTextContainer,
  HomeCenteredTextContainer,
  ConstrainedPageStyle,
  FlexFlipWrap,
  FlexDiv,
  Section,
  SectionHeading,
  SectionHeadingTop,
  SectionHeadingQuickLink,
  SnapRightImgHome,
  P,
  SubSectionHeading,
} from "../styles/page";
import ReactPlayer from "react-player";

export const HomePageHeader = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 3.37rem;
  padding: 0 2rem;
  margin: 2.5rem 0;
  text-align: center;
  font-weight: bold;
`;

export const HomeHeader = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 3.37rem;
  font-weight: bold;
`;

export const HomeBanner = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 3.37rem;
  font-weight: bold;
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 2rem;
  }
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;

export const HomeTextDiv = styled.div`
  ul li {
    margin-left: 1rem;
  }
`;

export const QuickLinksDiv = styled.div`
  background-color: #0097a9;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  a {
    color: ${({ theme }) => theme.colors.cta};
    background-color: white;
    white-space: nowrap;
    padding: 0.5rem 1.5rem;
    margin-right: 2rem;
    @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
      margin-bottom: 0.7rem;
    }
  }
`;

export const HomeLinkDiv = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  img {
    width: 350px;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    img {
      width: 300px;
    }
  }

  @media (max-width: 850px) {
    img {
      width: 200px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    position: relative;
    text-align: right;
  }
`;

export const HomeHeroStyle = styled(HeroStyle)`
  @media (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    height: 50rem;
  }
  border-radius: 0;
`;

const HomePage: React.FC = () => {
  return (
    <PageStyle>
      <HomeHeroStyle>
        <HeroHomeBackground src="/images/SAMHE_splash_01_1080.jpg" />
        <HeroInnerStyle>
          <div>
            <HeroLogo>
              <img src="/images/logo/samhe_logo_strap.png" alt="SAMHE" height="100%" />
            </HeroLogo>
          </div>
          <HomeCenteredTextContainer>
            <HomeBanner>We're on a mission to understand indoor air quality in UK schools</HomeBanner>
          </HomeCenteredTextContainer>
          <HeroButtonWrap>
            <HeroButtonLink to="/get-involved">Get Involved</HeroButtonLink>
            <HeroButtonLink to="/app">Web App</HeroButtonLink>
          </HeroButtonWrap>
          <HomeLinkDiv>
            <Link to="/getting-started">
              <img src="/images/getting_started.png" alt="Getting started" />
            </Link>
          </HomeLinkDiv>
        </HeroInnerStyle>
      </HomeHeroStyle>
      <ConstrainedPageStyle>
        <Section>
          <FlexFlipWrap>
            <CenteredTextContainer>
              <HomeTextDiv>
                <SectionHeading>Get involved in SAMHE</SectionHeading>
                <P>
                  SAMHE is transitioning. We're still working with UK schools to better understand classroom air quality
                  but are no longer recruiting new schools to host monitors. We will soon release a Teacher Resource
                  Pack to enable schools without a monitor to access SAMHE activities. Sign up to the SAMHE newsletter
                  via our <Link to="/contact">contact form</Link> to be the first to hear when this is available, and
                  for other news.
                </P>
                <CTALinkButtonStyle style={{ marginLeft: 0, padding: "0.5rem 1.5rem" }} to="/about">
                  Find out more
                </CTALinkButtonStyle>
              </HomeTextDiv>
            </CenteredTextContainer>
            <CenteredTextContainer>
              <SnapRightImgHome src="/images/SAMHE_monitor_cropped.jpg" alt="Samhe Monitor on table" />
            </CenteredTextContainer>
          </FlexFlipWrap>
        </Section>
        <Section>
          <QuickLinksDiv>
            <SectionHeadingQuickLink>Quicklinks</SectionHeadingQuickLink>
            <FlexDiv>
              <CTALinkButtonStyle to="/get-involved">Getting involved</CTALinkButtonStyle>
              <CTALinkButtonStyle to="/resources/monitor-leds">Guidance on monitor readings</CTALinkButtonStyle>
              <CTALinkButtonStyle to="/resources/ventilation">Ventilation tips</CTALinkButtonStyle>
            </FlexDiv>
          </QuickLinksDiv>
        </Section>
        <Section>
        <FlexFlipWrap reverse>
            <CenteredTextContainer>
              <ReactPlayer url="https://youtu.be/5Qv3J-Thgdo" controls={true} width="100%" title="Introducing SAMHE" />
            </CenteredTextContainer>
            <CenteredTextContainer>
              <HomeTextDiv>
                <SubSectionHeading>What has the SAMHE initiative achieved so far?</SubSectionHeading>
                <P>
                  Watch the team present key achievements and some of our findings on air quality in UK schools from our
                  original funded period (April 2022 - July 2024). This webinar was given in July 2024.
                </P>
                <P>
                  We held a separate,{" "}
                  <AExternal href="https://www.youtube.com/watch?v=EgenIlp6w3g">schools-facing webinar</AExternal> to
                  share our findings with teachers and pupils at SAMHE schools.
                </P>
              </HomeTextDiv>
            </CenteredTextContainer>
          </FlexFlipWrap>
        </Section>
        <Section>
          <div style={{ textAlign: "center" }}>
            <SectionHeadingTop>News and Updates</SectionHeadingTop>
          </div>
          <NewsPageWrap style={{ padding: 0, marginTop: "2rem" }}>
            <NewsStyle>
              <NewsletterHeading>
                <Link to="/news">NEWS</Link>
              </NewsletterHeading>
              <br></br>
              <NewsTickerStyle style={{}}>
                {entries.slice(0, 3).map((entry) => (
                  <NewsEntry key={entry.title} {...entry} />
                ))}
              </NewsTickerStyle>
            </NewsStyle>
            {false && (
              <TwitterFeedWrap>
                <NewsletterHeading>
                  <AExternal href="https://twitter.com/samheproject">X (Twitter) Feed</AExternal>
                </NewsletterHeading>
                <br></br>
                <TwitterFeedDesktopWrap>
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName={TWITTER_ID}
                    autoHeight // Does not work on mobile!
                    noHeader
                    noBorders
                    transparent
                  />
                </TwitterFeedDesktopWrap>
                <TwitterFeedMobileWrap>
                  <AExternal href="https://twitter.com/samheproject">SAMHE X (twitter) page</AExternal>
                </TwitterFeedMobileWrap>
              </TwitterFeedWrap>
            )}
            <NewsStyle>
              <NewsletterHeading>
                <Link to="/news">NEWSLETTERS</Link>
              </NewsletterHeading>
              <br></br>
              <NewsTickerStyle>
                {newsletterEntries.slice(0, 3).map((entry) => (
                  <NewsEntry key={entry.title} {...entry} />
                ))}
              </NewsTickerStyle>
            </NewsStyle>
          </NewsPageWrap>
        </Section>
      </ConstrainedPageStyle>
    </PageStyle>
  );
};

export default HomePage;
