import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { AUTH_URL_LOGOUT, AUTH_URL_REGISTER, VERSION } from "../config";
import { useAppSelector } from "../Redux/hooks";
import { ESessionStatus } from "../Redux/User/userReducer";

import {
  SignInPanel,
  SignInScreen,
  SignInPanelRhsPanel,
  SignInBox,
  SignInBoxes,
  SamheBigLogo,
  SamheBigLogoShadow,
  SignInPanelTitle,
  SignInPanelWrap,
  SignInButton,
  RegisterButton,
  HelpPanel,
  FullAuthPanelWrap,
  HelpButtonToggleButton,
} from "../styles/auth";


export const AuthPage = () => {
  const { sessionState, error } = useAppSelector((state) => state.userState);
  const { login, loginInProgress } = React.useContext(AuthContext);
  const [showHelp, setShowHelp] = React.useState(false);

  const [searchParams] = useSearchParams();
  const registered = React.useMemo(() => searchParams.get("registered"), [searchParams]);

  return (
    <SignInScreen>
      <FullAuthPanelWrap>
        <div style={{ position: "relative", height: "fit-content" }}>
          <SignInPanelWrap>
            <SignInPanel data-testid="signInPanel">
              <SignInPanelTitle>Welcome to the SAMHE Web App!</SignInPanelTitle>
              <p>A platform for learning about air quality in your school</p>
              <div style={{ minHeight: "1rem" }} />
              {loginInProgress && (
                <p>
                  <b>Logging in...</b>
                </p>
              )}
              {error && <p>{String(error)}</p>}
              {sessionState === ESessionStatus.GETTING_TOKEN && <div>Navigating to login screen</div>}
              {(sessionState === ESessionStatus.RECEIVED_TOKEN ||
                sessionState === ESessionStatus.GETTING_USER_META_DATA) && (
                <p>Received access token and getting user data.</p>
              )}
              {registered && sessionState === ESessionStatus.LOGGED_OUT && (
                <p>You successfully registered! You will shortly be redirected to the login screen.</p>
              )}
              {!registered && !loginInProgress && sessionState === ESessionStatus.LOGGED_OUT && (
                <>
                  <SignInBoxes>
                    <SignInBox>
                      <SignInButton onClick={() => login()}>Sign In</SignInButton>
                    </SignInBox>
                  </SignInBoxes>
                  <p>Or create a new account...</p>
                  {/* We have to use external link for registration */}
                  <SignInBoxes>
                    <SignInBox>
                      <RegisterButton href={AUTH_URL_REGISTER}>Register a New User</RegisterButton>
                    </SignInBox>
                  </SignInBoxes>
                </>
              )}
              {sessionState !== ESessionStatus.LOGGED_IN && (
                <>
                  <div style={{ minHeight: "1rem" }} />

                  <div style={{ height: "1rem" }} />
                  <p>Not yet signed up as a SAMHE school?</p>
                  <p>
                    <Link to="/register/school">Request Your Air Quality Monitor to get started.</Link>
                  </p>
                  <div style={{ height: "1rem" }} />
                  <Link to="/">
                    <FontAwesomeIcon size="xl" icon={faCircleLeft} /> Return to SAMHE.org.uk
                  </Link>
                </>
              )}
              <p style={{ marginTop: "1rem" }}>App Version: {VERSION}</p>
              {sessionState === ESessionStatus.LOGGED_IN && (
                <a style={{ fontWeight: "400" }} href={AUTH_URL_LOGOUT}>
                  Log out
                </a>
              )}
            </SignInPanel>
          </SignInPanelWrap>
          {sessionState !== ESessionStatus.LOGGED_IN && (
            <HelpPanel open={showHelp}>
              <HelpButtonToggleButton onClick={() => setShowHelp((prev) => !prev)} open={showHelp}>
                {showHelp ? "x" : " I need help setting up my monitor"}
              </HelpButtonToggleButton>
              <div>
                <b>To connect your monitor to your school's WiFi please follow our</b>{" "}
                <Link to="/monitor-connection-guide">Guide to monitor connection.</Link>
              </div>
              <div style={{ height: "1rem" }} />
              <p>
                <b>Getting stuck?</b>
              </p>
              <p>
                Please take a look at the additional guidance on the <Link to="/support">SAMHE Help Centre</Link>
              </p>
              <div>
                If you've worked through this and you're still having issues please get in touch via our{" "}
                <Link to="/support/tech-contact">Technical Query Form</Link>.
              </div>
            </HelpPanel>
          )}
        </div>
      </FullAuthPanelWrap>
      <SignInPanelRhsPanel className="rhslogo">
        <SamheBigLogo src="/images/logo/samhe_logo_robot.png" alt="SAMHE" />
        <SamheBigLogoShadow />
      </SignInPanelRhsPanel>
    </SignInScreen>
  );
};
