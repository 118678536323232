import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppWrapperInnerStyle, AppPageStyle } from "../../styles/app";
import ResourcesPage from "./resources";
import ActivitiesPage from "./activities";
import DataViewsPage from "./dataViews";
import AchievementsPage from "./achievements";
import { ActivityPage } from "./activity";
import { DataViewPage } from "./dataView";
import TeacherNotesPage from "./teacherNotes";
import CompletionCodesPage from "./completionCodes";
import AuthWrapper from "../../auth/AuthWrapper";
import { UserGroup } from "../../lib/User/UserGroup";

export const AppInnerRoutes = () => {
  return (
    <AppWrapperInnerStyle className="subpage_wrap" data-testid="samheAppInner">
      <Routes>
        <Route path="/data" element={<DataViewsPage />} />
        <Route path="/data/:dataViewId" element={<DataViewPage />} />
        <Route path="/activities" element={<ActivitiesPage />} />
        <Route path="/activities/:activityId" element={<ActivityPage />} />
        <Route path="/library" element={<ResourcesPage />} />
        <Route
          path="/library/extra_teacher_notes"
          element={
            <AuthWrapper
              allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.STUDENT, UserGroup.TEACHER]}
            >
              <TeacherNotesPage />
            </AuthWrapper>
          }
        />
        <Route
          path="/library/activity_completion_codes"
          element={
            <AuthWrapper
              allowedUserGroups={[UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.STUDENT, UserGroup.TEACHER]}
            >
              <CompletionCodesPage />
            </AuthWrapper>
          }
        />
        <Route path="/achievements" element={<AchievementsPage />} />
        <Route
          path="/be_a_detective"
          element={
            <AppPageStyle data-testid="samheAppContent">
              <div>Come back soon for our exciting be a data detective activity!</div>
            </AppPageStyle>
          }
        />
        <Route
          path="*"
          element={
            <AppPageStyle data-testid="samheAppContent">
              <div>Page not found!</div>
            </AppPageStyle>
          }
        />
      </Routes>
    </AppWrapperInnerStyle>
  );
};
