import { Link } from "react-router-dom";
import { Section, P, Ul, SubSectionHeading } from "../../styles/page";
import { AExternal } from "../Links";
import { IFaqItem } from "../faq/lib";
import { FaqItemWrapStyle } from "../../styles/faq";
import { FaqItem } from "../faq";

const entries: IFaqItem[] = [
  {
    uid: "how_to_setup_accounts",
    label: "How to set up accounts for other users at your school",
    group: null,
    audience: [],
    content: (
      <>
        <P>
        Pupils and staff at your school can have their own Web App accounts. For step-by-step instructions on how to set
        these up, see our FAQs:{" "}
        <Link to="/support#how_can_i_set_up_staff_accounts">How can I set up teacher accounts?</Link> and{" "}
        <Link to="/support#how_can_i_set_up_pupil_accounts">How can I set up pupil accounts?</Link>.
      </P>

        <b>What are the benefits of using individual accounts?</b>
        <Ul>
          <li>
            Benefits for pupils: with individual accounts pupils can explore the monitor data themselves, do activities
            individually and track their achievements. It also allows you to set SAMHE activities as homework.
          </li>
          <li>
            Benefits for staff: with a ‘teacher account’ of their own, staff members get full access to Web App
            activities and monitor data. This could be helpful for planning lessons or understanding where action needs
            to be taken to improve air quality. Admin and teacher users can also access additional resources in the Web
            App library, such as certificates and posters, that are not available to pupils.
          </li>
        </Ul>
      </>
    ),
  },
  {
    uid: "unlocking_activities",
    label: "Unlocking activities for pupils",
    group: null,
    audience: [],
    content: (
      <>
        <P style={{ display: "flex", marginTop: "2rem" }}>
        <img src="/images/app/icons/Locked.png" height="20" alt="Locked icon" style={{ display: "inline-block" }} />
        
      </P>
      <SubSectionHeading>Locked activities</SubSectionHeading>
      <P>
        A key point of difference between teacher and pupil accounts is that, when pupils first log in to their pupil
        account, they are guided to complete the five 'Understanding your SAMHE monitor' quizzes before moving on to
        other activities. This ensures they have a basic understanding of what the air quality readings mean before they
        start investigating the data. We think this is important to mitigate against misunderstandings, misconceptions,
        and concerns that might arise about air quality in their classroom. To begin with, these 5 activities appear as
        available/unlocked (purple cards) to pupils, and all others will be unavailable/locked ( blue cards). Until the
        five 'Understanding your SAMHE monitor' activities are completed, either by working through them step-by-step,
        or by using a ‘completion code’, pupils will not be able to access other activities. This ‘locking’ mechanism is
        not applied to teacher accounts, meaning all activities appear as available/unlocked (purple).
      </P>
      <SubSectionHeading>Bypassing the locking mechanism using completion codes</SubSectionHeading>
      <P>
        If they wish, teacher users can enable pupils to bypass these five introductory quizzes and move straight on to
        the other activities. Teachers do this by giving pupils a ‘completion code’ for each quiz. These can be found in
        the introduction section of the activity once it has been opened (see screenshot 1 below) or in the{" "}
        <Link to="/app/library">Web App library</Link>. To enter the relevant completion code, pupils then need to open
        up the corresponding introductory quiz activity and enter the code in the dedicated box in the introduction
        section (See screenshot 2 below). This process should be repeated for each of the quizzes.
      </P>
      <P>
        When the correct code has been entered, a ‘complete activity’ button will appear. Pupils should click this
        button to confirm the activity as ‘complete’.
      </P>
      <P>
        <i>Screenshot 1: Location of completion code - visible on teacher and admin accounts</i>
      </P>
      <P>
        <img
          src="/images/app/activities/screenshots/activity-info-section.png"
          width="100%"
          alt="screenshot of the activity info section showing the details, estimated time and score contributions"
        />
      </P>
      <P>
        <i>Screenshot 2: Location of completion code entry box - visible to pupil accounts only</i>
      </P>
      <P>
        <img
          src="/images/app/activities/screenshots/activity-info-section-pupil.png"
          width="100%"
          alt="screenshot of the activity info section showing the details, estimated time and score contributions"
        />
      </P>
      </>
    ),
  },
  {
    uid: "differences_between_accounts",
    label: "Differences between teacher and pupil accounts",
    group: null,
    audience: [],
    content: (
      <>
        <Ul>
          <li>
            Some activities are initially locked for pupils (but not for teachers). Teachers can unlock these activities
            for pupils if they wish. See more about unlocking activities for pupils.
          </li>
          <li>
            Some activities, and questions within activities, are for teachers only - this is typically where we want
            teachers’ points of view or when free text responses are enabled. (We do not allow pupils to enter free text
            for safeguarding reasons).
          </li>
          <li>
            Teacher users also have access to more resources in the <Link to="/app/library">library</Link> tab.
          </li>
        </Ul>
      </>
    ),
  },
  {
    uid: "how_to_direct_students_to_activities",
    label: "How to direct your students to specific activities",
    group: null,
    audience: [],
    content: (
      <>
        <P>
        Activities are displayed to each user in a particular order, depending on which activities they’ve already
        completed, with activities which are unlocked and have yet to be completed displayed first. Because of this, if
        you are working with a group of pupils with their own Web App accounts, activities may appear in a different
        order to each of you.
      </P>
      <P>
        To direct pupils to specific activities, you can instruct pupils to use the ‘jump to activity’ dropdown menu at
        the top of the Activities page. Selecting an activity from this list will take pupils directly to the activity.
      </P>
      </>
    ),
  },
  {
    uid: "how_to_integrate_into_learning",
    label: "Ideas about how to integrate SAMHE in your teaching",
    group: null,
    audience: [],
    content: (
      <>
        <P>
        SAMHE has strong links across different subject areas and stages of education, making it a useful tool for
        integrating within lessons. Working with real time data on their immediate environment makes learning about the
        world around them and practising skills in maths, science and literacy, more relevant and engaging to pupils.
      </P>
      <P>
        For each UK nation we have illustrated 5 ways in which SAMHE can be used to support different areas of the
        curriculum. These are just a few examples, as SAMHE can be integrated into many areas of your teaching. Take a
        look at our <Link to="/resources/curriculum-links">curriculum links page</Link> for some inspiration.
      </P>
      </>
    ),
  },
  {
    uid: "keeping_in_touch",
    label: "Keeping in touch",
    group: null,
    audience: [],
    content: (
      <>
        <P>
        We're keen to use the SAMHE newsletter to develop a mutually supportive community of teachers and others using
        SAMHE, by providing a platform for you to share your ideas, knowledge and success stories. Perhaps you have
        developed some interesting experiments which other schools might be interested in? Or started a new air quality
        initiative? Or produced some exciting pieces of writing or artwork about air quality? Perhaps your pupils would
        like to share how they feel about SAMHE, or what they’ve learned about air quality? (They can’t contribute
        directly but you could submit a piece on their behalf.)
      </P>
      <P>
        Please use our{" "}
        <AExternal href="https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform?usp=sf_link">
          Newsletter Contributions Form
        </AExternal>{" "}
        to submit your contribution.
      </P>
      <P>
        Equally, if you have any ideas for new activities you’d like us to consider adding to the Web App, please share
        them using our{" "}
        <AExternal href="https://docs.google.com/forms/d/e/1FAIpQLSfksyq8pwR6y12dVeLNoDqeVwP-YkFA_0BBkpKrPzKtc5_srg/viewform?usp=sf_link">
          activity contributions form
        </AExternal>
        .
      </P>
      <P>
        <b>Please note these forms are for completion by over 18s only.</b>
      </P>
      <P>
        If you are having any issues with the Web App please report these using the{" "}
        <Link to="/support/tech-contact">technical queries form</Link>.
      </P>
      </>
    ),
  },
];

export const TeacherExtraAppInfo = () => (
  <>
  <Section>
        <FaqItemWrapStyle>
          {entries.map((item) => (
            <FaqItem key={item.uid} data={item} />
          ))}
        </FaqItemWrapStyle>
      </Section>
  </>
);
