import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { SearchAndSelectDropdown } from "@react_db_client/components.search-and-select-dropdown";
import { CTAButtonStyle, OnOffButtonStyle } from "../../styles/button";
import { FormStyle, InputStyle, HorizSpacer } from "../../styles/form";
import { InputArea, InputWrap } from "../FormComponents/Input";
import {
  IMonitorRequestSubmission,
  monitorRequestQuestions,
  IMonitorRequestFormData,
  monitorRequestFormDefaultValues,
} from "../../lib/formSubmissions/IMonitorRequestSubmission";
import { apiMonitorRequest } from "../../Api/apiMonitorRequest";
import { Section, SectionHeading, SectionSubHeading, PLong, InsetDiv, FormHeading } from "../../styles/page";
import { Select } from "../FormComponents/Select";
import { IMultiCheckboxProps, MultiCheckbox } from "../FormComponents/MultiCheckbox";
import { extractBitwiseIndexesFromMultiCheckboxOutput, getSelectValue } from "../FormComponents/utils";
import { ParticipationInfo } from "../Documents/ParticipationInfo";
import { Label } from "../FormComponents/Label";
import { ISchoolEstablishment } from "../../lib/formSubmissions/ISchoolEstablishment";
import { FlexBox } from "../../styles/singlePageStyles";
import { ErrorMessageStyle } from "../FormComponents/ErrorMessage";
import { AExternal } from "../Links";

export interface SchoolRegistrationFormProps { }

const TemplateEmailTextBox = styled.textarea`
  width: 100%;
  height: 20rem;
  padding: 0.5rem;
  font-size: ${({ theme }) => theme.typography.fontSize2};
  line-height: ${({ theme }) => theme.typography.lineHeight2};
`;

const UserTypeSelectBtns = styled.div`
  width: 100%;
  display: flex;
  button {
    flex-grow: 1;
    display: flex:
    flex-direction: column;
  }
`;

const TemplateEmailText = `Dear [insert teacher name],

I'd like our school to get involved with the SAMHE (School Air quality Monitoring for Health and Education, pronounced 'Sammy'!) research project to improve air quality in schools.

We would receive a free air quality monitor which would measure and record air quality data in our school. We would be able to see the data, and analyse it if we want. The research scientists would also use it to analyse air quality across UK schools.

More information about the project can be found on the SAMHE project website SAMHE.org.uk

I need the support of a teacher to get our school involved. If you are happy for us to be part of this project, please request an air quality monitor by completing a short form here SAMHE.org.uk/register/school.

Many thanks,

From [your name goes here!]
`;

const TemplateEmail = () => <TemplateEmailTextBox readOnly value={TemplateEmailText} />;

const PupilInfo = () => (
  <div>
    <Section>
      <PLong>Great! We're so pleased you want to be involved!</PLong>
      <PLong>
        You will need to ask a teacher to fill in this form. We suggest trying your science, geography or maths teacher,
        or a teacher who runs a science / eco club, or school council, or similar.
      </PLong>
      <PLong>If you like, you can copy and paste the text below to send to a teacher you think would like to help out!</PLong>
    </Section>
    <Section>
      <SectionSubHeading>Email Template</SectionSubHeading>
      <TemplateEmail />
    </Section>
  </div>
);

const checkConsents = (data) =>
  ["consentToParticipant", "consentToVoluntary", "consentToTakePart", "consentToMonitor"].every((k) => data[k]);

const parseFormData = (
  data: IMonitorRequestFormData,
  schoolDetails: ISchoolEstablishment
): IMonitorRequestSubmission => {
  const [schoolAddress1, schoolAddress2, schoolAddress3, schoolAddress4] = schoolDetails.address
    .split(",")
    .map((s) => s.trim());
  const amendedData: IMonitorRequestSubmission = {
    ...data,
    schoolName: schoolDetails.name,
    schoolAddressPostCode: schoolDetails.postcode,
    schoolAddress1,
    schoolAddress2,
    schoolAddress3,
    schoolAddress4,
    establishmentId: String(schoolDetails.establishmentId),
    // TODO: would have been nicer to extract Uids here
    requestReasons: extractBitwiseIndexesFromMultiCheckboxOutput(data.requestReasons),
    schoolType: extractBitwiseIndexesFromMultiCheckboxOutput(data.schoolType),
    howDidYouFindUs: extractBitwiseIndexesFromMultiCheckboxOutput(data.howDidYouFindUs),
    submitterRole: getSelectValue(data.submitterRole, monitorRequestQuestions.submitterRole.options),
    schoolFundingType: getSelectValue(data.schoolFundingType, monitorRequestQuestions.schoolFundingType.options),
    schoolSize: getSelectValue(data.schoolSize, monitorRequestQuestions.schoolSize.options),
    schoolBuildingAge: getSelectValue(data.schoolBuildingAge, monitorRequestQuestions.schoolBuildingAge.options),
  };
  return amendedData as IMonitorRequestSubmission;
};

const searchSchools = async (filters) => {
  const postcode = filters[0].value;
  return apiMonitorRequest.apiGetSchoolFromFullList(postcode).then((schools) =>
    schools.map((s) => ({
      ...s,
      label: `${s.postcode.padEnd(7, "_")} - \t ${s.name}`,
      uid: `${s.name} \t ${s.postcode}`,
    }))
  );
};

const SchoolRegistrationForm: React.FC<SchoolRegistrationFormProps> = () => {
  const [message, setMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState(0);
  const [selectedSchool, setSelectedSchool] = React.useState<ISchoolEstablishment | null>(null);
  const navigate = useNavigate();

  const validateEmail = () => {
    const values = formData.getValues();
    if (values.email && values.email !== values.confirmEmail) {
      return "Email and Confirm Email do not match.";
    }
    return true;
  };

  const validateSchoolEmail = () => {
    const values = formData.getValues();
    if (values.schoolEmail && values.schoolEmail !== values.confirmSchoolEmail) {
      return '"Generic office email address" and "Confirm generic office email address" do not match.';
    }
    return true;
  };

  const onFormSubmit = async (data: IMonitorRequestFormData) => {
    setMessage("Submitting Form");
    setLoading(true);

    if (!checkConsents(data)) {
      setMessage("Must agree to all required consents.");
      throw Error("Must agree to all required consents.");
    }
    if (!selectedSchool) {
      setMessage("Must select a school from the list.");
      throw Error("Must select a school from the list.");
    }
    const parsedFormData = parseFormData(data, selectedSchool);
    return apiMonitorRequest
      .post(parsedFormData)
      .then(() => {
        // Successfully submitted form.
        navigate("/register/school/success");
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formData = useForm<IMonitorRequestFormData>({
    defaultValues: monitorRequestFormDefaultValues,
    reValidateMode: "onSubmit",
  });

  const { handleSubmit, formState, watch } = formData;

  const { isSubmitted, isSubmitSuccessful, isSubmitting, isValidating, errors } = formState;

  React.useEffect(() => {
    if (isValidating || isSubmitting) {
      setMessage("-");
    }
  }, [isValidating, isSubmitting]);

  const containsErrors = Object.keys(errors).length > 0;

  const displayMessage = React.useMemo(
    () =>
      (isSubmitting && "Submitting Form.") ||
      (isValidating && "Validating Form") ||
      (isSubmitted &&
        containsErrors &&
        "Form has validation errors. Check if you have filled in all required fields.") ||
      (isSubmitted && !isSubmitSuccessful && "There was an error submitting your answers. Please try again.") ||
      (isSubmitted && isSubmitSuccessful && "Submission successful. Navigating to success page.") ||
      message ||
      "",
    [isSubmitted, containsErrors, isSubmitSuccessful, message, isSubmitting, isValidating]
  );

  const isErrorMessage = React.useMemo(() => {
    return containsErrors || (isSubmitted && !isSubmitSuccessful);
  }, [containsErrors, isSubmitSuccessful, isSubmitted]);

  const useAlternateDeliveryAddress = watch("useAlternateDeliveryAddress");

  return (
    <div title="School Registration Form" className="registrationForm_wrap">
      <div>
        <Section>
          <SectionHeading>I am a...</SectionHeading>
          <UserTypeSelectBtns>
            <OnOffButtonStyle
              style={{ marginRight: "1rem", fontWeight: "bold" }}
              on={userType === 1 ? "true" : undefined}
              onClick={() => setUserType(1)}
            >
              Pupil
            </OnOffButtonStyle>
            <OnOffButtonStyle
              style={{ marginLeft: "1rem", fontWeight: "bold" }}
              on={userType === 2 ? "true" : undefined}
              onClick={() => setUserType(2)}
            >
              Teacher
            </OnOffButtonStyle>
          </UserTypeSelectBtns>
        </Section>
      </div>
      {userType === 1 && <PupilInfo />}
      {userType === 2 && (
        <>
          <Section>
            <InsetDiv>
              <ParticipationInfo />
            </InsetDiv>
          </Section>
          <FormProvider {...formData}>
            <FormStyle
              aria-label="School Registration Form"
              name="schoolRegistrationForm"
              onSubmit={handleSubmit(onFormSubmit)}
            // onSubmit={() => handleSubmitMid()}
            >
              <FormHeading>Confirmation of Consent</FormHeading>
              <InputWrap {...monitorRequestQuestions.consentToParticipant} />
              <InputWrap {...monitorRequestQuestions.consentToVoluntary} />
              <InputWrap {...monitorRequestQuestions.consentToTakePart} />
              <InputWrap {...monitorRequestQuestions.consentToMonitor} />
              <FormHeading>Future Contact</FormHeading>
              <InputWrap {...monitorRequestQuestions.consentToContact} />
              <InputWrap {...monitorRequestQuestions.consentToNewsletter} />
              <FormHeading>Confirmation of Eligibility</FormHeading>
              <InputStyle>
                <Label name="school_registration_form-search-schools-input" label="Search for your school" required />
                <PLong>
                  You can only register if your school is eligible to participate in SAMHE. Check this by inputting your
                  postcode below*. To enable us to find a match in our database, please enter your postcode with a space
                  (i.e. in the format AA1 1AA). If you cannot find your school, contact us directly using our{" "}
                  <Link to="/contact">Contact Form</Link>.
                </PLong>
                <PLong>
                  Once you have found your school, please confirm your selection using the tick box which will appear
                  below. Unless you supply alternate delivery details, we will use the address associated with your
                  school record.
                </PLong>
                <PLong>
                  <i>
                    *This field searches a database compiled using records from GIAS, gov.scot and EANI. If you cannot
                    find your school in our search box below, please find the record for your school on{" "}
                    <AExternal href="https://www.get-information-schools.service.gov.uk/">GIAS</AExternal>/
                    <AExternal href="https://education.gov.scot/parentzone/">gov.scot</AExternal>/
                    <AExternal href=" https://www.eani.org.uk/search-schools">EANI</AExternal> and enter that postcode
                    here. Multiple site schools will have the opportunity to provide a different delivery address if
                    required.
                  </i>
                </PLong>
                <SearchAndSelectDropdown
                  id="school_registration_form-search-schools-input"
                  labelField="label"
                  searchFunction={searchSchools}
                  handleSelect={(d) => setSelectedSchool(d)}
                  placeholder="Start typing your school's postcode"
                />
                <div>
                  {selectedSchool && (
                    <FlexBox data-testid="school_registration_form-school-details">
                      <p>
                        <b>School Name</b>
                        <span> : </span> {selectedSchool.name}
                      </p>
                      <div>
                        <b>School Address</b>
                        <span> : </span>
                        {selectedSchool.address.split(",").map((a) => (
                          <p key={a}>{a}</p>
                        ))}
                        <p>{selectedSchool.postcode}</p>
                      </div>
                      <p>
                        <b>School Reference Number</b>
                        <span> : </span>
                        {selectedSchool.establishmentId}
                      </p>
                      <InputWrap {...monitorRequestQuestions.confirmSchoolDetails} />
                    </FlexBox>
                  )}
                </div>
              </InputStyle>

              <InputWrap {...monitorRequestQuestions.useAlternateDeliveryAddress} />
              {useAlternateDeliveryAddress && (
                <>
                  <PLong>
                    We will need to know where to send your monitor. Please provide the name and address of your school
                    including the name of a specific building for delivery (if required).
                  </PLong>
                  <InputWrap {...monitorRequestQuestions.altSchoolAddress1} />
                  <InputWrap {...monitorRequestQuestions.altSchoolAddress2} />
                  <InputWrap {...monitorRequestQuestions.altSchoolAddress3} />
                  <InputWrap {...monitorRequestQuestions.altSchoolAddress4} />
                  <InputWrap {...monitorRequestQuestions.altSchoolAddressPostCode} />
                </>
              )}
              <FormHeading>Your Details</FormHeading>
              <div style={{ display: "flex", flexGrow: 1, width: "100%", flexWrap: "wrap" }}>
                <InputWrap style={{ width: "20rem", flexGrow: 1 }} {...monitorRequestQuestions.firstName} />
                <HorizSpacer />
                <InputWrap style={{ width: "20rem", flexGrow: 1 }} {...monitorRequestQuestions.lastName} />
              </div>
              <InputWrap {...monitorRequestQuestions.email} />
              <InputWrap
                {...monitorRequestQuestions.confirmEmail}
                validate={validateEmail}
                onInput={(e: any) => {
                  e.target.setCustomValidity("");
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  e.target.setCustomValidity(
                    "To prevent typos, pasting is not possible in this field. Please re-type your email address."
                  );
                  e.target.reportValidity();
                  return false;
                }}
              />
              <Select {...monitorRequestQuestions.submitterRole} labelField="label" searchDelay={50} placeholder="" />
              <FormHeading>School Details</FormHeading>
              <PLong>
                <b>IMPORTANT: please supply a generic email address for your school</b> e.g. reception@myschool.uk that
                we can use to set up your school's Admin account. This email should not identify anyone and should not
                relate to a specific individual.
              </PLong>
              <PLong>
                If you are not sure of your school's office email, <b>please check</b> it against the contact email
                listed on your school website. We will use this email for essential project communications and an
                incorrect email address may prevent us from sending your monitor.
              </PLong>
              <InputWrap {...monitorRequestQuestions.schoolEmail} />
              <InputWrap
                {...monitorRequestQuestions.confirmSchoolEmail}
                validate={validateSchoolEmail}
                onInput={(e: any) => {
                  e.target.setCustomValidity("");
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  e.target.setCustomValidity(
                    "To prevent typos, pasting is not possible in this field. Please re-type your email address."
                  );
                  e.target.reportValidity();
                  return false;
                }}
              />
              <PLong>
                To arrange for the monitor to be delivered to you we will also need a contact telephone number. Please
                provide the number of your school office.
              </PLong>
              <InputWrap {...monitorRequestQuestions.schoolTelephone} />
              <MultiCheckbox {...(monitorRequestQuestions.schoolFundingType as IMultiCheckboxProps)} />
              <MultiCheckbox {...(monitorRequestQuestions.schoolType as IMultiCheckboxProps)} />
              <InputWrap {...monitorRequestQuestions.schoolScienceClub} />
              <InputArea {...monitorRequestQuestions.schoolScienceClubDetails} />
              <MultiCheckbox {...(monitorRequestQuestions.schoolSize as IMultiCheckboxProps)} />
              <MultiCheckbox {...(monitorRequestQuestions.schoolBuildingAge as IMultiCheckboxProps)} />
              <MultiCheckbox {...(monitorRequestQuestions.requestReasons as IMultiCheckboxProps)} />
              <InputArea {...monitorRequestQuestions.requestReasonOther} />
              <FormHeading>How did you find out about SAMHE?</FormHeading>
              <PLong>
                We'd be really interested to know how you found out about SAMHE. This helps us to understand which of
                the ways of promoting our project are most effective so we can focus our efforts.
              </PLong>
              <MultiCheckbox {...(monitorRequestQuestions.howDidYouFindUs as IMultiCheckboxProps)} />
              <InputArea {...monitorRequestQuestions.howDidYouFindUsOther} />
              {isErrorMessage && <ErrorMessageStyle>{displayMessage}</ErrorMessageStyle>}
              <br></br>
              {!isErrorMessage && <div data-testid="registrationForm-displayMessage">{displayMessage || " "}</div>}
              <InputStyle style={{ alignItems: "center" }}>
                <CTAButtonStyle style={{ width: "30%" }} type="submit" disabled={loading}>
                  {loading ? "Loading" : "Submit"}
                </CTAButtonStyle>
              </InputStyle>
            </FormStyle>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default SchoolRegistrationForm;