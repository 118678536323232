import styled from "styled-components";
import { FlexBox } from "../../../styles/singlePageStyles";
import { H2 } from "../../../styles/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWaveSquare } from "@fortawesome/free-solid-svg-icons";
import { TextFormatter } from "../../TextFormatter/TextFormatter";
import { keywordMap } from "../../../dummyData/keywords";

const PatternInfoBoxStyle = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #f5f5ff;
  border-radius: 1rem;
`;

const PatternInfoBoxContentStyle = styled(FlexBox)`
  flex-direction: row;
  gap: 1.5rem;
  @media (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
`;

interface IPatternInfoBoxContentProps {
  content: string[];
}

const PatternInfoBoxContent: React.FC<IPatternInfoBoxContentProps> = ({ content }) => (
  <PatternInfoBoxContentStyle>
    {content.map((text) => (
      <div key={text}>
        <TextFormatter text={text} keywordMap={keywordMap} />
      </div>
    ))}
  </PatternInfoBoxContentStyle>
);

export enum EPatternInfoBoxType {
  SIMPLE = "simple",
  BUBBLE = "bubble",
  SCATTER = "scatter",
}

export interface IPatternInfoBoxProps {
  type: EPatternInfoBoxType;
}

const PATTERN_INFO_BOX_CONTENTS: { [key in EPatternInfoBoxType]: string[] } = {
  [EPatternInfoBoxType.SIMPLE]: [
    "Try describing the patterns you can see on this graph. What happens as time progresses (as you go further along the x axis)?",
    "You may notice that when you look at the same data over different time periods, it looks different. For example, depending on the time period selected the line may seem to have more peaks, or it may appear flatter.",
    "This is because whenever you change the time period selected, the !maximum! value on the y axis will automatically update in relation to the maximum value observed during that time period.",
    "A lower maximum value creates the effect of zooming in on the data. The opposite happens for a higher maximum value.",
  ],
  [EPatternInfoBoxType.BUBBLE]: [
    "Try describing the patterns you can see on this graph. What happens as time progresses (as you go further along the x axis)?",
    "If you look at a week's worth of data do you see the same patterns on each day? How do these relate to what is happening in the room?",
    "This plot type will enable you to explore recent data for short periods of time (a single day or a single week's worth of data). If you'd like to explore older data or data covering longer time periods you'll need to use one of our line plots.",
  ],
  [EPatternInfoBoxType.SCATTER]: [
    "You can investigate links between different metrics. For example, if the metric on the y axis increases as the metric on the x axis increases the two metrics are positively correlated. It may be that one metric is influencing the other (or both are being influenced by something else). If one metric increases in value as the other decreases, they are  negatively correlated. If there are dots all over the plot, or no clear pattern, that suggests that the two metrics you have chosen are not correlated.",
    "For example, the default settings show temperature on the x axis and CO₂ on the y axis. You may see that when people were in your classroom the CO₂ concentration increased over time (the dots get higher on the plot as the colour changes) because people breathed out CO₂. Maybe the temperature increased as well, as people produce heat. If not, what else could affect the value of CO₂ and temperature? ",
  ],
};

export const PatternInfoBox: React.FC<IPatternInfoBoxProps> = ({ type }) => {
  const content = PATTERN_INFO_BOX_CONTENTS[type] || [];

  return (
    <PatternInfoBoxStyle>
      <p style={{ textAlign: "center", marginBottom: "1rem" }}>
        <FontAwesomeIcon icon={faWaveSquare} style={{ fontSize: "3rem" }} />
      </p>
      <H2 style={{ textAlign: "center", marginBottom: "1rem" }}>Can you see any patterns?</H2>
      <PatternInfoBoxContent content={content} />
    </PatternInfoBoxStyle>
  );
};
