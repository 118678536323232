import { FilterObjectClass } from "@react_db_client/constants.client-types";
import { EResourceType, IAppResource } from "../../lib/AppResources/IAppResource";
import { UserGroup } from "../../lib/User/UserGroup";
import { useAppSelector } from "../../Redux/hooks";
import { IResourceCardData } from "./AppResourcesCard/IAppResourceCardProps";

export interface IUseGetResourcesMetaDataArgs {
  filters?: FilterObjectClass[];
}

/* Resources are currently hard coded */
export const resources: IAppResource[] = [
  {
    id: "samheCert1",
    label: "SAMHE Hero Certificate 1",
    description: "Click the download link below to download our exclusive SAMHE Hero Certificate.",
    resourceType: EResourceType.CERTIFICATE,
    resourceUrl: "/app/certificates/SAMHE_CERTIFICATE_1.docx",
    thumbnailUrl: "/app/certificates/SAMHE_certificate_1_SMALL.jpg",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samheCert2",
    label: "SAMHE Hero Certificate 2",
    description: "Click the download link below to download our exclusive SAMHE Hero Certificate.",
    resourceType: EResourceType.CERTIFICATE,
    resourceUrl: "/app/certificates/SAMHE_CERTIFICATE_2.docx",
    thumbnailUrl: "/app/certificates/SAMHE_certificate_2_SMALL.jpg",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samhe_parent_information_pack",
    label: "SAMHE Parent information pack",
    description:
      "This 'parent information pack' contains templates designed to help you communicate with parents/guardians about taking part in SAMHE. Please use these in combination with your usual processes and adapt the templates as required.",
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/SAMHE_Parent_information_pack.pdf",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samhe_monitor_sign",
    label: "SAMHE Monitor sign",
    description: `This A5 "Hi, I'm your SAMHE monitor" poster can be put next to your monitor to remind people what it is doing and why (and encourage them not to interfere with the monitor without permission).`,
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/samhe_monitor_sign.zip",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "we_are_part_of_samhe",
    label: `We're part of SAMHE" poster`,
    description: `You could use this A4 'We are part of SAMHE!' poster in your classroom, on a school notice board, or as part of a display, to celebrate being part of SAMHE. The poster also promotes the SAMHE website, to help more people learn about SAMHE.`,
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/we_are_part_of_samhe.zip",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "activity_completion_codes",
    label: `Activity Completion Codes`,
    description: `This is a list of completion codes which can be shared with your pupils to mark activities as complete in their Web App accounts. This might be useful if you're completing activities as a class and would like to share credit between everyone involved, or you'd like to bypass our 'locking' mechanism which guides pupils to complete activities in a given order.`,
    resourceType: EResourceType.POPUP_INFO,
    resourceUrl: "/app/library/activity_completion_codes",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "extra_teacher_notes",
    label: `Extra guidance for Teachers`,
    description: `This card contains information on:
- How to set up accounts for other users
- Key differences between staff and pupil accounts
- How to direct pupils to specific activities
- How to use SAMHE to support your curriculum-based teaching
- How to be part of a mutually supportive community of SAMHE users!
    `,
    resourceType: EResourceType.POPUP_INFO,
    resourceUrl: "/app/library/extra_teacher_notes",
    thumbnailUrl: "/images/app/home/app-robot-1.png",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
];

export const resourceCardData: IResourceCardData[] = resources.map((r) => ({
  ...r,
  title: r.label,
  uniqueCardId: r.id,
  type: r.resourceType,
}));

export const useGetResourcesMetaData = ({ filters: _filters }: IUseGetResourcesMetaDataArgs) => {
  const {
    user: { group },
  } = useAppSelector((state) => state.userState);
  // TODO: Implement filters
  return resourceCardData.filter(
    (c) =>
      !c.restrictedAccessGroups ||
      c.restrictedAccessGroups.length === 0 ||
      c.restrictedAccessGroups.indexOf(group) !== -1
  );
};
