import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  AppDashboardStyle,
  AppDashboardWrapStyle,
  AppInfoSection,
  SideBarDashboardStyle,
  AppSectionHeading,
  CardExpandedHr,
} from "../../styles/app";
import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
import { CTALinkButtonStyle } from "../../styles/links";
import { LinkExternal } from "../Links";
import { SidebarDataViews } from "../SideBar/SideBarDataViews";
import { UserAgePrompt } from "../UserAgePrompt";
import { useAppSelector } from "../../Redux/hooks";
import { FilterStateProvider } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { ActivityViewFields, initialFilterData } from "../../lib/Activities/IActivity";
import { ActivityViewHomePage } from "../ActivitiesDashboard/ActivityScroller";

const AppHomeBanner = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  padding-left: ${({ theme }) => theme.shape.defaultPaddingLarge};
  padding-right: ${({ theme }) => theme.shape.defaultPaddingLarge};
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.appBackground};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
  }
`;

const AppHomeBannerLHS = styled.div`
  flex-grow: 0.4;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 38%;
    margin-right: 1rem;
    margin-bottom: 0;
    max-height: 75vh;
    padding-bottom: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const AppHomeBannerHeading1 = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 3.37rem;
  font-weight: bold;
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 2rem;
  }
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;

const AppHomeBannerHeading2 = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSize4};
  font-weight: bold;
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }
  margin: ${({ theme }) => theme.shape.defaultPaddingMid} 0;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  text-align: center;
`;

const AppHomeBannerParagraph = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize4};
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin-top: ${({ theme }) => theme.shape.defaultPaddingMid};
`;

const AppHomeBannerRHS = styled.div`
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 62%;
  }

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 10rem;
    line-height: 10rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    flex-direction: row;
  }
`;

const AppHomeBannerContentContainer = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.shape.defaultPaddingWide};
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  background-color: ${({ theme }) => theme.colors.baseBackground};
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  gap: 1rem;
  position: relative;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.larger}) {
    padding: ${({ theme }) => theme.shape.defaultPadding};
    flex-direction: column;
  }
`;

const HeroTutorialLinkContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.boxoutBackground};
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2), 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;

  border-radius: 1rem;
  margin-top: ${({ theme }) => theme.shape.defaultPaddingMid};
  padding: ${({ theme }) => theme.shape.defaultPaddingSmall};

  flex: 0;
  gap: ${({ theme }) => theme.shape.defaultPaddingMid};
  min-width: 12rem;
  overflow: hidden;

  .icon-wrapper {
    display: flex;
  }

  img.icon {
    height: 5rem;
    width: auto;
  }

  flex-direction: row;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    flex-direction: column;
    align-self: center;
    padding: ${({ theme }) => theme.shape.defaultPaddingMid};

    img.icon {
      width: 80%;
      height: auto;
      margin: auto;
    }
  }
`;

const TutorialButton = styled(CTALinkButtonStyle)`
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.text};
  width: fit-content;
  text-align: center;
  margin-bottom: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryDark};
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

export const AppDashboard: React.FC = () => {
  const [containerRef] = useGetBreakpoints(1.5);
  const {
    user: { username },
  } = useAppSelector((state) => state.userState);
  const [appHomeImage, setAppHomeImage] = React.useState(1);

  React.useEffect(() => {
    const randomNum = Math.floor(Math.random() * (7 - 1 + 1) + 1);
    setAppHomeImage(randomNum);
  }, []);

  return (
    <AppDashboardWrapStyle
      className="subpage_wrap"
      data-testid="samheAppInner"
      style={{ flexDirection: "column", padding: 0, gap: 0 }}
    >
      <AppHomeBanner>
        <AppHomeBannerLHS>
          <img src={`/images/app/home/app-robot-${appHomeImage}.png`} alt="SAMHE robot" />
        </AppHomeBannerLHS>
        <AppHomeBannerRHS>
          <AppHomeBannerContentContainer>
            <AppHomeBannerHeading1>Hello User</AppHomeBannerHeading1>
            <AppHomeBannerHeading1>{username}</AppHomeBannerHeading1>
            <p style={{ minHeight: "1rem" }} />
            <AppHomeBannerParagraph>
              Welcome to the SAMHE Web App! Thank you for investigating your school's air quality with us.
            </AppHomeBannerParagraph>
            <AppHomeBannerParagraph>
              With the SAMHE Web App you can explore data from your SAMHE monitor, complete activities to investigate
              your data and learn more about the air quality of your classroom.
            </AppHomeBannerParagraph>
          </AppHomeBannerContentContainer>
          <HeroTutorialLinkContainer>
            <div className="icon-wrapper">
              <img src="/images/app/home/Computer_with_Web_App.png" alt="SAMHE robot" className="icon" />
            </div>
            <AppHomeBannerHeading2>New to the web app?</AppHomeBannerHeading2>
            <TutorialButton style={{ width: "100%" }} to="/app/activities/23">
              Take the tutorial
            </TutorialButton>
            <TutorialButton style={{ width: "100%" }} to="/app/library/extra_teacher_notes">
              Extra teacher notes
            </TutorialButton>
          </HeroTutorialLinkContainer>
        </AppHomeBannerRHS>
      </AppHomeBanner>
      <ContentContainer>
        <SideBarDashboardStyle>
          <AppInfoSection>
            <AppSectionHeading style={{ color: "#303b71" }}>Live Data</AppSectionHeading>
            <CardExpandedHr />
            <p>
              Use these data views to explore data from your SAMHE monitor in more detail. To understand what the
              numbers mean, take a look at our{" "}
              <LinkExternal to="/resources/monitor-leds">guidance on interpreting monitor readings</LinkExternal>.
            </p>
            <br></br>
            <SidebarDataViews />
            <CTALinkButtonStyle style={{ marginTop: "4rem", backgroundColor: "#303b71" }} to="/app/data">
              View all data
            </CTALinkButtonStyle>
          </AppInfoSection>
        </SideBarDashboardStyle>
        <AppDashboardStyle data-testid="appPage-dashboard" ref={containerRef}>
          <FilterStateProvider fieldsData={ActivityViewFields} initialFilterData={initialFilterData}>
            <AppInfoSection style={{ position: "relative" }}>
              <UserAgePrompt />
              <ActivityViewHomePage />
            </AppInfoSection>
          </FilterStateProvider>
        </AppDashboardStyle>
      </ContentContainer>
    </AppDashboardWrapStyle>
  );
};
