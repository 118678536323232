import React from "react";
import { Link } from "react-router-dom";
import { BETA_VERSION } from "../../config";
import styled from "styled-components";
// import UserDropDownMenu from "../../GenericComponents/UserDropDownBtn/UserDropDownBtn";
import { NavBar, Logo, LogoWrap } from "../../styles/header";
import { CTALinkButtonStyle } from "../../styles/links";

export const NavDropdown = styled.div`
  overflow: visible !important;
`;

export const Header: React.FC = () => {
  /* menuOpen only affects mobile */
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <NavBar className="header_wrap" data-testid="publicHeader">
      <div className="navbar">
        <LogoWrap>
          <Logo>
            <Link to="/">
              {BETA_VERSION ? (
                <img src="/images/logo/samhe_beta_logo.png" alt="SAMHE" className="logo" />
              ) : (
                <img src="/images/logo/samhe_logo.png" alt="SAMHE" className="logo" />
              )}
            </Link>
          </Logo>
        </LogoWrap>
        <div className={`menu-wrapper ${showNavbar && "active"}`}>
          <div className="button-wrapper">
            <CTALinkButtonStyle style={{ padding: "0.5rem" }} to="/get-involved">
              Get Involved
            </CTALinkButtonStyle>
            <CTALinkButtonStyle style={{ padding: "0.5rem" }} to="/app">
              Web App
            </CTALinkButtonStyle>
          </div>
          <div className="menu">
            <NavDropdown className="dropdown">
              <button className="dropbtn">ABOUT</button>
              <Link to="/about" className="droplink">
                ABOUT
              </Link>
              <div className="dropdown-content">
                <Link to="/about" onClick={() => setShowNavbar(false)}>
                  About SAMHE
                </Link>
                <Link to="/about/team" onClick={() => setShowNavbar(false)}>
                  The Team
                </Link>
                <Link to="/get-involved" onClick={() => setShowNavbar(false)}>
                  Get Involved
                </Link>
                <Link to="/how-samhe-works" onClick={() => setShowNavbar(false)}>
                  How SAMHE works
                </Link>
                <Link to="/co-design" onClick={() => setShowNavbar(false)}>
                  Co-design
                </Link>
                <Link to="/timeline" onClick={() => setShowNavbar(false)}>
                  Timeline
                </Link>
                <Link to="/news" onClick={() => setShowNavbar(false)}>
                  News
                </Link>
              </div>
            </NavDropdown>
            <NavDropdown className="dropdown">
              <button className="dropbtn">RESOURCES</button>
              <Link to="/resources" className="droplink">
                RESOURCES
              </Link>
              <div className="dropdown-content">
                <Link to="/resources/air-pollution" onClick={() => setShowNavbar(false)}>
                  About air pollution
                </Link>
                <Link to="/resources/key-definitions" onClick={() => setShowNavbar(false)}>
                  Key definitions
                </Link>
                <Link to="/resources/monitor-leds" onClick={() => setShowNavbar(false)}>
                  Interpreting monitor readings
                </Link>
                <Link to="/resources/ventilation" onClick={() => setShowNavbar(false)}>
                  Ventilation guidance
                </Link>
                <Link to="/resources/outputs" onClick={() => setShowNavbar(false)}>
                  Outputs
                </Link>
                <Link to="/resources/curriculum-links" onClick={() => setShowNavbar(false)}>
                  Curriculum links
                </Link>
                <Link to="/resources" onClick={() => setShowNavbar(false)}>
                  All resources
                </Link>
              </div>
            </NavDropdown>
            <NavDropdown className="dropdown">
              <button className="dropbtn">HELP CENTRE</button>
              <Link to="/support" className="droplink">
                HELP CENTRE
              </Link>
              <div className="dropdown-content">
                <Link to="/getting-started" onClick={() => setShowNavbar(false)}>
                  Getting started
                </Link>
                <Link to="/safety-and-data-protection" onClick={() => setShowNavbar(false)}>
                  Safety and data protection
                </Link>
                <Link to="/support" onClick={() => setShowNavbar(false)}>
                  Tech support
                </Link>
                <Link to="/faq" onClick={() => setShowNavbar(false)}>
                  FAQs
                </Link>
              </div>
            </NavDropdown>
            <button className="icon" onClick={handleShowNavbar}>
              &#9776;
            </button>
          </div>
        </div>
        {/* <UserDropDownMenu /> */}
      </div>
    </NavBar>
  );
};

export default Header;
