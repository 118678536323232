import React from "react";
import { ConstrainedPageStyle, PageTitle, Section, P, Ul } from "../styles/page";
import { DownloadLink } from "../components/Links";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SafetyDataProtectionWrapper = styled(ConstrainedPageStyle)`
  position: relative;
  padding-bottom: 10.5rem;

  .samhe-logo {
    position: absolute;
    width: auto;
    max-width: calc(100% - 1rem);
    max-height: 10rem;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`;

const SafetyDataProtectionPage: React.FC = () => {
  return (
    <SafetyDataProtectionWrapper>
      <PageTitle>Safety and Data Protection - information for schools</PageTitle>
      <Section>
        <P>
          The following documentation is provided to all schools and provides useful information on safety and data
          protection issues:
        </P>
        <Ul style={{ marginLeft: "3rem" }}>
          <li>
            This <DownloadLink href="/resources/samhe_risk_assessment.pdf">monitor risk assessment</DownloadLink> covers
            risks relating to the SAMHE monitors and documents procedures in place to mitigate those risks.
          </li>
          <li>
            Our{" "}
            <DownloadLink href="/resources/SAMHE_Monitors_Standard_Operating_Procedure_Staff.pdf">
              monitor standard operating procedures
            </DownloadLink>{" "}
            detail safe practice for using and moving your monitor.
          </li>
        </Ul>
        <P>Note. following these links will download copies of the documents as pdf files for your records.</P>
        <P>
          If you're a parent with queries about data protection please visit our <Link to="/faq">FAQs</Link> page.
        </P>
      </Section>

      <img src="/images/logo/samhe_logo_strap.png" alt="SAMHE logo" className="samhe-logo" />
    </SafetyDataProtectionWrapper>
  );
};

export default SafetyDataProtectionPage;
