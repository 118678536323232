import React from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { CTAButtonStyle } from "../../styles/button";
import { PLong } from "../../styles/page";
import { FormStyle, HorizSpacer, InputStyle } from "../../styles/form";
import { InputArea, InputWrap } from "../FormComponents/Input";
import { IContactFormData, IContactFormSubmission } from "../../lib/formSubmissions/IContactFormSubmission";
import { apiSubmitContactForm } from "../../Api/apiContactForm";
import { HowDidYouHearAboutUs } from "../../lib/formSubmissions/IHowDidYouHear";
import { extractBitwiseIndexesFromMultiCheckboxOutput } from "../FormComponents/utils";
import { ErrorMessageStyle } from "../FormComponents/ErrorMessage";
import { LinkExternal } from "../Links";
import { MultiCheckbox } from "../FormComponents/MultiCheckbox";

export interface ContactFormProps {}

const formDefaultValues: IContactFormData = {
  firstName: "",
  lastName: "",
  email: "",
  signUpForNewsletter: false,
  howDidYouHearAboutUs: null,
  howDidYouFindUsOther: "",
  howDidYouFindUsInfo: "",
  tellUsAboutYourself: "",
  message: "",
  confirmAge: false,
  agreeToPrivacy: false,
};

const checkConsents = (data) => ["confirmAge", "agreeToPrivacy"].every((k) => data[k] === true);

const parseFormData = (data: IContactFormData): IContactFormSubmission => {
  const amendedData: IContactFormSubmission = {
    ...data,
    // TODO: would have been nicer to extract Uids here
    howDidYouHearAboutUs: extractBitwiseIndexesFromMultiCheckboxOutput(data.howDidYouHearAboutUs),
  };
  return amendedData;
};

const ContactForm: React.FC<ContactFormProps> = () => {
  const [message, setMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const onFormSubmit = async (data: IContactFormData) => {
    setMessage("Submitting Message");
    if (!checkConsents(data)) {
      setMessage("Must agree to all required consents.");
      throw Error("Must agree to all required consents.");
    }
    setLoading(true);
    const parsedFormData = parseFormData(data);

    return apiSubmitContactForm(parsedFormData)
      .then(() => {
        // Successfully submitted form.
        navigate("/contact/success");
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formData = useForm<IContactFormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, formState } = formData;

  const { isSubmitted, isSubmitSuccessful, isSubmitting, isValidating, errors } = formState;

  React.useEffect(() => {
    if (isValidating || isSubmitting) {
      setMessage("-");
    }
  }, [isValidating, isSubmitting]);

  const containsErrors = Object.keys(errors).length > 0;

  const displayMessage = React.useMemo(
    () =>
      (isSubmitting && "Submitting Form.") ||
      (isSubmitted &&
        containsErrors &&
        "Form has validation errors. Check if you have filled in all required fields.") ||
      (isSubmitted && !isSubmitSuccessful && "There was an error submitting your answers. Please try again.") ||
      (isSubmitted && isSubmitSuccessful && "Submission successful. Navigating to success page.") ||
      message ||
      "",
    [isSubmitted, containsErrors, isSubmitSuccessful, message, isSubmitting]
  );

  const isErrorMessage = React.useMemo(() => {
    return containsErrors || (isSubmitted && !isSubmitSuccessful);
  }, [containsErrors, isSubmitSuccessful, isSubmitted]);

  return (
    <div className="contactForm_wrap">
      <FormProvider {...formData}>
        <FormStyle aria-label="Contact Form" name="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
          <div style={{ display: "flex", flexGrow: 1, width: "100%", flexWrap: "wrap" }}>
            <InputWrap
              style={{ width: "20rem", flexGrow: 1 }}
              name="firstName"
              placeholder=""
              label="First Name"
              type="string"
              required
            />
            <HorizSpacer />
            <InputWrap
              style={{ width: "20rem", flexGrow: 1 }}
              name="lastName"
              placeholder=""
              label="Last Name"
              type="string"
              required
            />
            <InputWrap
              style={{ width: "20rem", flexGrow: 1 }}
              placeholder=""
              name="email"
              label="Email"
              type="email"
              required
            />
          </div>
          <InputWrap
            name="signUpForNewsletter"
            label="I would like to receive SAMHE newsletters and email updates."
            type="checkbox"
          />
          <InputArea
            name="message"
            placeholder="If you have a media or other enquiry about SAMHE, please write it here."
            label="Your enquiry"
            type="area"
          />
          <InputArea
            name="tellUsAboutYourself"
            label="Please tell us more about yourself"
            placeholder="What is your background/interest in working to improve air quality in schools?"
            type="area"
          />
          <PLong>
            We'd be really interested to know how you found out about SAMHE. This helps us to understand which of the
            ways of promoting our project are most effective so we can focus our efforts.
          </PLong>
          <MultiCheckbox
            multiple
            label="Please select one or more of the options below. If you've got time, please add more detail in the box below."
            name="howDidYouHearAboutUs"
            options={Object.values(HowDidYouHearAboutUs)}
          />
          <InputArea name="howDidYouFindUsInfo" label="" placeholder="More detail..." type="area" />
          <InputWrap name="confirmAge" label="I confirm that I am over the age of 13." type="checkbox" required />
          <InputWrap
            name="agreeToPrivacy"
            label={
              <>
                I agree to the terms set out in the{" "}
                <LinkExternal
                  to="/privacy/contact"
                  aria-label="Open privacy policy in new tab"
                  title="SAMHE Contact Privacy Policy"
                >
                  SAMHE Contact Privacy Policy
                </LinkExternal>{" "}
              </>
            }
            type="checkbox"
            required
          />
          {isErrorMessage && <ErrorMessageStyle data-testid="displayErrorMessage">{displayMessage}</ErrorMessageStyle>}
          <br></br>
          {!isErrorMessage && <div data-testid="displayMessage">{displayMessage || " "}</div>}
          <InputStyle style={{ alignItems: "center" }}>
            <CTAButtonStyle style={{ width: "30%" }} type="submit" disabled={loading}>
              {loading ? "Loading" : "Submit"}
            </CTAButtonStyle>
          </InputStyle>
          <br></br>
          <PLong>
            You can also follow SAMHE on <a href="https://twitter.com/SAMHEProject">X</a>
          </PLong>
        </FormStyle>
      </FormProvider>
    </div>
  );
};

export default ContactForm;
