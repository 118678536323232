import { DocumentStyle } from "../../styles/documents";
import { Section, SectionHeading, P, Ul } from "../../styles/page";
import { AExternal } from "../Links";

export const lastUpdated = "06/04/2023";
export const version = "2.0";

export const UserAdminPrivacyPolicy = () => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>
        <P> Last updated {lastUpdated} </P>
      </Section>
      <Section>
        <SectionHeading>How will your information be used about you?</SectionHeading>
        <SectionHeading>
          Research Study Title: Schools' Air quality Monitoring for Health and Education (SAMHE)
        </SectionHeading>
        <SectionHeading>P91387</SectionHeading>
        <P>
          Imperial College London is the sponsor for this study and will act as the data controller for this study. This
          means that we are responsible for looking after your information and using it properly. Imperial College
          London will keep the email address provided as part of the Admin user creation process for:
        </P>
        <Ul>
          <li>Up to ten years after the end of the project, so we can contact you if required.</li>
        </Ul>
        <P>This study is stated to finish in August 2024, but additional funding may extend the project.</P>
        <P>
          We will need to use information from your school for this research project. This information will include:
        </P>
        <Ul>
          <li>Your school's generic email address</li>
        </Ul>
        <P>
          People within Imperial College London and study team (see section 'Sharing your information with others') will
          use this information in the event that there are safeguarding concerns, or to check your records to make sure
          the information held is accurate. We will keep all information about you safe and secure.
        </P>
        <P>
          Once we have finished the study, we will keep some of the data so we can check the results. Your school will
          not be identified in any project outputs unless your school has provided written permission for us to do so.
        </P>
      </Section>
      <Section>
        <SectionHeading>Legal Basis</SectionHeading>
        <P>
          As a university we use personal information to conduct research that will be in the public interest.
          Therefore, our legal basis for using your information under the General Data Protection Regulation (GDPR) and
          the Data Protection Act 2018, is as follows:
        </P>
        <P>Imperial College London - "performance of a task carried out in the public interest".</P>
        <P>
          Where special category personal information is involved (most commonly health data, biometric data and genetic
          data, racial and ethnic data etc.), Imperial College London relies on "scientific or historical research
          purposes or statistical purposes".
        </P>
        <P>
          We will conduct scientific research in compliance with the law and the recommendations and guidance published
          by the UK Information Commissioners Office (ICO). This will follow similar rules to health and social care
          research where the College aims to conduct world leading research and innovation aiming to deliver
          transformative impact for society. Scientific research should serve the public interest, which means that we
          have to demonstrate that our research has societal impact serving the interests of society as a whole.
        </P>
      </Section>
      <Section>
        <SectionHeading>International Transfers</SectionHeading>
        <P>
          There may be a requirement to transfer information to countries outside the European Economic Area (for
          example, to a research partner). Where this information contains your personal data, Imperial College London
          will ensure that it is transferred in accordance with data protection legislation. If the data is transferred
          to a country which is not subject to a European Commission (<b>EC</b>) adequacy decision in respect of its
          data protection standards, Imperial College London will enter into a data sharing agreement with the recipient
          organisation that incorporates UK approved standard contractual clauses that safeguard how your personal data
          is processed.
        </P>
      </Section>
      <Section>
        <SectionHeading>Sharing your information with others</SectionHeading>
        <P>
          For the purposes referred to in this privacy notice and relying on the bases for processing as set out above,
          we will share your personal data with certain third parties.
        </P>
        <Ul>
          <li>
            Other College employees, agents, contractors and service providers (for example, suppliers of printing and
            mailing services, email communication services or web services, or suppliers who help us carry out any of
            the activities described above). Our third party service providers are required to enter into data
            processing agreements with us. We only permit them to process your personal data for specified purposes and
            in accordance with our policies.
          </li>
          <li>the following Research Collaborators / Partners in the study:</li>
          <Ul>
            <li>
              the Stockholm Environment Institute (SEI) at the University of York - will have access to your personal
              data including your contact details because they will be leading communications with schools
            </li>
            <li>
              The United Kingdom Health Security Agency - because they may seek to contact you in relation to additional
              surveys they would like to conduct to collect supplementary data
            </li>
          </Ul>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Potential use of study data for future research</SectionHeading>
        <P>
          When you agree to take part in a research study, the information collected either as part of the study or in
          preparation (such as contact details) may, with your consent, be provided to researchers running other
          research studies in this organisation and in other organisations. These organisations may be universities or
          organisations involved in research in this country or abroad. Your information will only be used by
          organisations and researchers to conduct research in accordance with all relevant legislation including the
          GDPR (UK GDPR and EU GDPR 2016/679), the Data Protection Act 2018.
        </P>
        <P>
          This information will not identify you and will not be combined with other information in a way that could
          identify you, used against you or used to make decisions about you.
        </P>
      </Section>
      <Section>
        <SectionHeading>Commercialisation</SectionHeading>
        <P>
          Data from the study may also be provided to organisations not named in this participant information sheet,
          e.g. commercial organisations or non-commercial organisations for the purposes of undertaking the current
          study, future research studies or commercial purposes such as development by a company of a new test, product,
          service or treatment. We will ensure your name and any identifying details will NOT be given to these third
          parties, instead you will be identified by a unique study number with any analysis having the potential to
          generate 'personal data'.
        </P>
        <P>
          Aggregated (combined) or anonymised data sets (all identifying information is removed) may also be created
          using your data (in a way which does not identify you individually) and be used for such research or
          commercial purposes where the purposes align to relevant legislation (including the GDPR) and wider aims of
          the study. Your data will not be shared with a commercial organisation for marketing purposes.
        </P>
      </Section>
      <Section>
        <SectionHeading>What are your choices about how your information is used?</SectionHeading>
        <P>
          You can stop being part of the study by deleting your account at any time, without giving a reason, and there
          will be no repercussions if you decide to do so. If you do, we will delete your personal data from our records
          but we will keep any data you have entered in the app in response to the various embedded activities. You will
          be able to see what personal data we have about you under account settings and change it as required to ensure
          it is up to date.
        </P>
      </Section>
      <Section>
        <SectionHeading>Where can you find out more about how your information is used?</SectionHeading>
        <P>You can find out more about how we use your information</P>
        <Ul>
          <li>by asking one of the research team</li>
          <li>
            by sending an email to <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>
          </li>
          <li>
            by visiting <AExternal href="www.samhe.org.uk">www.samhe.org.uk</AExternal>
          </li>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Complaint</SectionHeading>
        <P>
          If you wish to raise a complaint about how we have handled your personal data, please contact the research
          team first by sending an email to <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>.
        </P>
        <P>
          Following our response, if you are not satisfied please contact Imperial College London's Data Protection
          Officer via email at <a href="mailto:dpo@imperial.ac.uk">dpo@imperial.ac.uk</a>, via telephone on 020 7594
          3502 and/or via post at Imperial College London, Data Protection Officer, Faculty Building Level 4, London SW7
          2AZ.
        </P>
        <P>
          If you remain unsatisfied with our response or believe we are processing your personal data in a way that is
          not lawful you can complain to the Information Commissioner's Office (ICO) via{" "}
          <AExternal href="https://ico.org.uk/">ico.org.uk</AExternal>. The ICO does recommend that you seek to resolve
          matters with the data controller (us) first before involving them.
        </P>
      </Section>
    </DocumentStyle>
  );
};
